/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { OuiCodeBlock } from '../../../../../src/components/code';
import { OuiButtonEmpty } from '../../../../../src/components/button'; // @ts-ignore Not TS

import { CodeSandboxLink } from '../../codesandbox'; // @ts-ignore Not TS

import { renderJsSourceCode } from '../_utils';
export var GuideSectionExampleCode = ({
  code
}) => {
  const [codeToRender, setCodeToRender] = useState();
  useEffect(() => {
    setCodeToRender(renderJsSourceCode(code));
    return () => {
      setCodeToRender(undefined);
    };
  }, [code]);
  const codeSandboxLink = <CodeSandboxLink className="guideSectionExampleCode__link" content={code.default}>
      <OuiButtonEmpty size="xs" iconType="logoCodesandbox">
        Try out this demo on Code Sandbox
      </OuiButtonEmpty>
    </CodeSandboxLink>;
  return <>
      <OuiCodeBlock language="jsx" overflowHeight={400} isCopyable>
        {codeToRender}
      </OuiCodeBlock>
      {codeSandboxLink}
    </>;
};
GuideSectionExampleCode.propTypes = {
  code: PropTypes.any.isRequired
};

try {
  GuideSectionExampleCode.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'GuideSectionExampleCode',
    methods: [],
    props: {
      code: {
        defaultValue: null,
        description: '',
        name: 'code',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_code.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'any'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}