/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { copyToClipboard } from '../../services';
import { OuiToolTip } from '../tool_tip';
export class OuiCopy extends Component {
  static defaultProps = {
    afterMessage: 'Copied'
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltipText: this.props.beforeMessage
    };
  }

  copy = () => {
    const isCopied = copyToClipboard(this.props.textToCopy);

    if (isCopied) {
      this.setState({
        tooltipText: this.props.afterMessage
      });
    }
  };
  resetTooltipText = () => {
    this.setState({
      tooltipText: this.props.beforeMessage
    });
  };

  render() {
    const {
      children,
      textToCopy,
      beforeMessage,
      afterMessage,
      ...rest
    } = this.props;
    return (// See `src/components/tool_tip/tool_tip.js` for explanation of below eslint-disable
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <OuiToolTip content={this.state.tooltipText} onMouseOut={this.resetTooltipText} {...rest}>
        {children(this.copy)}
      </OuiToolTip>
    );
  }

}
OuiCopy.propTypes = {
  /**
     * Text that will be copied to clipboard when copy function is executed.
     */
  textToCopy: PropTypes.string.isRequired,

  /**
     * Tooltip message displayed before copy function is called.
     */
  beforeMessage: PropTypes.node,

  /**
     * Tooltip message displayed after copy function is called that lets the user know that
     * 'textToCopy' has been copied to the clipboard.
     */
  afterMessage: PropTypes.node,

  /**
     * Function that must return a component. First argument is 'copy' function.
     * Use your own logic to create the component that users interact with when triggering copy.
     */
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiCopy.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCopy',
    methods: [],
    props: {
      textToCopy: {
        defaultValue: null,
        description: 'Text that will be copied to clipboard when copy function is executed.',
        name: 'textToCopy',
        parent: {
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        },
        declarations: [{
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      beforeMessage: {
        defaultValue: null,
        description: 'Tooltip message displayed before copy function is called.',
        name: 'beforeMessage',
        parent: {
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        },
        declarations: [{
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      afterMessage: {
        defaultValue: {
          value: 'Copied'
        },
        description: 'Tooltip message displayed after copy function is called that lets the user know that\n' + "'textToCopy' has been copied to the clipboard.",
        name: 'afterMessage',
        parent: {
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        },
        declarations: [{
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      children: {
        defaultValue: null,
        description: "Function that must return a component. First argument is 'copy' function.\n" + 'Use your own logic to create the component that users interact with when triggering copy.',
        name: 'children',
        parent: {
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        },
        declarations: [{
          fileName: 'docs/src/components/copy/copy.tsx',
          name: 'OuiCopyProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(copy: () => void) => ReactElement'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: 'An optional title for your tooltip.',
        name: 'title',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      id: {
        defaultValue: null,
        description: 'Unless you provide one, this will be randomly generated.',
        name: 'id',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseOut: {
        defaultValue: null,
        description: 'If supplied, called when mouse movement causes the tool tip to be\n' + 'hidden.',
        name: 'onMouseOut',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLSpanElement, MouseEvent>) => void'
        }
      },
      anchorClassName: {
        defaultValue: null,
        description: 'Passes onto the the trigger.',
        name: 'anchorClassName',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      content: {
        defaultValue: null,
        description: 'The main content of your tooltip.',
        name: 'content',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      display: {
        defaultValue: null,
        description: 'Common display alternatives for the anchor wrapper',
        name: 'display',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      delay: {
        defaultValue: null,
        description: 'Delay before showing tooltip. Good for repeatable items.',
        name: 'delay',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToolTipDelay',
          value: [{
            value: '"regular"'
          }, {
            value: '"long"'
          }]
        }
      },
      position: {
        defaultValue: null,
        description: 'Suggested position. If there is not enough room for it this will be changed.',
        name: 'position',
        parent: {
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tool_tip/tool_tip.tsx',
          name: 'OuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiPopoverPosition',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      }
    },
    extendedInterfaces: ['OuiCopyProps', 'CommonProps', 'OuiToolTipProps']
  };
} catch (e) {}