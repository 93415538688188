/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { htmlIdGenerator, isWithinMinBreakpoint, throttle } from '../../services';
import { OuiFlyout } from '../flyout'; // Extend all the flyout props except `onClose` because we handle this internally

export var OuiCollapsibleNav = ({
  id,
  children,
  className,
  isDocked = false,
  isOpen = false,
  button,
  showButtonIfDocked = false,
  dockedBreakpoint = 'l',
  // Setting different OuiFlyout defaults
  as = 'nav',
  size = 320,
  side = 'left',
  role = null,
  ownFocus = true,
  outsideClickCloses = true,
  closeButtonPosition = 'outside',
  paddingSize = 'none',
  ...rest
}) => {
  const [flyoutID] = useState(id || htmlIdGenerator()('ouiCollapsibleNav'));
  /**
   * Setting the initial state of pushed based on the `type` prop
   * and if the current window size is large enough (larger than `pushBreakpoint`)
   */

  const [windowIsLargeEnoughToPush, setWindowIsLargeEnoughToPush] = useState(isWithinMinBreakpoint(typeof window === 'undefined' ? 0 : window.innerWidth, dockedBreakpoint));
  const navIsDocked = isDocked && windowIsLargeEnoughToPush;
  /**
   * Watcher added to the window to maintain `isPushed` state depending on
   * the window size compared to the `pushBreakpoint`
   */

  const functionToCallOnWindowResize = throttle(() => {
    if (isWithinMinBreakpoint(window.innerWidth, dockedBreakpoint)) {
      setWindowIsLargeEnoughToPush(true);
    } else {
      setWindowIsLargeEnoughToPush(false);
    } // reacts every 50ms to resize changes and always gets the final update

  }, 50);
  useEffect(() => {
    if (isDocked) {
      // Only add the event listener if we'll need to accommodate with padding
      window.addEventListener('resize', functionToCallOnWindowResize);
    }

    return () => {
      if (isDocked) {
        window.removeEventListener('resize', functionToCallOnWindowResize);
      }
    };
  }, [isDocked, functionToCallOnWindowResize]);
  const classes = classNames('ouiCollapsibleNav', className); // Show a trigger button if one was passed but
  // not if navIsDocked and showButtonIfDocked is false

  const trigger = navIsDocked && !showButtonIfDocked ? undefined : button && cloneElement(button, {
    'aria-controls': flyoutID,
    'aria-expanded': isOpen,
    'aria-pressed': isOpen,
    // When OuiOutsideClickDetector is enabled, we don't want both the toggle button and document touches/clicks to happen, they'll cancel eachother out
    onTouchEnd: e => {
      e.nativeEvent.stopImmediatePropagation();
    },
    onMouseUpCapture: e => {
      e.nativeEvent.stopImmediatePropagation();
    }
  });
  const flyout = <OuiFlyout id={flyoutID} className={classes} // Flyout props we set different defaults for
  as={as} size={size} side={side} role={role} ownFocus={ownFocus} outsideClickCloses={outsideClickCloses} closeButtonPosition={closeButtonPosition} paddingSize={paddingSize} {...rest} // Props dependent on internal docked status
  type={navIsDocked ? 'push' : 'overlay'} hideCloseButton={navIsDocked} pushMinBreakpoint={dockedBreakpoint}>
      {children}
    </OuiFlyout>;
  return <>
      {trigger}
      {(isOpen || navIsDocked) && flyout}
    </>;
};
OuiCollapsibleNav.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Sets the HTML element for `OuiFlyout`
       */
  as: PropTypes.any,
  onClose: PropTypes.func.isRequired,

  /**
     * Defines the width of the panel.
     * Pass a predefined size of `s | m | l`, or pass any number/string compatible with the CSS `width` attribute
     */
  size: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),

  /**
     * Sets the max-width of the panel,
     * set to `true` to use the default size,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
     * Customize the padding around the content of the flyout header, body and footer
     */
  paddingSize: PropTypes.any,

  /**
     * Adds an OuiOverlayMask and wraps in an OuiPortal
     */
  ownFocus: PropTypes.bool,

  /**
     * Hides the default close button. You must provide another close button somewhere within the flyout.
     */
  hideCloseButton: PropTypes.bool,

  /**
     * Extends OuiButtonIconProps onto the close button
     */
  closeButtonProps: PropTypes.any,

  /**
     * Position of close button.
     * `inside`: Floating to just inside the flyout, always top right;
     * `outside`: Floating just outside the flyout near the top (side dependent on `side`). Helpful when the close button may cover other interactable content.
     */
  closeButtonPosition: PropTypes.oneOf(["inside", "outside"]),

  /**
     * Adjustments to the OuiOverlayMask that is added when `ownFocus = true`
     */
  maskProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Function that applies to clicking the mask itself and not the children
       */
    onClick: PropTypes.func,

    /**
       * ReactNode to render as this component's content
       */
    children: PropTypes.node,

    /**
       * Should the mask visually sit above or below the OuiHeader (controlled by z-index)
       */
    headerZindexLocation: PropTypes.oneOf(["above", "below"])
  }),

  /**
     * Forces this interaction on the mask overlay or body content.
     * Defaults depend on `ownFocus` and `type` values
     */
  outsideClickCloses: PropTypes.bool,

  /**
     * Which side of the window to attach to.
     * The `left` option should only be used for navigation.
     */
  side: PropTypes.any,

  /**
     * Defaults to `dialog` which is best for most cases of the flyout.
     * Otherwise pass in your own, aria-role, or `null` to remove it and use the semantic `as` element instead
     */
  role: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string.isRequired]),

  /**
     * Named breakpoint or pixel value for customizing the minimum window width to enable docking
     */
  pushMinBreakpoint: PropTypes.oneOfType([PropTypes.oneOf(["xs", "s", "m", "l", "xl"]).isRequired, PropTypes.number.isRequired]),
  style: PropTypes.any,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,

  /**
     * Shows the navigation flyout
     */
  isOpen: PropTypes.bool,

  /**
     * Keeps navigation flyout visible and push `<body>` content via padding
     */
  isDocked: PropTypes.bool,
  dockedBreakpoint: PropTypes.oneOfType([PropTypes.oneOf(["xs", "s", "m", "l", "xl"]).isRequired, PropTypes.number.isRequired]),

  /**
     * Button for controlling visible state of the nav
     */
  button: PropTypes.element,

  /**
     * Keeps the display of toggle button when in docked state
     */
  showButtonIfDocked: PropTypes.bool
};

try {
  OuiCollapsibleNav.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCollapsibleNav',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'docs/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      size: {
        defaultValue: {
          value: '320'
        },
        description: 'Defines the width of the panel.\n' + 'Pass a predefined size of `s | m | l`, or pass any number/string compatible with the CSS `width` attribute',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: undefined,
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Customize the padding around the content of the flyout header, body and footer',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      side: {
        defaultValue: {
          value: 'left'
        },
        description: 'Which side of the window to attach to.\n' + 'The `left` option should only be used for navigation.',
        name: 'side',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToastSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      ownFocus: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds an OuiOverlayMask and wraps in an OuiPortal',
        name: 'ownFocus',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      maxWidth: {
        defaultValue: null,
        description: 'Sets the max-width of the panel,\n' + 'set to `true` to use the default size,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'maxWidth',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      },
      as: {
        defaultValue: {
          value: "'nav' as OuiCollapsibleNavProps['as']"
        },
        description: 'Sets the HTML element for `OuiFlyout`',
        name: 'as',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"div"'
        }
      },
      closeButtonProps: {
        defaultValue: null,
        description: 'Extends OuiButtonIconProps onto the close button',
        name: 'closeButtonProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<OuiButtonIconPropsForButton>'
        }
      },
      hideCloseButton: {
        defaultValue: null,
        description: 'Hides the default close button. You must provide another close button somewhere within the flyout.',
        name: 'hideCloseButton',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      closeButtonPosition: {
        defaultValue: {
          value: 'outside'
        },
        description: 'Position of close button.\n' + '`inside`: Floating to just inside the flyout, always top right;\n' + '`outside`: Floating just outside the flyout near the top (side dependent on `side`). Helpful when the close button may cover other interactable content.',
        name: 'closeButtonPosition',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inside" | "outside"',
          value: [{
            value: '"inside"'
          }, {
            value: '"outside"'
          }]
        }
      },
      maskProps: {
        defaultValue: null,
        description: 'Adjustments to the OuiOverlayMask that is added when `ownFocus = true`',
        name: 'maskProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiOverlayMaskProps'
        }
      },
      outsideClickCloses: {
        defaultValue: {
          value: 'true'
        },
        description: 'Forces this interaction on the mask overlay or body content.\n' + 'Defaults depend on `ownFocus` and `type` values',
        name: 'outsideClickCloses',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      pushMinBreakpoint: {
        defaultValue: null,
        description: 'Named breakpoint or pixel value for customizing the minimum window width to enable the `push` type',
        name: 'pushMinBreakpoint',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/flyout/flyout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number | "xs" | "s" | "m" | "l" | "xl"'
        }
      },
      isOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows the navigation flyout',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDocked: {
        defaultValue: {
          value: 'false'
        },
        description: 'Keeps navigation flyout visible and push `<body>` content via padding',
        name: 'isDocked',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      dockedBreakpoint: {
        defaultValue: {
          value: 'l'
        },
        description: 'Named breakpoint or pixel value for customizing the minimum window width to enable docking',
        name: 'dockedBreakpoint',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number | "xs" | "s" | "m" | "l" | "xl"'
        }
      },
      button: {
        defaultValue: null,
        description: 'Button for controlling visible state of the nav',
        name: 'button',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      showButtonIfDocked: {
        defaultValue: {
          value: 'false'
        },
        description: 'Keeps the display of toggle button when in docked state',
        name: 'showButtonIfDocked',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/collapsible_nav/collapsible_nav.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'Attributes']
  };
} catch (e) {}