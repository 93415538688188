/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
var typeToClassNameMap = {
  inList: 'ouiCheckbox--inList'
};
export var TYPES = keysOf(typeToClassNameMap);
export class OuiCheckbox extends Component {
  static defaultProps = {
    checked: false,
    disabled: false,
    indeterminate: false,
    compressed: false
  };
  inputRef = undefined;

  componentDidMount() {
    this.invalidateIndeterminate();
  }

  componentDidUpdate() {
    this.invalidateIndeterminate();
  }

  render() {
    const {
      className,
      id,
      checked,
      label,
      onChange,
      type,
      disabled,
      compressed,
      indeterminate,
      inputRef,
      labelProps,
      ...rest
    } = this.props;
    const classes = classNames('ouiCheckbox', type && typeToClassNameMap[type], {
      'ouiCheckbox--noLabel': !label,
      'ouiCheckbox--compressed': compressed
    }, className);
    const labelClasses = classNames('ouiCheckbox__label', labelProps?.className);
    let optionalLabel;

    if (label) {
      optionalLabel = <label {...labelProps} className={labelClasses} htmlFor={id}>
          {label}
        </label>;
    }

    return <div className={classes}>
        <input className="ouiCheckbox__input" type="checkbox" id={id} checked={checked} onChange={onChange} disabled={disabled} ref={this.setInputRef} {...rest} />

        <div className="ouiCheckbox__square" />

        {optionalLabel}
      </div>;
  }

  setInputRef = input => {
    this.inputRef = input;

    if (this.props.inputRef) {
      this.props.inputRef(input);
    }

    this.invalidateIndeterminate();
  };

  invalidateIndeterminate() {
    if (this.inputRef) {
      this.inputRef.indeterminate = this.props.indeterminate;
    }
  }

}
OuiCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.any.isRequired,
  // overriding to make it required
  inputRef: PropTypes.func,
  label: PropTypes.node,
  type: PropTypes.oneOf(["inList"]),
  disabled: PropTypes.bool,

  /**
     * when `true` creates a shorter height checkbox row
     */
  compressed: PropTypes.bool,
  indeterminate: PropTypes.bool,

  /**
     * Object of props passed to the <label/>
     */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiCheckbox.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCheckbox',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      checked: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'checked',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: true,
        type: {
          name: '(event: ChangeEvent<HTMLInputElement>) => void'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: '(element: HTMLInputElement) => void'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: '"inList"'
        }
      },
      disabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height checkbox row',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      indeterminate: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'indeterminate',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      labelProps: {
        defaultValue: null,
        description: 'Object of props passed to the <label/>',
        name: 'labelProps',
        parent: {
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox.tsx',
          name: 'OuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & LabelHTMLAttributes<HTMLLabelElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiCheckboxProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}