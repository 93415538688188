/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiFormFieldset } from '../form_fieldset';
import { OuiCheckbox } from './checkbox';
export var OuiCheckboxGroup = ({
  options = [],
  idToSelectedMap = {},
  onChange,
  className,
  disabled,
  compressed,
  legend,
  ...rest
}) => {
  const checkboxes = options.map((option, index) => {
    const {
      disabled: isOptionDisabled,
      className: optionClass,
      ...optionRest
    } = option;
    return <OuiCheckbox className={classNames('ouiCheckboxGroup__item', optionClass)} key={index} checked={idToSelectedMap[option.id]} disabled={disabled || isOptionDisabled} onChange={onChange.bind(null, option.id)} compressed={compressed} {...optionRest} />;
  });

  if (!!legend) {
    // Be sure to pass down the compressed option to the legend
    legend.compressed = compressed;
    return <OuiFormFieldset className={className} legend={legend} {...rest}>
        {checkboxes}
      </OuiFormFieldset>;
  }

  return <div className={className} {...rest}>
      {checkboxes}
    </div>;
};
OuiCheckboxGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired).isRequired,
  idToSelectedMap: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,

  /**
     * Tightens up the spacing between checkbox rows and sends down the
     * compressed prop to the checkbox itself
     */
  compressed: PropTypes.bool,
  disabled: PropTypes.bool,

  /**
     * If the individual labels for each radio do not provide a sufficient description, add a legend.
     * Wraps the group in a `OuiFormFieldset` which adds an `OuiLegend` for titling the whole group.
     * Accepts an `OuiFormLegendProps` shape.
     */
  legend: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
         * ReactNode to render as this component's content
         */
    children: PropTypes.node.isRequired,

    /**
         * For a hidden legend that is still visible to the screen reader, set to 'hidden'
         */
    display: PropTypes.oneOf(["hidden", "visible"]),
    compressed: PropTypes.bool
  })
};

try {
  OuiCheckboxGroup.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiCheckboxGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      options: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiCheckboxGroupOption[]'
        }
      },
      idToSelectedMap: {
        defaultValue: {
          value: '{}'
        },
        description: '',
        name: 'idToSelectedMap',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiCheckboxGroupIdToSelectedMap'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(optionId: string) => void'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Tightens up the spacing between checkbox rows and sends down the\n' + 'compressed prop to the checkbox itself',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      legend: {
        defaultValue: null,
        description: 'Adds an OuiFormLegend element as the first child\n' + 'If the individual labels for each radio do not provide a sufficient description, add a legend.\n' + 'Wraps the group in a `OuiFormFieldset` which adds an `OuiLegend` for titling the whole group.\n' + 'Accepts an `OuiFormLegendProps` shape.',
        name: 'legend',
        parent: {
          fileName: 'docs/src/components/form/form_fieldset/form_fieldset.tsx',
          name: 'OuiFormFieldsetProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/form_fieldset/form_fieldset.tsx',
          name: 'OuiFormFieldsetProps'
        }, {
          fileName: 'docs/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLLegendElement> & CommonProps & { children: ReactNode; display?: "hidden" | "visible"; compressed?: boolean; }'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'OuiFormFieldsetProps', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}