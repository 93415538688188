/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiRangeThumb = ({
  className,
  min,
  max,
  value,
  disabled,
  showInput,
  showTicks,
  onClick,
  onMouseDown,
  tabIndex,
  ...rest
}) => {
  const classes = classNames('ouiRangeThumb', {
    'ouiRangeThumb--hasTicks': showTicks
  }, className);
  const commonAttrs = {
    className: classes,
    role: 'slider',
    'aria-valuemin': min,
    'aria-valuemax': max,
    'aria-valuenow': Number(value),
    'aria-disabled': !!disabled,
    tabIndex: showInput || !!disabled ? -1 : tabIndex || 0
  };
  return onClick || onMouseDown ? <button type="button" onClick={onClick} onMouseDown={onMouseDown} disabled={disabled} {...commonAttrs} {...rest} /> : <div {...commonAttrs} {...rest} />;
};
OuiRangeThumb.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  disabled: PropTypes.bool,
  showInput: PropTypes.bool,
  showTicks: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiRangeThumb.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeThumb',
    methods: [],
    props: {
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showInput: {
        defaultValue: null,
        description: '',
        name: 'showInput',
        parent: {
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: {
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_thumb.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['DOMAttributes', 'BaseProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes']
  };
} catch (e) {}