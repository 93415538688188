/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { keysOf } from '../../common';
import { OuiColorPaletteDisplayFixed } from './color_palette_display_fixed';
import { OuiColorPaletteDisplayGradient } from './color_palette_display_gradient';
var sizeToClassNameMap = {
  xs: 'ouiColorPaletteDisplay--sizeExtraSmall',
  s: 'ouiColorPaletteDisplay--sizeSmall',
  m: 'ouiColorPaletteDisplay--sizeMedium'
};
export var SIZES = keysOf(sizeToClassNameMap);
export var OuiColorPaletteDisplay = ({
  type = 'fixed',
  palette,
  className,
  size = 's',
  ...rest
}) => {
  const classes = classnames('ouiColorPaletteDisplay', className, sizeToClassNameMap[size]);
  return <>
      {type === 'fixed' ? <OuiColorPaletteDisplayFixed className={classes} palette={palette} {...rest} /> : <OuiColorPaletteDisplayGradient className={classes} palette={palette} {...rest} />}
    </>;
};
OuiColorPaletteDisplay.propTypes = {
  /**
     * Height of the palette display
     */
  size: PropTypes.oneOf(["xs", "s", "m"]),

  /**
     *   Specify the type of palette.
     *  `gradient`: each color fades into the next.
     */

  /**
     *  `fixed`: individual color blocks.
     */
  type: PropTypes.oneOfType([PropTypes.oneOf(["fixed"]), PropTypes.oneOf(["gradient"]).isRequired]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Array of color `strings` or an array of #ColorStop. The stops must be numbers in an ordered range.
     */

  /**
     * Array of color `strings` or an array of #ColorStop. The stops must be numbers in an ordered range.
     */
  palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
    stop: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired).isRequired]).isRequired
};

try {
  OuiColorPaletteDisplay.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiColorPaletteDisplay',
    methods: [],
    props: {
      size: {
        defaultValue: {
          value: 's'
        },
        description: 'Height of the palette display',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      type: {
        defaultValue: {
          value: 'fixed'
        },
        description: 'Specify the type of palette.\n' + '`gradient`: each color fades into the next.\n' + '`fixed`: individual color blocks.',
        name: 'type',
        parent: {
          fileName: 'docs/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'DisplayGradient'
        },
        declarations: [{
          fileName: 'docs/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'DisplayGradient'
        }, {
          fileName: 'docs/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'DisplayFixed'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "gradient"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"gradient"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      palette: {
        defaultValue: null,
        description: 'Array of color `strings` or an array of #ColorStop. The stops must be numbers in an ordered range.',
        name: 'palette',
        parent: {
          fileName: 'docs/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'OuiColorPaletteDisplayShared'
        },
        declarations: [{
          fileName: 'docs/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'OuiColorPaletteDisplayShared'
        }],
        required: true,
        type: {
          name: 'string[] | ColorStop[]'
        }
      }
    },
    extendedInterfaces: ['DisplayGradient', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'OuiColorPaletteDisplayShared']
  };
} catch (e) {}