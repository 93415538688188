/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiCode, OuiCodeBlock } from '../../../../src/components/code';
import { OuiCopy } from '../../../../src/components/copy';
import { OuiFlexGroup, OuiFlexItem } from '../../../../src/components/flex';
import { OuiText } from '../../../../src/components/text';
import { OuiSplitPanel } from '../../../../src/components/panel';
export var LANGUAGES = ['javascript', 'html'];
export var UtilityClassesSection = ({
  code,
  description,
  example,
  snippet
}) => {
  return <OuiFlexGroup>
      <OuiFlexItem>
        <OuiText size="s">
          <h3>
            <OuiCopy textToCopy={code}>
              {copy => <button onClick={copy}>
                  <OuiCode language="html" className="oui-textInheritColor">
                    {code}
                  </OuiCode>
                </button>}
            </OuiCopy>
          </h3>
          {description}
        </OuiText>
      </OuiFlexItem>
      <OuiFlexItem>
        <OuiSplitPanel.Outer style={{
        overflow: 'hidden'
      }}>
          <OuiSplitPanel.Inner>{example}</OuiSplitPanel.Inner>
          <OuiSplitPanel.Inner color="subdued">
            {snippet && <OuiCodeBlock isCopyable={true} paddingSize="none" transparentBackground={true} language="html">
                {snippet}
              </OuiCodeBlock>}
          </OuiSplitPanel.Inner>
        </OuiSplitPanel.Outer>
      </OuiFlexItem>
    </OuiFlexGroup>;
};
UtilityClassesSection.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.node,
  example: PropTypes.node,
  snippet: PropTypes.node
};

try {
  UtilityClassesSection.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'UtilityClassesSection',
    methods: [],
    props: {
      code: {
        defaultValue: null,
        description: '',
        name: 'code',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/views/utility_classes/utility_classes_section.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/views/utility_classes/utility_classes_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      example: {
        defaultValue: null,
        description: '',
        name: 'example',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/views/utility_classes/utility_classes_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      snippet: {
        defaultValue: null,
        description: '',
        name: 'snippet',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/views/utility_classes/utility_classes_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}