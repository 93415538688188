/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { prettyDuration, showPrettyDuration, commonDurationRanges } from './pretty_duration';
import { prettyInterval } from './pretty_interval';
import dateMath from '@opensearch/datemath';
import { OuiSuperUpdateButton } from './super_update_button';
import { OuiQuickSelectPopover } from './quick_select_popover/quick_select_popover';
import { OuiDatePopoverButton } from './date_popover/date_popover_button';
import { OuiDatePickerRange } from '../date_picker_range';
import { OuiFormControlLayout } from '../../form';
import { OuiFlexGroup, OuiFlexItem } from '../../flex';
import { AsyncInterval } from './async_interval';
import { OuiI18n } from '../../i18n';
import { OuiI18nConsumer } from '../../context'; // eslint-disable-line import/named

export { prettyDuration, commonDurationRanges };

function isRangeInvalid(start, end) {
  if (start === 'now' && end === 'now') {
    return true;
  }

  var startMoment = dateMath.parse(start);
  var endMoment = dateMath.parse(end, {
    roundUp: true
  });

  if (!startMoment || !endMoment || !startMoment.isValid() || !endMoment.isValid()) {
    return true;
  }

  if (startMoment.isAfter(endMoment)) {
    return true;
  }

  return false;
}

export class OuiSuperDatePicker extends Component {
  static defaultProps = {
    commonlyUsedRanges: commonDurationRanges,
    dateFormat: 'MMM D, YYYY @ HH:mm:ss.SSS',
    end: 'now',
    isAutoRefreshOnly: false,
    isDisabled: false,
    isPaused: true,
    recentlyUsedRanges: [],
    refreshInterval: 0,
    showUpdateButton: true,
    start: 'now-15m',
    timeFormat: 'HH:mm'
  };
  state = {
    prevProps: {
      start: this.props.start,
      end: this.props.end
    },
    start: this.props.start,
    end: this.props.end,
    isInvalid: isRangeInvalid(this.props.start, this.props.end),
    hasChanged: false,
    showPrettyDuration: showPrettyDuration(this.props.start, this.props.end, this.props.commonlyUsedRanges),
    isStartDatePopoverOpen: false,
    isEndDatePopoverOpen: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.start !== prevState.prevProps.start || nextProps.end !== prevState.prevProps.end) {
      return {
        prevProps: {
          start: nextProps.start,
          end: nextProps.end
        },
        start: nextProps.start,
        end: nextProps.end,
        isInvalid: isRangeInvalid(nextProps.start, nextProps.end),
        hasChanged: false,
        showPrettyDuration: showPrettyDuration(nextProps.start, nextProps.end, nextProps.commonlyUsedRanges)
      };
    }

    return null;
  }

  setTime = ({
    end,
    start
  }) => {
    const isInvalid = isRangeInvalid(start, end);
    this.setState({
      start,
      end,
      isInvalid,
      hasChanged: !(this.state.prevProps.start === start && this.state.prevProps.end === end)
    });

    if (!this.props.showUpdateButton) {
      this.props.onTimeChange({
        start,
        end,
        isQuickSelection: false,
        isInvalid
      });
    }
  };
  componentDidMount = () => {
    if (!this.props.isPaused) {
      this.startInterval(this.props.refreshInterval);
    }
  };
  componentDidUpdate = () => {
    this.stopInterval();

    if (!this.props.isPaused) {
      this.startInterval(this.props.refreshInterval);
    }
  };
  componentWillUnmount = () => {
    this.stopInterval();
  };
  setStart = start => {
    this.setTime({
      start,
      end: this.state.end
    });
  };
  setEnd = end => {
    this.setTime({
      start: this.state.start,
      end
    });
  };
  applyTime = () => {
    this.props.onTimeChange({
      start: this.state.start,
      end: this.state.end,
      isQuickSelection: false,
      isInvalid: false
    });
  };
  applyQuickTime = ({
    start,
    end
  }) => {
    this.setState({
      showPrettyDuration: showPrettyDuration(start, end, commonDurationRanges)
    });
    this.props.onTimeChange({
      start,
      end,
      isQuickSelection: true,
      isInvalid: false
    });
  };
  hidePrettyDuration = () => {
    this.setState({
      showPrettyDuration: false,
      isStartDatePopoverOpen: true
    });
  };
  onStartDatePopoverToggle = () => {
    this.setState(prevState => {
      return {
        isStartDatePopoverOpen: !prevState.isStartDatePopoverOpen
      };
    });
  };
  onStartDatePopoverClose = () => {
    this.setState({
      isStartDatePopoverOpen: false
    });
  };
  onEndDatePopoverToggle = () => {
    this.setState(prevState => {
      return {
        isEndDatePopoverOpen: !prevState.isEndDatePopoverOpen
      };
    });
  };
  onEndDatePopoverClose = () => {
    this.setState({
      isEndDatePopoverOpen: false
    });
  };
  onRefreshChange = ({
    refreshInterval,
    isPaused
  }) => {
    this.stopInterval();

    if (!isPaused) {
      this.startInterval(refreshInterval);
    }

    if (this.props.onRefreshChange) {
      this.props.onRefreshChange({
        refreshInterval,
        isPaused
      });
    }
  };
  stopInterval = () => {
    if (this.asyncInterval) {
      this.asyncInterval.stop();
    }
  };
  startInterval = refreshInterval => {
    const {
      onRefresh
    } = this.props;

    if (onRefresh) {
      const handler = () => {
        const {
          start,
          end
        } = this.props;
        onRefresh({
          start,
          end,
          refreshInterval
        });
      };

      this.asyncInterval = new AsyncInterval(handler, refreshInterval);
    }
  };
  renderDatePickerRange = () => {
    const {
      end,
      hasChanged,
      isEndDatePopoverOpen,
      isInvalid,
      isStartDatePopoverOpen,
      showPrettyDuration,
      start
    } = this.state;
    const {
      commonlyUsedRanges,
      dateFormat,
      isAutoRefreshOnly,
      isDisabled,
      isPaused,
      locale,
      refreshInterval,
      timeFormat,
      utcOffset
    } = this.props;

    if (isAutoRefreshOnly) {
      return <OuiDatePickerRange className="ouiDatePickerRange--inGroup" iconType={false} isCustom startDateControl={<div />} endDateControl={<div />} readOnly>
          <span className="ouiSuperDatePicker__prettyFormat">
            {prettyInterval(Boolean(isPaused), refreshInterval)}
          </span>
        </OuiDatePickerRange>;
    }

    if (showPrettyDuration && !isStartDatePopoverOpen && !isEndDatePopoverOpen) {
      return <OuiDatePickerRange className="ouiDatePickerRange--inGroup" iconType={false} isCustom startDateControl={<div />} endDateControl={<div />}>
          <button className={classNames('ouiSuperDatePicker__prettyFormat', {
          'ouiSuperDatePicker__prettyFormat--disabled': isDisabled
        })} data-test-subj="superDatePickerShowDatesButton" disabled={isDisabled} onClick={this.hidePrettyDuration}>
            {prettyDuration(start, end, commonlyUsedRanges, dateFormat)}
            <span className="ouiSuperDatePicker__prettyFormatLink">
              <OuiI18n token="ouiSuperDatePicker.showDatesButtonLabel" default="Show dates" />
            </span>
          </button>
        </OuiDatePickerRange>;
    }

    return <OuiI18nConsumer>
        {({
        locale: contextLocale
      }) => <OuiDatePickerRange className="ouiDatePickerRange--inGroup" iconType={false} isCustom startDateControl={<OuiDatePopoverButton className="ouiSuperDatePicker__startPopoverButton" position="start" needsUpdating={hasChanged} isInvalid={isInvalid} isDisabled={isDisabled} onChange={this.setStart} value={start} dateFormat={dateFormat} utcOffset={utcOffset} timeFormat={timeFormat} locale={locale || contextLocale} isOpen={this.state.isStartDatePopoverOpen} onPopoverToggle={this.onStartDatePopoverToggle} onPopoverClose={this.onStartDatePopoverClose} />} endDateControl={<OuiDatePopoverButton position="end" needsUpdating={hasChanged} isInvalid={isInvalid} isDisabled={isDisabled} onChange={this.setEnd} value={end} dateFormat={dateFormat} utcOffset={utcOffset} timeFormat={timeFormat} locale={locale || contextLocale} roundUp isOpen={this.state.isEndDatePopoverOpen} onPopoverToggle={this.onEndDatePopoverToggle} onPopoverClose={this.onEndDatePopoverClose} />} />}
      </OuiI18nConsumer>;
  };
  handleClickUpdateButton = () => {
    if (!this.state.hasChanged && this.props.onRefresh) {
      const {
        start,
        end,
        refreshInterval
      } = this.props;
      this.props.onRefresh({
        start,
        end,
        refreshInterval
      });
    } else {
      this.applyTime();
    }
  };
  renderUpdateButton = () => {
    if (!this.props.showUpdateButton || this.props.isAutoRefreshOnly) {
      return;
    }

    return <OuiFlexItem grow={false}>
        <OuiSuperUpdateButton needsUpdate={this.state.hasChanged} showTooltip={!this.state.isStartDatePopoverOpen && !this.state.isEndDatePopoverOpen} isLoading={this.props.isLoading} isDisabled={this.props.isDisabled || this.state.isInvalid} onClick={this.handleClickUpdateButton} data-test-subj="superDatePickerApplyTimeButton" {...this.props.updateButtonProps} />
      </OuiFlexItem>;
  };

  render() {
    const {
      commonlyUsedRanges,
      customQuickSelectPanels,
      dateFormat,
      end,
      isAutoRefreshOnly,
      isDisabled,
      isPaused,
      onRefreshChange,
      recentlyUsedRanges,
      refreshInterval,
      showUpdateButton,
      start
    } = this.props;
    const quickSelect = <OuiQuickSelectPopover applyRefreshInterval={onRefreshChange ? this.onRefreshChange : undefined} applyTime={this.applyQuickTime} commonlyUsedRanges={commonlyUsedRanges} customQuickSelectPanels={customQuickSelectPanels} dateFormat={dateFormat} end={end} isAutoRefreshOnly={isAutoRefreshOnly} isDisabled={isDisabled} isPaused={isPaused} recentlyUsedRanges={recentlyUsedRanges} refreshInterval={refreshInterval} start={start} />;
    const flexWrapperClasses = classNames('ouiSuperDatePicker__flexWrapper', {
      'ouiSuperDatePicker__flexWrapper--noUpdateButton': !showUpdateButton,
      'ouiSuperDatePicker__flexWrapper--isAutoRefreshOnly': isAutoRefreshOnly
    });
    return <OuiFlexGroup gutterSize="s" responsive={false} className={flexWrapperClasses}>
        <OuiFlexItem>
          <OuiFormControlLayout className="ouiSuperDatePicker" isDisabled={isDisabled} prepend={quickSelect}>
            {this.renderDatePickerRange()}
          </OuiFormControlLayout>
        </OuiFlexItem>
        {this.renderUpdateButton()}
      </OuiFlexGroup>;
  }

}
OuiSuperDatePicker.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  commonlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  customQuickSelectPanels: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired
  }).isRequired),

  /**
     * Specifies the formatted used when displaying dates and/or datetimes
     */
  dateFormat: PropTypes.string.isRequired,
  end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,

  /**
     * Set isAutoRefreshOnly to true to limit the component to only display auto refresh content.
     */
  isAutoRefreshOnly: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isPaused: PropTypes.bool.isRequired,

  /**
     * Used to localize e.g. month names, passed to `moment`
     */
  locale: PropTypes.any,

  /**
     * Callback for when the refresh interval is fired.
     * OuiSuperDatePicker will only manage a refresh interval timer when onRefresh callback is supplied
     * If a promise is returned, the next refresh interval will not start until the promise has resolved.
     * If the promise rejects the refresh interval will stop and the error thrown
     */
  onRefresh: PropTypes.func,

  /**
     * Callback for when the refresh interval changes.
     * Supply onRefreshChange to show refresh interval inputs in quick select popover
     */
  onRefreshChange: PropTypes.func,

  /**
     * Callback for when the time changes.
     */
  onTimeChange: PropTypes.func.isRequired,
  recentlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,

  /**
     * Refresh interval in milliseconds
     */
  refreshInterval: PropTypes.number.isRequired,

  /**
     * Set showUpdateButton to false to immediately invoke onTimeChange for all start and end changes.
     */
  showUpdateButton: PropTypes.bool.isRequired,
  start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,

  /**
     * Specifies the formatted used when displaying times
     */
  timeFormat: PropTypes.string.isRequired,
  utcOffset: PropTypes.number,

  /**
     * Props passed to the update button
     */
  updateButtonProps: PropTypes.any
};

try {
  OuiSuperDatePicker.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSuperDatePicker',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      commonlyUsedRanges: {
        defaultValue: {
          value: '[\n' + "  { start: 'now/d', end: 'now/d', label: 'Today' },\n" + "  { start: 'now/w', end: 'now/w', label: 'This week' },\n" + "  { start: 'now/M', end: 'now/M', label: 'This month' },\n" + "  { start: 'now/y', end: 'now/y', label: 'This year' },\n" + "  { start: 'now-1d/d', end: 'now-1d/d', label: 'Yesterday' },\n" + "  { start: 'now/w', end: 'now', label: 'Week to date' },\n" + "  { start: 'now/M', end: 'now', label: 'Month to date' },\n" + "  { start: 'now/y', end: 'now', label: 'Year to date' },\n" + ']'
        },
        description: '',
        name: 'commonlyUsedRanges',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'DurationRange[]'
        }
      },
      customQuickSelectPanels: {
        defaultValue: null,
        description: '',
        name: 'customQuickSelectPanels',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'QuickSelectPanel[]'
        }
      },
      dateFormat: {
        defaultValue: {
          value: 'MMM D, YYYY @ HH:mm:ss.SSS'
        },
        description: 'Specifies the formatted used when displaying dates and/or datetimes',
        name: 'dateFormat',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      end: {
        defaultValue: {
          value: 'now'
        },
        description: '',
        name: 'end',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isAutoRefreshOnly: {
        defaultValue: {
          value: 'false'
        },
        description: 'Set isAutoRefreshOnly to true to limit the component to only display auto refresh content.',
        name: 'isAutoRefreshOnly',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isPaused: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'isPaused',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      locale: {
        defaultValue: null,
        description: 'Used to localize e.g. month names, passed to `moment`',
        name: 'locale',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'LocaleSpecifier'
        }
      },
      onRefresh: {
        defaultValue: null,
        description: 'Callback for when the refresh interval is fired.\n' + 'OuiSuperDatePicker will only manage a refresh interval timer when onRefresh callback is supplied\n' + 'If a promise is returned, the next refresh interval will not start until the promise has resolved.\n' + 'If the promise rejects the refresh interval will stop and the error thrown',
        name: 'onRefresh',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(props: OnRefreshProps) => void'
        }
      },
      onRefreshChange: {
        defaultValue: null,
        description: 'Callback for when the refresh interval changes.\n' + 'Supply onRefreshChange to show refresh interval inputs in quick select popover',
        name: 'onRefreshChange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ApplyRefreshInterval'
        }
      },
      onTimeChange: {
        defaultValue: null,
        description: 'Callback for when the time changes.',
        name: 'onTimeChange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(props: OnTimeChangeProps) => void'
        }
      },
      recentlyUsedRanges: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'recentlyUsedRanges',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'DurationRange[]'
        }
      },
      refreshInterval: {
        defaultValue: {
          value: '0'
        },
        description: 'Refresh interval in milliseconds',
        name: 'refreshInterval',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      showUpdateButton: {
        defaultValue: {
          value: 'true'
        },
        description: 'Set showUpdateButton to false to immediately invoke onTimeChange for all start and end changes.',
        name: 'showUpdateButton',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      start: {
        defaultValue: {
          value: 'now-15m'
        },
        description: '',
        name: 'start',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      timeFormat: {
        defaultValue: {
          value: 'HH:mm'
        },
        description: 'Specifies the formatted used when displaying times',
        name: 'timeFormat',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      utcOffset: {
        defaultValue: null,
        description: '',
        name: 'utcOffset',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      updateButtonProps: {
        defaultValue: null,
        description: 'Props passed to the update button',
        name: 'updateButtonProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_date_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Pick<OuiSuperUpdateButtonProps, "style" | "children" | "color" | "className" | "aria-label" | "data-test-subj" | "size" | "iconType" | "fill" | ... 7 more ... | "toolTipProps">>'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}