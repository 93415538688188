/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiBeacon } from '../beacon';
import { OuiButtonEmpty } from '../button';
import { OuiFlexGroup, OuiFlexItem } from '../flex';
import { OuiI18n } from '../i18n';
import { OuiPopover, OuiPopoverFooter, OuiPopoverTitle } from '../popover';
import { OuiTitle } from '../title';
import { OuiTourStepIndicator } from './tour_step_indicator';
import { htmlIdGenerator } from '../../services';
export var OuiTourStep = ({
  anchorPosition = 'leftUp',
  children,
  className,
  closePopover = () => {},
  content,
  isStepOpen = false,
  minWidth = true,
  onFinish,
  step = 1,
  stepsTotal,
  style,
  subtitle,
  title,
  decoration = 'beacon',
  footerAction,
  ...rest
}) => {
  const generatedId = htmlIdGenerator();
  const titleId = generatedId();

  if (step === 0) {
    console.warn('OuiTourStep `step` should 1-based indexing. Please update to eliminate 0 indexes.');
  }

  let newStyle;
  let widthClassName;

  if (minWidth === true) {
    widthClassName = 'ouiTour--minWidth-default';
  } else if (minWidth !== false) {
    const value = typeof minWidth === 'number' ? `${minWidth}px` : minWidth;
    newStyle = { ...style,
      minWidth: value
    };
  }

  const classes = classNames('ouiTour', widthClassName, className);
  const finishButtonProps = {
    color: 'text',
    flush: 'right',
    size: 'xs'
  };
  const footer = <OuiFlexGroup responsive={false} justifyContent={stepsTotal > 1 ? 'spaceBetween' : 'flexEnd'}>
      {stepsTotal > 1 && <OuiFlexItem grow={false}>
          <ul className="ouiTourFooter__stepList">
            {[...Array(stepsTotal).keys()].map((_, i) => {
          let status = 'complete';

          if (step === i + 1) {
            status = 'active';
          } else if (step <= i) {
            status = 'incomplete';
          }

          return <OuiTourStepIndicator key={i} number={i + 1} status={status} />;
        })}
          </ul>
        </OuiFlexItem>}

      {footerAction ? <OuiFlexItem grow={false}>{footerAction}</OuiFlexItem> : <OuiFlexItem grow={false}>
          <OuiI18n tokens={['ouiTourStep.endTour', 'ouiTourStep.skipTour', 'ouiTourStep.closeTour']} defaults={['End tour', 'Skip tour', 'Close tour']}>
            {([endTour, skipTour, closeTour]) => {
          let content = closeTour;

          if (stepsTotal > 1) {
            content = stepsTotal === step ? endTour : skipTour;
          }

          return <OuiButtonEmpty onClick={onFinish} {...finishButtonProps}>
                  {content}
                </OuiButtonEmpty>;
        }}
          </OuiI18n>
        </OuiFlexItem>}
    </OuiFlexGroup>;
  const hasBeacon = decoration === 'beacon';
  return <OuiPopover anchorPosition={anchorPosition} button={children} closePopover={closePopover} isOpen={isStepOpen} ownFocus={false} panelClassName={classes} panelStyle={newStyle || style} offset={hasBeacon ? 10 : 0} aria-labelledby={titleId} arrowChildren={hasBeacon && <OuiBeacon className="ouiTour__beacon" />} {...rest}>
      <OuiPopoverTitle className="ouiTourHeader" id={titleId}>
        <OuiTitle size="xxxs" className="ouiTourHeader__subtitle">
          <h1>{subtitle}</h1>
        </OuiTitle>
        <OuiTitle size="xxs" className="ouiTourHeader__title">
          <h2>{title}</h2>
        </OuiTitle>
      </OuiPopoverTitle>
      <div className="ouiTour__content">{content}</div>
      <OuiPopoverFooter className="ouiTourFooter">{footer}</OuiPopoverFooter>
    </OuiPopover>;
};
OuiTourStep.propTypes = {
  /**
     * Element to which the tour step popover attaches when open
     */
  children: PropTypes.element.isRequired,

  /**
     * Contents of the tour step popover
     */
  content: PropTypes.node.isRequired,

  /**
     * Step will display if set to `true`
     */
  isStepOpen: PropTypes.bool,

  /**
     * Sets the min-width of the tour popover,
     * set to `true` to use the default size,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  minWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
     * Function to call for 'Skip tour' and 'End tour' actions
     */
  onFinish: PropTypes.func.isRequired,

  /**
     * The number of the step within the parent tour. 1-based indexing.
     */
  step: PropTypes.number.isRequired,

  /**
     * The total number of steps in the tour
     */
  stepsTotal: PropTypes.number.isRequired,

  /**
     * Optional, standard DOM `style` attribute. Passed to the OuiPopover panel.
     */
  style: PropTypes.any,

  /**
     * Smaller title text that appears atop each step in the tour. The subtitle gets wrapped in the appropriate heading level.
     */
  subtitle: PropTypes.node.isRequired,

  /**
     * Larger title text specific to this step. The title gets wrapped in the appropriate heading level.
     */
  title: PropTypes.node.isRequired,

  /**
     * Extra visual indication of step location
     */
  decoration: PropTypes.oneOf(["none", "beacon"]),

  /**
     * Element to replace the 'Skip tour' link in the footer
     */
  footerAction: PropTypes.element,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiTourStep.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTourStep',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: 'Element to which the tour step popover attaches when open',
        name: 'children',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      content: {
        defaultValue: null,
        description: 'Contents of the tour step popover',
        name: 'content',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      isStepOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'Step will display if set to `true`',
        name: 'isStepOpen',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minWidth: {
        defaultValue: {
          value: 'true'
        },
        description: 'Sets the min-width of the tour popover,\n' + 'set to `true` to use the default size,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'minWidth',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      },
      onFinish: {
        defaultValue: null,
        description: "Function to call for 'Skip tour' and 'End tour' actions",
        name: 'onFinish',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number of the step within the parent tour. 1-based indexing.',
        name: 'step',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      stepsTotal: {
        defaultValue: null,
        description: 'The total number of steps in the tour',
        name: 'stepsTotal',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      style: {
        defaultValue: null,
        description: 'Optional, standard DOM `style` attribute. Passed to the OuiPopover panel.',
        name: 'style',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      subtitle: {
        defaultValue: null,
        description: 'Smaller title text that appears atop each step in the tour. The subtitle gets wrapped in the appropriate heading level.',
        name: 'subtitle',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      title: {
        defaultValue: null,
        description: 'Larger title text specific to this step. The title gets wrapped in the appropriate heading level.',
        name: 'title',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      decoration: {
        defaultValue: {
          value: 'beacon'
        },
        description: 'Extra visual indication of step location',
        name: 'decoration',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "beacon"',
          value: [{
            value: '"none"'
          }, {
            value: '"beacon"'
          }]
        }
      },
      footerAction: {
        defaultValue: null,
        description: "Element to replace the 'Skip tour' link in the footer",
        name: 'footerAction',
        parent: {
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step.tsx',
          name: 'OuiTourStepProps'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Alternative option to `aria-label` that takes an `id`.\n' + 'Usually takes the `id` of the popover title',
        name: 'aria-labelledby',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      anchorClassName: {
        defaultValue: null,
        description: 'Class name passed to the direct parent of the button',
        name: 'anchorClassName',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      display: {
        defaultValue: null,
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      anchorPosition: {
        defaultValue: {
          value: 'leftUp'
        },
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'PopoverAnchorPosition',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: null,
        description: 'Style and position alteration for arrow-less, left-aligned\n' + 'attachment. Intended for use with inputs as anchors, e.g.\n' + 'OuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLDivElement) => void'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<OuiFocusTrapProps, "clickOutsideDisables" | "noIsolation" | "scrollLock">'
        }
      },
      hasArrow: {
        defaultValue: null,
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node\n' + 'Set to `false` to prevent initial auto-focus. Use only\n' + 'when your app handles setting initial focus state.',
        name: 'initialFocus',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string | false | HTMLElement | (() => HTMLElement)'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to OuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ownFocus: {
        defaultValue: null,
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the OuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: null,
        description: 'OuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the OuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiPanel',
        name: 'panelProps',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<(DisambiguateSet<_OuiPanelButtonlike, _OuiPanelDivlike> & _OuiPanelDivlike) | (DisambiguateSet<_OuiPanelDivlike, _OuiPanelButtonlike> & _OuiPanelButtonlike), "value" | ... 270 more ... | "borderRadius">'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      onTrapDeactivation: {
        defaultValue: null,
        description: 'Function callback for when the focus trap is deactivated',
        name: 'onTrapDeactivation',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      buffer: {
        defaultValue: null,
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`\n' + 'Default is 16',
        name: 'buffer',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `OuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      button: {
        defaultValue: null,
        description: 'Triggering element for which to align the popover to',
        name: 'button',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      closePopover: {
        defaultValue: {
          value: '() => {}'
        },
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      }
    },
    extendedInterfaces: ['OuiTourStepProps', 'CommonProps', 'OuiPopoverProps']
  };
} catch (e) {}