/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiSplitPanel } from '../../../../../src/components/panel';
export var GuideSectionExample = ({
  example,
  tabs,
  ghostBackground = false,
  tabContent,
  demoPanelProps
}) => {
  const classes = classNames(demoPanelProps?.className, {
    guideDemo__ghostBackground: ghostBackground
  });
  return <OuiSplitPanel.Outer hasBorder hasShadow={false}>
      <OuiSplitPanel.Inner color="plain" {...demoPanelProps} className={classes}>
        {example}
      </OuiSplitPanel.Inner>
      {(tabs || tabContent) && <OuiSplitPanel.Inner paddingSize="none" color="subdued">
          {tabs}
          {tabContent}
        </OuiSplitPanel.Inner>}
    </OuiSplitPanel.Outer>;
};
GuideSectionExample.propTypes = {
  example: PropTypes.node.isRequired,
  tabs: PropTypes.node,

  /** Forces display of a certain content (playground props table) */
  tabContent: PropTypes.node,
  ghostBackground: PropTypes.bool,
  demoPanelProps: PropTypes.shape({
    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,

    /**
       * When true the panel will grow in height to match `OuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,

    /**
       * Background color of the panel;
       * Usually a lightened form of the brand colors
       */
    color: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  })
};

try {
  GuideSectionExample.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'GuideSectionExample',
    methods: [],
    props: {
      example: {
        defaultValue: null,
        description: '',
        name: 'example',
        parent: {
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      tabs: {
        defaultValue: null,
        description: '',
        name: 'tabs',
        parent: {
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      tabContent: {
        defaultValue: null,
        description: 'Forces display of a certain content (playground props table)',
        name: 'tabContent',
        parent: {
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      ghostBackground: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'ghostBackground',
        parent: {
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      demoPanelProps: {
        defaultValue: null,
        description: '',
        name: 'demoPanelProps',
        parent: {
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement> & Pick<_OuiPanelProps, "color" | "className" | "aria-label" | "data-test-subj" | "grow" | "paddingSize" | "panelRef">'
        }
      }
    },
    extendedInterfaces: ['GuideSectionExample']
  };
} catch (e) {}