/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { OuiPopover } from './popover';
import { OuiPortal } from '../portal';
/**
 * Injects the OuiPopover next to the button via OuiPortal
 * then the button element is moved into the popover dom.
 * On unmount, the button is moved back to its original location.
 */

export class OuiWrappingPopover extends Component {
  portal = null;
  anchor = null;

  componentDidMount() {
    if (this.anchor) {
      this.anchor.insertAdjacentElement('beforebegin', this.props.button);
    }
  }

  componentWillUnmount() {
    if (this.props.button.parentNode) {
      if (this.portal) {
        this.portal.insertAdjacentElement('beforebegin', this.props.button);
      }
    }
  }

  setPortalRef = node => {
    this.portal = node;
  };
  setAnchorRef = node => {
    this.anchor = node;
  };

  render() {
    const {
      button,
      ...rest
    } = this.props;
    return <OuiPortal portalRef={this.setPortalRef} insert={{
      sibling: this.props.button,
      position: 'after'
    }}>
        <OuiPopover {...rest} button={<div ref={this.setAnchorRef} className="ouiWrappingPopover__anchor" />} />
      </OuiPortal>;
  }

}
OuiWrappingPopover.propTypes = {
  button: PropTypes.any.isRequired
};

try {
  OuiWrappingPopover.__docgenInfo = {
    tags: {},
    description: 'Injects the OuiPopover next to the button via OuiPortal\n' + 'then the button element is moved into the popover dom.\n' + 'On unmount, the button is moved back to its original location.',
    displayName: 'OuiWrappingPopover',
    methods: [],
    props: {
      button: {
        defaultValue: null,
        description: 'Triggering element for which to align the popover to',
        name: 'button',
        parent: {
          fileName: 'docs/src/components/popover/wrapping_popover.tsx',
          name: 'OuiWrappingPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/wrapping_popover.tsx',
          name: 'OuiWrappingPopoverProps'
        }],
        required: true,
        type: {
          name: 'HTMLElement'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + 'Provide a name to the popover panel\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      anchorClassName: {
        defaultValue: null,
        description: 'Class name passed to the direct parent of the button',
        name: 'anchorClassName',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      anchorPosition: {
        defaultValue: null,
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'PopoverAnchorPosition',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: null,
        description: 'Style and position alteration for arrow-less, left-aligned\n' + 'attachment. Intended for use with inputs as anchors, e.g.\n' + 'OuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLDivElement) => void'
        }
      },
      closePopover: {
        defaultValue: null,
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      display: {
        defaultValue: null,
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<OuiFocusTrapProps, "clickOutsideDisables" | "noIsolation" | "scrollLock">'
        }
      },
      hasArrow: {
        defaultValue: null,
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node\n' + 'Set to `false` to prevent initial auto-focus. Use only\n' + 'when your app handles setting initial focus state.',
        name: 'initialFocus',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string | false | HTMLElement | (() => HTMLElement)'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to OuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ownFocus: {
        defaultValue: null,
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the OuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: null,
        description: 'OuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the OuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to OuiPanel',
        name: 'panelProps',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Pick<(DisambiguateSet<_OuiPanelButtonlike, _OuiPanelDivlike> & _OuiPanelDivlike) | (DisambiguateSet<_OuiPanelDivlike, _OuiPanelButtonlike> & _OuiPanelButtonlike), "value" | ... 270 more ... | "borderRadius">'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      onTrapDeactivation: {
        defaultValue: null,
        description: 'Function callback for when the focus trap is deactivated',
        name: 'onTrapDeactivation',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      buffer: {
        defaultValue: null,
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`\n' + 'Default is 16',
        name: 'buffer',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `OuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        },
        declarations: [{
          fileName: 'docs/src/components/popover/popover.tsx',
          name: 'OuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['OuiWrappingPopoverProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiPopoverProps']
  };
} catch (e) {}