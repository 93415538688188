/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { isWithinRange } from '../../../services/number';
import { OuiInputPopover } from '../../popover';
import { htmlIdGenerator } from '../../../services/accessibility';
import { OuiRangeHighlight } from './range_highlight';
import { OuiRangeInput } from './range_input';
import { OuiRangeLabel } from './range_label';
import { OuiRangeSlider } from './range_slider';
import { OuiRangeTooltip } from './range_tooltip';
import { OuiRangeTrack } from './range_track';
import { OuiRangeWrapper } from './range_wrapper';
export class OuiRange extends Component {
  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    fullWidth: false,
    compressed: false,
    isLoading: false,
    showLabels: false,
    showInput: false,
    showRange: false,
    showTicks: false,
    showValue: false,
    levels: []
  };
  preventPopoverClose = false;
  state = {
    id: this.props.id || htmlIdGenerator()(),
    isPopoverOpen: false
  };
  handleOnChange = e => {
    const isValid = isWithinRange(this.props.min, this.props.max, e.currentTarget.value);

    if (this.props.onChange) {
      this.props.onChange(e, isValid);
    }
  };

  get isValid() {
    return isWithinRange(this.props.min, this.props.max, this.props.value || '');
  }

  onInputFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.setState({
      isPopoverOpen: true
    });
  };
  onInputBlur = e => setTimeout(() => {
    // Safari does not recognize any focus-related eventing for input[type=range]
    // making it impossible to capture its state using active/focus/relatedTarget
    // Instead, a prevention flag is set on mousedown, with a waiting period here.
    // Mousedown is viable because in the popover case, it is inaccessible via keyboard (intentionally)
    if (this.preventPopoverClose) {
      this.preventPopoverClose = false;
      return;
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.closePopover();
  }, 200);
  closePopover = () => {
    this.preventPopoverClose = false;
    this.setState({
      isPopoverOpen: false
    });
  };

  render() {
    const {
      className,
      compressed,
      disabled,
      fullWidth,
      isLoading,
      readOnly,
      id: propsId,
      max,
      min,
      name,
      step,
      showLabels,
      showInput,
      showTicks,
      tickInterval,
      ticks,
      levels,
      showRange,
      showValue,
      valueAppend,
      valuePrepend,
      onBlur,
      onChange,
      onFocus,
      value,
      style,
      tabIndex,
      isInvalid,
      ...rest
    } = this.props;
    const {
      id
    } = this.state;
    const digitTolerance = Math.max(String(min).length, String(max).length);
    const showInputOnly = showInput === 'inputWithPopover';
    const canShowDropdown = showInputOnly && !readOnly && !disabled;
    const theInput = !!showInput ? <OuiRangeInput id={id} min={min} max={max} digitTolerance={digitTolerance} step={step} value={value} readOnly={readOnly} disabled={disabled} compressed={compressed} onChange={this.handleOnChange} name={name} onFocus={canShowDropdown ? this.onInputFocus : onFocus} onBlur={canShowDropdown ? this.onInputBlur : onBlur} fullWidth={showInputOnly && fullWidth} isLoading={showInputOnly && isLoading} isInvalid={isInvalid} autoSize={!showInputOnly} {...rest} /> : null;
    const classes = classNames('ouiRange', {
      'ouiRange--hasInput': showInput
    }, className);
    const theRange = <OuiRangeWrapper className={classes} fullWidth={fullWidth} compressed={compressed}>
        {showLabels && <OuiRangeLabel side="min" disabled={disabled}>
            {min}
          </OuiRangeLabel>}
        <OuiRangeTrack disabled={disabled} compressed={compressed} max={max} min={min} step={step} showTicks={showTicks} tickInterval={tickInterval} ticks={ticks} levels={levels} onChange={this.handleOnChange} value={value} aria-hidden={showInput === true}>
          <OuiRangeSlider id={showInput ? undefined : id} // Attach id only to the input if there is one
        name={name} min={min} max={max} step={step} value={value} disabled={disabled} compressed={compressed} onChange={this.handleOnChange} style={style} showTicks={showTicks} showRange={showRange} tabIndex={showInput ? -1 : tabIndex} onMouseDown={showInputOnly ? () => this.preventPopoverClose = true : undefined} onFocus={showInput === true ? undefined : onFocus} onBlur={showInputOnly ? this.onInputBlur : onBlur} aria-hidden={showInput === true ? true : false} {...rest} />

          {showRange && this.isValid && <OuiRangeHighlight compressed={compressed} showTicks={showTicks} min={Number(min)} max={Number(max)} lowerValue={Number(min)} upperValue={Number(value)} />}

          {showValue && !!String(value).length && <OuiRangeTooltip compressed={compressed} value={value} max={max} min={min} name={name} showTicks={showTicks} valuePrepend={valuePrepend} valueAppend={valueAppend} />}
        </OuiRangeTrack>
        {showLabels && <OuiRangeLabel side="max" disabled={disabled}>
            {max}
          </OuiRangeLabel>}
        {showInput && !showInputOnly && <>
            <div className={showTicks || ticks ? 'ouiRange__slimHorizontalSpacer' : 'ouiRange__horizontalSpacer'} />
            {theInput}
          </>}
      </OuiRangeWrapper>;
    const thePopover = showInputOnly ? <OuiInputPopover className="ouiRange__popover" input={theInput} // `showInputOnly` confirms existence
    fullWidth={fullWidth} isOpen={this.state.isPopoverOpen} closePopover={this.closePopover} disableFocusTrap={true}>
        {theRange}
      </OuiInputPopover> : undefined;
    return thePopover ? thePopover : theRange;
  }

}
OuiRange.propTypes = {
  compressed: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,

  /**
     * Create colored indicators for certain intervals
     */
  levels: PropTypes.arrayOf(PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    color: PropTypes.oneOf(["primary", "success", "warning", "danger"]).isRequired
  }).isRequired),
  step: PropTypes.number,

  /**
     * Pass `true` to displays an extra input control for direct manipulation.
     * Pass `'inputWithPopover'` to only show the input but show the range in a dropdown.
     */
  showInput: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["inputWithPopover"])]),

  /**
     * Shows static min/max labels on the sides of the range slider
     */
  showLabels: PropTypes.bool,

  /**
     * Shows a thick line from min to value
     */
  showRange: PropTypes.bool,

  /**
     * Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)
     */
  showTicks: PropTypes.bool,

  /**
     * Shows a tooltip styled value
     */
  showValue: PropTypes.bool,

  /**
     * Specified ticks at specified values
     */
  ticks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired
  }).isRequired),

  /**
     * Modifies the number of tick marks and at what interval
     */
  tickInterval: PropTypes.number,

  /**
     * Appends to the tooltip
     */
  valueAppend: PropTypes.node,

  /**
     * Prepends to the tooltip
     */
  valuePrepend: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiRange.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRange',
    methods: [],
    props: {
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      levels: {
        defaultValue: {
          value: '[]'
        },
        description: 'Create colored indicators for certain intervals',
        name: 'levels',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeLevel[]'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'Specifies the granularity that the value must adhere to.\n' + "Accepts a `number` or the string `'any'` for no stepping to allow for any value.\n" + 'Defaults to `1`',
        name: 'step',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      showInput: {
        defaultValue: {
          value: 'false'
        },
        description: 'Pass `true` to displays an extra input control for direct manipulation.\n' + "Pass `'inputWithPopover'` to only show the input but show the range in a dropdown.",
        name: 'showInput',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean | "inputWithPopover"'
        }
      },
      showLabels: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows static min/max labels on the sides of the range slider',
        name: 'showLabels',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showValue: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows a tooltip styled value',
        name: 'showValue',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      valueAppend: {
        defaultValue: null,
        description: 'Appends to the tooltip',
        name: 'valueAppend',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      valuePrepend: {
        defaultValue: null,
        description: 'Prepends to the tooltip',
        name: 'valuePrepend',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range.tsx',
          name: 'OuiRangeProps'
        }],
        required: false,
        type: {
          name: '(event: any, isValid: boolean) => void'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      max: {
        defaultValue: {
          value: '100'
        },
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: {
          value: '0'
        },
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      side: {
        defaultValue: null,
        description: '',
        name: 'side',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"max" | "min"',
          value: [{
            value: '"max"'
          }, {
            value: '"min"'
          }]
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      controlOnly: {
        defaultValue: null,
        description: 'Completely removes form control layout wrapper and ignores\n' + 'icon, prepend, and append. Best used inside OuiFormControlLayoutDelimited.',
        name: 'controlOnly',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      autoSize: {
        defaultValue: null,
        description: '',
        name: 'autoSize',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiRangeProps', 'CommonProps', 'OuiRangeInputProps', 'HTMLAttributes', 'InputHTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}