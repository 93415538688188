/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component, createRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiSelectableSearch } from './selectable_search';
import { OuiSelectableMessage } from './selectable_message';
import { OuiSelectableList } from './selectable_list';
import { OuiLoadingSpinner } from '../loading';
import { OuiSpacer } from '../spacer';
import { getMatchingOptions } from './matching_options';
import { keys, htmlIdGenerator } from '../../services';
import { OuiI18n } from '../i18n';
export class OuiSelectable extends Component {
  static defaultProps = {
    options: [],
    singleSelection: false,
    searchable: false,
    isPreFiltered: false
  };
  containerRef = createRef();
  optionsListRef = createRef();
  rootId = htmlIdGenerator();

  constructor(props) {
    super(props);
    const {
      options,
      singleSelection,
      isPreFiltered
    } = props;
    const initialSearchValue = '';
    const visibleOptions = getMatchingOptions(options, initialSearchValue, isPreFiltered); // ensure that the currently selected single option is active if it is in the visibleOptions

    const selectedOptions = options.filter(option => option.checked);
    let activeOptionIndex;

    if (singleSelection && selectedOptions.length === 1) {
      if (visibleOptions.includes(selectedOptions[0])) {
        activeOptionIndex = visibleOptions.indexOf(selectedOptions[0]);
      }
    }

    this.state = {
      activeOptionIndex,
      searchValue: initialSearchValue,
      visibleOptions,
      isFocused: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      options,
      isPreFiltered
    } = nextProps;
    const {
      activeOptionIndex,
      searchValue
    } = prevState;
    const matchingOptions = getMatchingOptions(options, searchValue, isPreFiltered);
    const stateUpdate = {
      visibleOptions: matchingOptions,
      activeOptionIndex
    };

    if (activeOptionIndex != null && activeOptionIndex >= matchingOptions.length) {
      stateUpdate.activeOptionIndex = -1;
    }

    return stateUpdate;
  }

  hasActiveOption = () => {
    return this.state.activeOptionIndex != null;
  };
  onFocus = () => {
    if (!this.state.visibleOptions.length || this.state.activeOptionIndex) {
      return;
    }

    const firstSelected = this.state.visibleOptions.findIndex(option => option.checked && !option.disabled && !option.isGroupLabel);

    if (firstSelected > -1) {
      this.setState({
        activeOptionIndex: firstSelected,
        isFocused: true
      });
    } else {
      this.setState({
        activeOptionIndex: this.state.visibleOptions.findIndex(option => !option.disabled && !option.isGroupLabel),
        isFocused: true
      });
    }
  };
  onKeyDown = event => {
    const optionsList = this.optionsListRef.current;

    switch (event.key) {
      case keys.ARROW_UP:
        event.preventDefault();
        event.stopPropagation();
        this.incrementActiveOptionIndex(-1);
        break;

      case keys.ARROW_DOWN:
        event.preventDefault();
        event.stopPropagation();
        this.incrementActiveOptionIndex(1);
        break;

      case keys.ENTER:
        event.preventDefault();
        event.stopPropagation();

        if (this.state.activeOptionIndex != null && optionsList) {
          optionsList.onAddOrRemoveOption(this.state.visibleOptions[this.state.activeOptionIndex]);
        }

        break;

      default:
        this.setState({
          activeOptionIndex: undefined
        }, this.onFocus);
        break;
    }
  };
  incrementActiveOptionIndex = amount => {
    // If there are no options available, do nothing.
    if (!this.state.visibleOptions.length) {
      return;
    }

    this.setState(({
      activeOptionIndex,
      visibleOptions
    }) => {
      let nextActiveOptionIndex;

      if (activeOptionIndex == null) {
        // If this is the beginning of the user's keyboard navigation of the menu, then we'll focus
        // either the first or last item.
        nextActiveOptionIndex = amount < 0 ? visibleOptions.length - 1 : 0;
      } else {
        nextActiveOptionIndex = activeOptionIndex + amount;

        if (nextActiveOptionIndex < 0) {
          nextActiveOptionIndex = visibleOptions.length - 1;
        } else if (nextActiveOptionIndex === visibleOptions.length) {
          nextActiveOptionIndex = 0;
        }
      } // Group titles and disabled options are included in option list but are not selectable


      const direction = amount > 0 ? 1 : -1;

      while (visibleOptions[nextActiveOptionIndex].isGroupLabel || visibleOptions[nextActiveOptionIndex].disabled) {
        nextActiveOptionIndex = nextActiveOptionIndex + direction;

        if (nextActiveOptionIndex < 0) {
          nextActiveOptionIndex = visibleOptions.length - 1;
        } else if (nextActiveOptionIndex === visibleOptions.length) {
          nextActiveOptionIndex = 0;
        }
      }

      return {
        activeOptionIndex: nextActiveOptionIndex
      };
    });
  };
  onSearchChange = (visibleOptions, searchValue) => {
    this.setState({
      visibleOptions,
      searchValue,
      activeOptionIndex: undefined
    }, () => {
      if (this.state.isFocused) {
        this.onFocus();
      }
    });

    if (this.props.searchProps && this.props.searchProps.onSearch) {
      this.props.searchProps.onSearch(searchValue);
    }
  };
  onContainerBlur = e => {
    // Ignore blur events when moving from search to option to avoid activeOptionIndex conflicts
    if (this.containerRef.current.contains(e.relatedTarget)) return;
    this.setState({
      activeOptionIndex: undefined,
      isFocused: false
    });
  };
  onOptionClick = options => {
    const {
      isPreFiltered,
      onChange,
      searchProps
    } = this.props;
    const {
      searchValue
    } = this.state;
    const visibleOptions = getMatchingOptions(options, searchValue, isPreFiltered);
    this.setState({
      visibleOptions
    });

    if (onChange) {
      onChange(options);
    }

    if (searchProps && searchProps.onChange) {
      searchProps.onChange(visibleOptions, searchValue);
    }
  };
  scrollToItem = (index, align) => {
    this.optionsListRef.current?.listRef?.scrollToItem(index, align);
  };

  render() {
    const {
      id,
      children,
      className,
      options,
      onChange,
      searchable,
      searchProps,
      singleSelection,
      isLoading,
      listProps,
      renderOption,
      height,
      allowExclusions,
      'aria-label': ariaLabel,
      'aria-describedby': ariaDescribedby,
      loadingMessage,
      noMatchesMessage,
      emptyMessage,
      isPreFiltered,
      ...rest
    } = this.props;
    const {
      searchValue,
      visibleOptions,
      activeOptionIndex
    } = this.state; // Some messy destructuring here to remove aria-label/describedby from searchProps and listProps
    // Made messier by some TS requirements
    // The aria attributes are then used in getAccessibleName() to place them where they need to go

    const unknownAccessibleName = {
      'aria-label': undefined,
      'aria-describedby': undefined
    };
    const {
      'aria-label': searchAriaLabel,
      'aria-describedby': searchAriaDescribedby,
      onChange: propsOnChange,
      onSearch,
      ...cleanedSearchProps
    } = searchProps || unknownAccessibleName;
    const {
      'aria-label': listAriaLabel,
      'aria-describedby': listAriaDescribedby,
      ...cleanedListProps
    } = listProps || unknownAccessibleName;
    const classes = classNames('ouiSelectable', {
      'ouiSelectable-fullHeight': height === 'full'
    }, className);
    /** Create Id's */

    let messageContentId = this.rootId('messageContent');
    const listId = this.rootId('listbox');

    const makeOptionId = index => {
      if (typeof index === 'undefined') {
        return '';
      }

      return `${listId}_option-${index}`;
    };
    /** Create message content that replaces the list if no options are available (yet) */


    let messageContent;

    if (isLoading) {
      if (loadingMessage === undefined || typeof loadingMessage === 'string') {
        messageContent = <>
            <OuiLoadingSpinner size="m" />
            <OuiSpacer size="xs" />
            <p>
              {loadingMessage || <OuiI18n token="ouiSelectable.loadingOptions" default="Loading options" />}
            </p>
          </>;
      } else {
        messageContent = React.cloneElement(loadingMessage, {
          id: messageContentId,
          ...loadingMessage.props
        });
      }
    } else if (searchValue && visibleOptions.length === 0) {
      if (noMatchesMessage === undefined || typeof noMatchesMessage === 'string') {
        messageContent = <p>
            {noMatchesMessage || <OuiI18n token="ouiSelectable.noMatchingOptions" default="{searchValue} doesn't match any options" values={{
            searchValue: <strong>{searchValue}</strong>
          }} />}
          </p>;
      } else {
        messageContent = React.cloneElement(noMatchesMessage, {
          id: messageContentId,
          ...noMatchesMessage.props
        });
      }
    } else if (!options.length) {
      if (emptyMessage === undefined || typeof emptyMessage === 'string') {
        messageContent = <p>
            {emptyMessage || <OuiI18n token="ouiSelectable.noAvailableOptions" default="No options available" />}
          </p>;
      } else {
        messageContent = React.cloneElement(emptyMessage, {
          id: messageContentId,
          ...emptyMessage.props
        });
      }
    } else {
      messageContentId = '';
    }
    /**
     * There are lots of ways to add an accessible name
     * Usually we want the same name for the input and the listbox (which is added by aria-label/describedby)
     * But you can always override it using searchProps or listProps
     * This finds the correct name to use
     *
     * TODO: This doesn't handle being labelled (<label for="idOfInput">)
     */


    const getAccessibleName = (props, messageContentId) => {
      if (props && props['aria-label']) {
        return {
          'aria-label': props['aria-label']
        };
      }

      const messageContentIdString = messageContentId ? ` ${messageContentId}` : '';

      if (props && props['aria-describedby']) {
        return {
          'aria-describedby': `${props['aria-describedby']}${messageContentIdString}`
        };
      }

      if (ariaLabel) {
        return {
          'aria-label': ariaLabel
        };
      }

      if (ariaDescribedby) {
        return {
          'aria-describedby': `${ariaDescribedby}${messageContentIdString}`
        };
      }

      return {};
    };

    const searchAccessibleName = getAccessibleName(searchProps, messageContentId);
    const searchHasAccessibleName = Boolean(Object.keys(searchAccessibleName).length);
    const search = searchable ? <OuiI18n token="ouiSelectable.placeholderName" default="Filter options">
        {placeholderName => <OuiSelectableSearch key="listSearch" options={options} onChange={this.onSearchChange} listId={this.optionsListRef.current ? listId : undefined} // Only pass the listId if it exists on the page
      aria-activedescendant={makeOptionId(activeOptionIndex)} // the current faux-focused option
      placeholder={placeholderName} isPreFiltered={isPreFiltered ?? false} {...searchHasAccessibleName ? searchAccessibleName : {
        'aria-label': placeholderName
      }} {...cleanedSearchProps} />}
      </OuiI18n> : undefined;
    const listAccessibleName = getAccessibleName(listProps);
    const listHasAccessibleName = Boolean(Object.keys(listAccessibleName).length);
    const list = messageContent ? <OuiSelectableMessage id={messageContentId} bordered={listProps && listProps.bordered}>
        {messageContent}
      </OuiSelectableMessage> : <OuiI18n token="ouiSelectable.placeholderName" default="Filter options">
        {placeholderName => <OuiSelectableList key="list" options={options} visibleOptions={visibleOptions} searchValue={searchValue} activeOptionIndex={activeOptionIndex} setActiveOptionIndex={(index, cb) => {
        this.setState({
          activeOptionIndex: index
        }, cb);
      }} onOptionClick={this.onOptionClick} singleSelection={singleSelection} ref={this.optionsListRef} renderOption={renderOption} height={height} allowExclusions={allowExclusions} searchable={searchable} makeOptionId={makeOptionId} listId={listId} {...listHasAccessibleName ? listAccessibleName : searchable && {
        'aria-label': placeholderName
      }} {...cleanedListProps} />}
      </OuiI18n>;
    return <div ref={this.containerRef} className={classes} onKeyDown={this.onKeyDown} onBlur={this.onContainerBlur} onFocus={this.onFocus} {...rest}>
        {children && children(list, search)}
      </div>;
  }

}
OuiSelectable.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Hooks up a search box to filter the list (boolean)
       */
  searchable: PropTypes.oneOfType([PropTypes.oneOf([false]).isRequired, PropTypes.oneOf([true]).isRequired]).isRequired,

  /**
       * Passes props down to the `OuiFieldSearch`
       */
  searchProps: PropTypes.any,

  /**
       * Function that takes the `list` node and then
       * the `search` node (if `searchable` is applied)
       */
  children: PropTypes.func,

  /**
       * Array of OuiSelectableOption objects. See #OuiSelectableOptionProps
       */
  options: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Optional `boolean`.
       * Set to `true` to indicate object is just a grouping label, not a selectable item
       */
    isGroupLabel: PropTypes.oneOfType([PropTypes.oneOf([true]).isRequired, PropTypes.oneOf([false])]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
       * Visible label of option.
       * Must be unique across items if `key` is not supplied
       */
    label: PropTypes.string,

    /**
       * Optionally change the searchable term by passing a different string other than the `label`.
       * Best used when creating a custom `optionRender` to separate the label from metadata but allowing to search on both
       */
    searchableLabel: PropTypes.string,

    /**
       * Must be unique across items.
       * Will be used to match options instead of `label`
       */
    key: PropTypes.string,

    /**
       * Leave `undefined` to indicate not selected,
       * 'on' to indicate inclusion and
       * 'off' to indicate exclusion
       */
    checked: PropTypes.oneOf(["on", "off", undefined]),
    disabled: PropTypes.bool,

    /**
       * Node to add between the selection icon and the label
       */
    prepend: PropTypes.node,

    /**
       * Node to add to the far right of the item
       */
    append: PropTypes.node,
    ref: PropTypes.func
  }).isRequired).isRequired,

  /**
       * Passes back the altered `options` array with selected options as
       */
  onChange: PropTypes.func,

  /**
       * Sets the single selection policy of
       * `false`: allows multiple selection
       * `true`: only allows one selection
       * `always`: can and must have only one selection
       */
  singleSelection: PropTypes.oneOfType([PropTypes.oneOf(["always"]), PropTypes.bool.isRequired]),

  /**
       * Allows marking options as `checked='off'` as well as `'on'`
       */
  allowExclusions: PropTypes.bool,

  /**
       * Show an loading indicator while you load and hook up your data
       */
  isLoading: PropTypes.bool,

  /**
       * Sets the max height in pixels or pass `full` to allow
       * the whole group to fill the height of its container and
       * allows the list grow as well
       */
  height: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf(["full"])]),

  /**
       * See #OuiSelectableOptionsList
       */
  listProps: PropTypes.any,

  /**
       * Custom render function for each option.
       * Returns `(option, searchValue)`
       */
  renderOption: PropTypes.func,

  /**
       * Customize the loading message. Pass a string to simply change the text,
       * or a node to replace the whole content.
       */
  loadingMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),

  /**
       * Customize the no matches message. Pass a string to simply change the text,
       * or a node to replace the whole content.
       */
  noMatchesMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),

  /**
       * Customize the empty message. Pass a string to simply change the text,
       * or a node to replace the whole content.
       */
  emptyMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),

  /**
       * Control whether or not options get filtered internally or if consumer will filter
       * Default: false
       */
  isPreFiltered: PropTypes.bool
};

try {
  OuiSelectable.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSelectable',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      searchable: {
        defaultValue: {
          value: 'false'
        },
        description: 'Hooks up a search box to filter the list (boolean)',
        name: 'searchable',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      searchProps: {
        defaultValue: null,
        description: 'Passes props down to the `OuiFieldSearch`',
        name: 'searchProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<OuiSelectableSearchProps<T>>'
        }
      },
      children: {
        defaultValue: null,
        description: 'Function that takes the `list` node and then\n' + 'the `search` node (if `searchable` is applied)',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(list: ReactElement<typeof OuiSelectableList | FunctionComponent<OuiSelectableMessageProps>, string | ((props: any) => ReactElement<any, string | ... 1 more ... | (new (props: any) => Component<...>)>) | (new (props: any) => Component<...>)>, search: ReactElement<...>) => ReactNode'
        }
      },
      options: {
        defaultValue: {
          value: '[]'
        },
        description: 'Array of OuiSelectableOption objects. See #OuiSelectableOptionProps',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ExclusiveUnion<OuiSelectableGroupLabelOption<T>, OuiSelectableLIOption<T>>[]'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'Passes back the altered `options` array with selected options as',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(options: ExclusiveUnion<OuiSelectableGroupLabelOption<T>, OuiSelectableLIOption<T>>[]) => void'
        }
      },
      singleSelection: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the single selection policy of\n' + '`false`: allows multiple selection\n' + '`true`: only allows one selection\n' + '`always`: can and must have only one selection',
        name: 'singleSelection',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "always"'
        }
      },
      allowExclusions: {
        defaultValue: null,
        description: "Allows marking options as `checked='off'` as well as `'on'`",
        name: 'allowExclusions',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Show an loading indicator while you load and hook up your data',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      height: {
        defaultValue: null,
        description: 'Sets the max height in pixels or pass `full` to allow\n' + 'the whole group to fill the height of its container and\n' + 'allows the list grow as well',
        name: 'height',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number | "full"'
        }
      },
      listProps: {
        defaultValue: null,
        description: 'See #OuiSelectableOptionsList',
        name: 'listProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiSelectableOptionsListPropsWithDefaults'
        }
      },
      renderOption: {
        defaultValue: null,
        description: 'Custom render function for each option.\n' + 'Returns `(option, searchValue)`',
        name: 'renderOption',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(option: ExclusiveUnion<OuiSelectableGroupLabelOption<T>, OuiSelectableLIOption<T>>, searchValue: string) => ReactNode'
        }
      },
      loadingMessage: {
        defaultValue: null,
        description: 'Customize the loading message. Pass a string to simply change the text,\n' + 'or a node to replace the whole content.',
        name: 'loadingMessage',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      noMatchesMessage: {
        defaultValue: null,
        description: 'Customize the no matches message. Pass a string to simply change the text,\n' + 'or a node to replace the whole content.',
        name: 'noMatchesMessage',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      emptyMessage: {
        defaultValue: null,
        description: 'Customize the empty message. Pass a string to simply change the text,\n' + 'or a node to replace the whole content.',
        name: 'emptyMessage',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      isPreFiltered: {
        defaultValue: {
          value: 'false'
        },
        description: 'Control whether or not options get filtered internally or if consumer will filter\n' + 'Default: false',
        name: 'isPreFiltered',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}