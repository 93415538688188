/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var LEVEL_COLORS = ['primary', 'success', 'warning', 'danger'];
export var OuiRangeLevels = ({
  levels = [],
  max,
  min,
  showTicks,
  compressed
}) => {
  const validateLevelIsInRange = level => {
    if (level.min < min) {
      throw new Error(`The level min of ${level.min} is lower than the min value of ${min}.`);
    }

    if (level.max > max) {
      throw new Error(`The level max of ${level.max} is higher than the max value of ${max}.`);
    }
  };

  const classes = classNames('ouiRangeLevels', {
    'ouiRangeLevels--hasTicks': showTicks,
    'ouiRangeLevels--compressed': compressed
  });
  return <div className={classes}>
      {levels.map((level, index) => {
      validateLevelIsInRange(level);
      const range = level.max - level.min;
      const width = range / (max - min) * 100;
      return <span key={index} style={{
        width: `${width}%`
      }} className={`ouiRangeLevel ouiRangeLevel--${level.color}`} />;
    })}
    </div>;
};
OuiRangeLevels.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    color: PropTypes.oneOf(["primary", "success", "warning", "danger"]).isRequired
  }).isRequired),
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  showTicks: PropTypes.bool,
  compressed: PropTypes.bool
};

try {
  OuiRangeLevels.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeLevels',
    methods: [],
    props: {
      levels: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'levels',
        parent: {
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeLevel[]'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: {
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_levels.tsx',
          name: 'OuiRangeLevelsProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiRangeLevelsProps']
  };
} catch (e) {}