/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { isArray, isNil } from '../../../services/predicate';
import { keys } from '../../../services';
import { OuiPopover, OuiPopoverTitle } from '../../popover';
import { OuiFieldSearch } from '../../form/field_search';
import { OuiFilterButton, OuiFilterSelectItem } from '../../filter_group';
import { OuiLoadingChart } from '../../loading';
import { OuiSpacer } from '../../spacer';
import { OuiIcon } from '../../icon';
import { Query } from '../query';
import { Operator } from '../query/ast';
var defaults = {
  config: {
    multiSelect: true,
    filterWith: 'prefix',
    loadingMessage: 'Loading...',
    noOptionsMessage: 'No options found',
    searchThreshold: 10
  }
};
export class FieldValueSelectionFilter extends Component {
  searchInput = null;

  constructor(props) {
    super(props);
    const {
      options
    } = props.config;
    const preloadedOptions = isArray(options) ? {
      all: options,
      shown: options
    } : null;
    this.selectItems = [];
    this.state = {
      popoverOpen: false,
      error: null,
      options: preloadedOptions,
      activeItems: []
    };
  }

  closePopover() {
    this.setState({
      popoverOpen: false
    });
  }

  onButtonClick() {
    this.setState(prevState => {
      if (!prevState.popoverOpen) {
        // loading options updates the state, so we'll do that in the animation frame
        window.requestAnimationFrame(() => {
          this.loadOptions();
        });
      }

      return {
        options: null,
        error: null,
        popoverOpen: !prevState.popoverOpen
      };
    });
  }

  loadOptions() {
    const loader = this.resolveOptionsLoader();
    this.setState({
      options: null,
      error: null
    });
    loader().then(options => {
      const items = {
        on: [],
        off: [],
        rest: []
      };
      const {
        query,
        config
      } = this.props;
      const multiSelect = this.resolveMultiSelect();

      if (options) {
        options.forEach(op => {
          const optionField = op.field || config.field;

          if (optionField) {
            const clause = multiSelect === 'or' ? query.getOrFieldClause(optionField, op.value) : query.getSimpleFieldClause(optionField, op.value);
            const checked = this.resolveChecked(clause);

            if (!checked) {
              items.rest.push(op);
            } else if (checked === 'on') {
              items.on.push(op);
            } else {
              items.off.push(op);
            }
          }

          return;
        });
      }

      this.setState({
        error: null,
        activeItems: items.on,
        options: {
          all: options,
          shown: [...items.on, ...items.off, ...items.rest]
        }
      });
    }).catch(() => {
      this.setState({
        options: null,
        error: 'Could not load options'
      });
    });
  }

  filterOptions(q = '') {
    this.setState(prevState => {
      if (isNil(prevState.options)) {
        return {};
      }

      const predicate = this.getOptionFilter();
      return { ...prevState,
        options: { ...prevState.options,
          shown: prevState.options.all.filter((option, i, options) => {
            const name = this.resolveOptionName(option).toLowerCase();
            const query = q.toLowerCase();
            return predicate(name, query, options);
          })
        }
      };
    });
  }

  getOptionFilter() {
    const filterWith = this.props.config.filterWith || defaults.config.filterWith;

    if (typeof filterWith === 'function') {
      return filterWith;
    }

    if (filterWith === 'includes') {
      return (name, query) => name.includes(query);
    }

    return (name, query) => name.startsWith(query);
  }

  resolveOptionsLoader = () => {
    const options = this.props.config.options;

    if (isArray(options)) {
      return () => Promise.resolve(options);
    }

    return () => {
      const cachedOptions = this.state.cachedOptions;

      if (cachedOptions) {
        return Promise.resolve(cachedOptions);
      }

      return options().then(opts => {
        // If a cache time is set, populate the cache and also schedule a
        // cache reset.
        if (this.props.config.cache != null && this.props.config.cache > 0) {
          this.setState({
            cachedOptions: opts
          });
          setTimeout(() => {
            this.setState({
              cachedOptions: null
            });
          }, this.props.config.cache);
        }

        return opts;
      });
    };
  };

  resolveOptionName(option) {
    return option.name || option.value.toString();
  }

  onOptionClick(field, value, checked) {
    const multiSelect = this.resolveMultiSelect();
    const {
      config: {
        autoClose = true,
        operator = Operator.EQ
      }
    } = this.props; // we're closing popover only if the user can only select one item... if the
    // user can select more, we'll leave it open so she can continue selecting

    if (!multiSelect && autoClose) {
      this.closePopover();
      const query = checked ? this.props.query.removeSimpleFieldClauses(field) : this.props.query.removeSimpleFieldClauses(field).addSimpleFieldValue(field, value, true, operator);
      this.props.onChange(query);
    } else {
      if (multiSelect === 'or') {
        const query = checked ? this.props.query.removeOrFieldValue(field, value) : this.props.query.addOrFieldValue(field, value, true, operator);
        this.props.onChange(query);
      } else {
        const query = checked ? this.props.query.removeSimpleFieldValue(field, value) : this.props.query.addSimpleFieldValue(field, value, true, operator);
        this.props.onChange(query);
      }
    }
  }

  onKeyDown(index, event) {
    switch (event.key) {
      case keys.ARROW_DOWN:
        if (index < this.selectItems.length - 1) {
          event.preventDefault();
          this.selectItems[index + 1].focus();
        }

        break;

      case keys.ARROW_UP:
        if (index < 0) {
          return; // it's coming from the search box... nothing to do... nowhere to go
        }

        if (index === 0 && this.searchInput) {
          event.preventDefault();
          this.searchInput.focus();
        } else if (index > 0) {
          event.preventDefault();
          this.selectItems[index - 1].focus();
        }

    }
  }

  resolveMultiSelect() {
    const {
      config
    } = this.props;
    return !isNil(config.multiSelect) ? config.multiSelect : defaults.config.multiSelect;
  }

  componentDidMount() {
    if (this.props.query.text.length) this.loadOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) this.loadOptions();
  }

  render() {
    const {
      index,
      query,
      config
    } = this.props;
    const multiSelect = this.resolveMultiSelect();
    const activeTop = this.isActiveField(config.field);
    const activeItem = this.state.options ? this.state.options.all.some(item => this.isActiveField(item.field)) : false;
    const active = activeTop || activeItem;
    const button = <OuiFilterButton iconType="arrowDown" iconSide="right" onClick={this.onButtonClick.bind(this)} hasActiveFilters={active} numActiveFilters={active ? this.state.activeItems.length : undefined} grow>
        {config.name}
      </OuiFilterButton>;
    const searchBox = this.renderSearchBox();
    const content = this.renderContent(config.field, query, config, multiSelect);
    return <OuiPopover id={`${config.type}_${index}`} button={button} isOpen={this.state.popoverOpen} closePopover={this.closePopover.bind(this)} panelPaddingSize="none" anchorPosition="downCenter" panelClassName="ouiFilterGroup__popoverPanel">
        {searchBox}
        {content}
      </OuiPopover>;
  }

  renderSearchBox() {
    const threshold = this.props.config.searchThreshold || defaults.config.searchThreshold;

    if (this.state.options && this.state.options.all.length >= threshold) {
      const disabled = this.state.error != null;
      return <OuiPopoverTitle paddingSize="s">
          <OuiFieldSearch inputRef={ref => this.searchInput = ref} disabled={disabled} incremental={true} onSearch={query => this.filterOptions(query)} onKeyDown={this.onKeyDown.bind(this, -1)} compressed />
        </OuiPopoverTitle>;
    }
  }

  renderContent(field, query, config, multiSelect) {
    if (this.state.error) {
      return this.renderError(this.state.error);
    }

    if (isNil(this.state.options)) {
      return this.renderLoader();
    }

    if (this.state.options.shown.length === 0) {
      return this.renderNoOptions();
    }

    if (this.state.options == null) {
      return;
    }

    const items = [];
    this.state.options.shown.forEach((option, index) => {
      const optionField = option.field || field;

      if (optionField == null) {
        throw new Error('option.field or field should be provided in <FieldValueSelectionFilter/>');
      }

      const clause = multiSelect === 'or' ? query.getOrFieldClause(optionField, option.value) : query.getSimpleFieldClause(optionField, option.value);
      const checked = this.resolveChecked(clause);

      const onClick = () => {
        // clicking a checked item will uncheck it and effective remove the filter (value = undefined)
        this.onOptionClick(optionField, option.value, checked);
      };

      const item = <OuiFilterSelectItem key={index} checked={checked} onClick={onClick} ref={ref => this.selectItems[index] = ref} onKeyDown={this.onKeyDown.bind(this, index)}>
          {option.view ? option.view : this.resolveOptionName(option)}
        </OuiFilterSelectItem>;
      items.push(item);
    });
    return <div className="ouiFilterSelect__items">{items}</div>;
  }

  resolveChecked(clause) {
    if (clause) {
      return Query.isMust(clause) ? 'on' : 'off';
    }
  }

  renderLoader() {
    const message = this.props.config.loadingMessage || defaults.config.loadingMessage;
    return <div className="ouiFilterSelect__note">
        <div className="ouiFilterSelect__noteContent">
          <OuiLoadingChart size="m" />
          <OuiSpacer size="xs" />
          <p>{message}</p>
        </div>
      </div>;
  }

  renderError(message) {
    return <div className="ouiFilterSelect__note">
        <div className="ouiFilterSelect__noteContent">
          <OuiIcon size="m" type="faceSad" color="danger" />
          <OuiSpacer size="xs" />
          <p>{message}</p>
        </div>
      </div>;
  }

  renderNoOptions() {
    const message = this.props.config.noOptionsMessage || defaults.config.noOptionsMessage;
    return <div className="ouiFilterSelect__note">
        <div className="ouiFilterSelect__noteContent">
          <OuiIcon type="minusInCircle" />
          <OuiSpacer size="xs" />
          <p>{message}</p>
        </div>
      </div>;
  }

  isActiveField(field) {
    if (typeof field !== 'string') {
      return false;
    }

    const {
      query
    } = this.props;
    const multiSelect = this.resolveMultiSelect();

    if (multiSelect === 'or') {
      return query.hasOrFieldClause(field);
    }

    return query.hasSimpleFieldClause(field);
  }

}
FieldValueSelectionFilter.propTypes = {
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["field_value_selection"]).isRequired,
    field: PropTypes.string,
    name: PropTypes.string.isRequired,

    /**
       * See #FieldValueOptionType
       */
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired, PropTypes.shape({
        type: PropTypes.oneOf(["date"]).isRequired,
        raw: PropTypes.any.isRequired,
        granularity: PropTypes.oneOfType([PropTypes.shape({
          es: PropTypes.oneOf(["d", "w", "M", "y"]).isRequired,
          js: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
          isSame: PropTypes.func.isRequired,
          start: PropTypes.func.isRequired,
          startOfNext: PropTypes.func.isRequired,
          iso8601: PropTypes.func.isRequired
        }).isRequired, PropTypes.oneOf([undefined])]).isRequired,
        text: PropTypes.string.isRequired,
        resolve: PropTypes.func.isRequired
      }).isRequired]).isRequired,
      name: PropTypes.string,
      view: PropTypes.node
    }).isRequired).isRequired, PropTypes.func.isRequired]).isRequired,
    filterWith: PropTypes.oneOfType([PropTypes.oneOf(["prefix", "includes"]), PropTypes.func.isRequired]),
    cache: PropTypes.number,
    multiSelect: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["and", "or"])]),
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    searchThreshold: PropTypes.number,
    available: PropTypes.func,
    autoClose: PropTypes.bool,
    operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
  }).isRequired,
  query: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

try {
  FieldValueSelectionFilter.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'FieldValueSelectionFilter',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      config: {
        defaultValue: null,
        description: '',
        name: 'config',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: 'FieldValueSelectionFilterConfigType'
        }
      },
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: 'Query'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: '(query: Query) => void'
        }
      }
    },
    extendedInterfaces: ['FieldValueSelectionFilterProps']
  };
} catch (e) {}