/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiOverlayMask } from '../overlay_mask';
import { OuiIcon } from '../icon';
import { useOuiI18n } from '../i18n';
import { OuiFocusTrap } from '../focus_trap';
import { keys } from '../../services';
import { useInnerText } from '../inner_text';
var sizeToClassNameMap = {
  s: 'ouiImage--small',
  m: 'ouiImage--medium',
  l: 'ouiImage--large',
  xl: 'ouiImage--xlarge',
  fullWidth: 'ouiImage--fullWidth',
  original: 'ouiImage--original'
};
var marginToClassNameMap = {
  s: 'ouiImage--marginSmall',
  m: 'ouiImage--marginMedium',
  l: 'ouiImage--marginLarge',
  xl: 'ouiImage--marginXlarge'
};
var floatToClassNameMap = {
  left: 'ouiImage--floatLeft',
  right: 'ouiImage--floatRight'
};
export var SIZES = Object.keys(sizeToClassNameMap);
var fullScreenIconColorMap = {
  light: 'ghost',
  dark: 'default'
};
export var OuiImage = ({
  className,
  url,
  src,
  size = 'original',
  caption,
  hasShadow,
  allowFullScreen,
  fullScreenIconColor = 'light',
  alt,
  style,
  float,
  margin,
  ...rest
}) => {
  const [isFullScreenActive, setIsFullScreenActive] = useState(false);

  const onKeyDown = event => {
    if (event.key === keys.ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      closeFullScreen();
    }
  };

  const closeFullScreen = () => {
    setIsFullScreenActive(false);
  };

  const openFullScreen = () => {
    setIsFullScreenActive(true);
  };

  const customStyle = { ...style
  };
  let classes = classNames('ouiImage', {
    'ouiImage--hasShadow': hasShadow,
    'ouiImage--allowFullScreen': allowFullScreen
  }, margin ? marginToClassNameMap[margin] : null, float ? floatToClassNameMap[float] : null, className);

  if (typeof size === 'string' && SIZES.includes(size)) {
    classes = `${classes} ${sizeToClassNameMap[size]}`;
  } else {
    classes = `${classes}`;
    customStyle.maxWidth = size;
    customStyle.maxHeight = size; // Set width back to auto to ensure aspect ratio is kept

    customStyle.width = 'auto';
  }

  let allowFullScreenButtonClasses = 'ouiImage__button'; // when the button is not custom we need it to go full width
  // to match the parent '.ouiImage' width except when the size is original

  if (typeof size === 'string' && size !== 'original' && SIZES.includes(size)) {
    allowFullScreenButtonClasses = `${allowFullScreenButtonClasses} ouiImage__button--fullWidth`;
  } else {
    allowFullScreenButtonClasses = `${allowFullScreenButtonClasses}`;
  }

  const [optionalCaptionRef, optionalCaptionText] = useInnerText();
  let optionalCaption;

  if (caption) {
    optionalCaption = <figcaption ref={optionalCaptionRef} className="ouiImage__caption">
        {caption}
      </figcaption>;
  }

  const allowFullScreenIcon = <OuiIcon type="fullScreen" color={fullScreenIconColorMap[fullScreenIconColor]} className="ouiImage__icon" />;
  const fullScreenDisplay = <OuiOverlayMask data-test-subj="fullScreenOverlayMask" onClick={closeFullScreen}>
      <OuiFocusTrap clickOutsideDisables={true}>
        <>
          <figure className="ouiImage ouiImage-isFullScreen" aria-label={optionalCaptionText}>
            <button type="button" aria-label={useOuiI18n('ouiImage.closeImage', 'Close full screen {alt} image', {
            alt
          })} className="ouiImage__button" data-test-subj="deactivateFullScreenButton" onClick={closeFullScreen} onKeyDown={onKeyDown}>
              <img src={src || url} alt={alt} className="ouiImage-isFullScreen__img" {...rest} />
            </button>
            {optionalCaption}
          </figure>
          <OuiIcon type="cross" color="default" className="ouiImage-isFullScreenCloseIcon" />
        </>
      </OuiFocusTrap>
    </OuiOverlayMask>;
  const fullscreenLabel = useOuiI18n('ouiImage.openImage', 'Open full screen {alt} image', {
    alt
  });

  if (allowFullScreen) {
    return <figure className={classes} aria-label={optionalCaptionText}>
        <button type="button" aria-label={fullscreenLabel} className={allowFullScreenButtonClasses} data-test-subj="activateFullScreenButton" onClick={openFullScreen}>
          <img style={customStyle} src={src || url} alt={alt} className="ouiImage__img" {...rest} />
          {allowFullScreenIcon}
        </button>
        {isFullScreenActive && fullScreenDisplay}
        {optionalCaption}
      </figure>;
  } else {
    return <figure className={classes} aria-label={optionalCaptionText}>
        <img style={customStyle} src={src || url} className="ouiImage__img" alt={alt} {...rest} />
        {optionalCaption}
      </figure>;
  }
};
OuiImage.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Requires either `src` or `url` but defaults to using `src` if both are provided
       */
  src: PropTypes.string,
  url: PropTypes.string,

  /**
       * Separate from the caption is a title on the alt tag itself.
       * This one is required for accessibility.
       */
  alt: PropTypes.string.isRequired,

  /**
       * Accepts `s` / `m` / `l` / `xl` / `original` / `fullWidth` / or a CSS size of `number` or `string`.
       * `fullWidth` will set the figure to stretch to 100% of its container.
       * `string` and `number` types will max both the width or height, whichever is greater.
       */
  size: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "l", "xl", "fullWidth", "original"]).isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
       * Changes the color of the icon that floats above the image when it can be clicked to fullscreen.
       * The default value of `light` is fine unless your image has a white background, in which case you should change it to `dark`.
       */
  fullScreenIconColor: PropTypes.oneOf(["light", "dark"]),

  /**
       * Provides the visible caption to the image
       */
  caption: PropTypes.node,

  /**
       * When set to `true` (default) will apply a slight shadow to the image
       */
  hasShadow: PropTypes.bool,

  /**
       * When set to `true` will make the image clickable to a larger version
       */
  allowFullScreen: PropTypes.bool,

  /**
       * Float the image to the left or right. Useful in large text blocks.
       */
  float: PropTypes.oneOf(["left", "right"]),

  /**
       * Margin around the image.
       */
  margin: PropTypes.oneOf(["s", "m", "l", "xl"])
};

try {
  OuiImage.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiImage',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      src: {
        defaultValue: null,
        description: 'Requires either `src` or `url` but defaults to using `src` if both are provided',
        name: 'src',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      url: {
        defaultValue: null,
        description: '',
        name: 'url',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      alt: {
        defaultValue: null,
        description: 'Separate from the caption is a title on the alt tag itself.\n' + 'This one is required for accessibility.',
        name: 'alt',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      size: {
        defaultValue: {
          value: 'original'
        },
        description: 'Accepts `s` / `m` / `l` / `xl` / `original` / `fullWidth` / or a CSS size of `number` or `string`.\n' + '`fullWidth` will set the figure to stretch to 100% of its container.\n' + '`string` and `number` types will max both the width or height, whichever is greater.',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      fullScreenIconColor: {
        defaultValue: {
          value: 'light'
        },
        description: 'Changes the color of the icon that floats above the image when it can be clicked to fullscreen.\n' + 'The default value of `light` is fine unless your image has a white background, in which case you should change it to `dark`.',
        name: 'fullScreenIconColor',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'FullScreenIconColor',
          value: [{
            value: '"dark"'
          }, {
            value: '"light"'
          }]
        }
      },
      caption: {
        defaultValue: null,
        description: 'Provides the visible caption to the image',
        name: 'caption',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: 'When set to `true` (default) will apply a slight shadow to the image',
        name: 'hasShadow',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      allowFullScreen: {
        defaultValue: null,
        description: 'When set to `true` will make the image clickable to a larger version',
        name: 'allowFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      float: {
        defaultValue: null,
        description: 'Float the image to the left or right. Useful in large text blocks.',
        name: 'float',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToastSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      margin: {
        defaultValue: null,
        description: 'Margin around the image.',
        name: 'margin',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/image/image.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}