/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys } from '../../../services';
import { isWithinRange } from '../../../services/number';
import { OuiInputPopover } from '../../popover';
import { OuiFormControlLayoutDelimited } from '../form_control_layout';
import { htmlIdGenerator } from '../../../services/accessibility';
import { OuiRangeDraggable } from './range_draggable';
import { OuiRangeHighlight } from './range_highlight';
import { OuiRangeInput } from './range_input';
import { OuiRangeLabel } from './range_label';
import { OuiRangeSlider } from './range_slider';
import { OuiRangeThumb } from './range_thumb';
import { OuiRangeTrack } from './range_track';
import { OuiRangeWrapper } from './range_wrapper';
import { calculateThumbPosition } from './utils';
export class OuiDualRange extends Component {
  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    fullWidth: false,
    compressed: false,
    showLabels: false,
    showInput: false,
    showRange: true,
    showTicks: false,
    levels: []
  };
  state = {
    id: this.props.id || htmlIdGenerator()(),
    hasFocus: false,
    rangeSliderRefAvailable: false,
    isPopoverOpen: false,
    rangeWidth: undefined,
    isVisible: true // used to trigger a rerender if initial element width is 0

  };
  preventPopoverClose = false;
  rangeSliderRef = null;
  handleRangeSliderRefUpdate = ref => {
    this.rangeSliderRef = ref;
    this.setState({
      rangeSliderRefAvailable: !!ref,
      rangeWidth: !!ref ? ref.clientWidth : null
    });
  };
  leftPosition = 0;
  dragAcc = 0;

  get lowerValue() {
    return this.props.value ? this.props.value[0] : this.props.min;
  }

  get upperValue() {
    return this.props.value ? this.props.value[1] : this.props.max;
  }

  get lowerValueIsValid() {
    return isWithinRange(this.props.min, this.upperValue, this.lowerValue);
  }

  get upperValueIsValid() {
    return isWithinRange(this.lowerValue, this.props.max, this.upperValue);
  }

  get isValid() {
    return this.lowerValueIsValid && this.upperValueIsValid;
  }

  componentDidMount() {
    if (this.rangeSliderRef && this.rangeSliderRef.clientWidth === 0) {
      // Safe to call `setState` inside conditional
      // https://reactjs.org/docs/react-component.html#componentdidmount
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isVisible: false
      });
    }
  }

  componentDidUpdate() {
    if (this.rangeSliderRef?.clientWidth && !this.state.isVisible) {
      // Safe to call `setState` inside conditional
      // https://reactjs.org/docs/react-component.html#componentdidupdate
      // eslint-disable-next-line  react/no-did-update-set-state
      this.setState({
        isVisible: true
      });
    }
  }

  _determineInvalidThumbMovement = (newVal, lower, upper, e) => {
    // If the values are invalid, find whether the new value is in the upper
    // or lower half and move the appropriate handle to the new value,
    // while the other handle gets moved to the opposite bound (if invalid)
    const lowerHalf = Math.abs(this.props.max - this.props.min) / 2 + this.props.min;
    const newValIsLow = isWithinRange(this.props.min, lowerHalf, newVal);

    if (newValIsLow) {
      lower = newVal;
      upper = !this.upperValueIsValid ? this.props.max : upper;
    } else {
      lower = !this.lowerValueIsValid ? this.props.min : lower;
      upper = newVal;
    }

    this._handleOnChange(lower, upper, e);
  };
  _determineValidThumbMovement = (newVal, lower, upper, e) => {
    // Lower thumb targeted or right-moving swap has occurred
    if (Math.abs(lower - newVal) < Math.abs(upper - newVal)) {
      lower = newVal;
    } // Upper thumb targeted or left-moving swap has occurred
    else {
      upper = newVal;
    }

    this._handleOnChange(lower, upper, e);
  };
  _determineThumbMovement = (newVal, e) => {
    // Determine thumb movement based on slider interaction
    if (!this.isValid) {
      // Non-standard positioning follows
      this._determineInvalidThumbMovement(newVal, this.lowerValue, this.upperValue, e);
    } else {
      // Standard positioning based on click event proximity to thumb locations
      this._determineValidThumbMovement(newVal, this.lowerValue, this.upperValue, e);
    }
  };
  _handleOnChange = (lower, upper, e) => {
    const isValid = isWithinRange(this.props.min, upper, lower) && isWithinRange(lower, this.props.max, upper);
    this.props.onChange([lower, upper], isValid, e);
  };
  handleSliderChange = e => {
    this._determineThumbMovement(Number(e.currentTarget.value), e);
  };
  _resetToRangeEnds = e => {
    // Arbitrary decision to pass `min` instead of `max`. Result is the same.
    this._determineInvalidThumbMovement(this.props.min, this.lowerValue, this.upperValue, e);
  };
  _isDirectionalKeyPress = event => {
    return [keys.ARROW_UP, keys.ARROW_RIGHT, keys.ARROW_DOWN, keys.ARROW_LEFT].indexOf(event.key) > -1;
  };
  handleInputKeyDown = e => {
    // Relevant only when initial values are both `''` and `showInput` is set
    if (this._isDirectionalKeyPress(e) && !this.isValid) {
      e.preventDefault();

      this._resetToRangeEnds(e);
    }
  };
  handleLowerInputChange = e => {
    this._handleOnChange(e.target.value, this.upperValue, e);
  };
  handleUpperInputChange = e => {
    this._handleOnChange(this.lowerValue, e.target.value, e);
  };
  _handleKeyDown = (value, event) => {
    let newVal = Number(value);
    let stepRemainder = 0;
    const step = this.props.step || 1;

    switch (event.key) {
      case keys.ARROW_UP:
      case keys.ARROW_RIGHT:
        event.preventDefault();
        newVal += step;
        stepRemainder = (newVal - this.props.min) % step;

        if (step !== 1 && stepRemainder > 0) {
          newVal = newVal - stepRemainder;
        }

        break;

      case keys.ARROW_DOWN:
      case keys.ARROW_LEFT:
        event.preventDefault();
        newVal -= step;
        stepRemainder = (newVal - this.props.min) % step;

        if (step !== 1 && stepRemainder > 0) {
          newVal = newVal + (step - stepRemainder);
        }

        break;
    }

    return newVal;
  };
  handleLowerKeyDown = event => {
    let lower = this.lowerValue;

    switch (event.key) {
      case keys.TAB:
        return;

      default:
        if (!this.lowerValueIsValid) {
          // Relevant only when initial value is `''` and `showInput` is not set
          event.preventDefault();

          this._resetToRangeEnds(event);

          return;
        }

        lower = this._handleKeyDown(lower, event);
    }

    if (lower >= this.upperValue || lower < this.props.min) return;

    this._handleOnChange(lower, this.upperValue, event);
  };
  handleUpperKeyDown = event => {
    let upper = this.upperValue;

    switch (event.key) {
      case keys.TAB:
        return;

      default:
        if (!this.upperValueIsValid) {
          // Relevant only when initial value is `''` and `showInput` is not set
          event.preventDefault();

          this._resetToRangeEnds(event);

          return;
        }

        upper = this._handleKeyDown(upper, event);
    }

    if (upper <= this.lowerValue || upper > this.props.max) return;

    this._handleOnChange(this.lowerValue, upper, event);
  };
  handleDraggableKeyDown = event => {
    let lower = this.lowerValue;
    let upper = this.upperValue;

    switch (event.key) {
      case keys.TAB:
        return;

      default:
        lower = this._handleKeyDown(lower, event);
        upper = this._handleKeyDown(upper, event);
    }

    if (lower >= this.upperValue || lower < this.props.min) return;
    if (upper <= this.lowerValue || upper > this.props.max) return;

    this._handleOnChange(lower, upper, event);
  };
  calculateThumbPositionStyle = (value, width) => {
    const trackWidth = this.props.showInput === 'inputWithPopover' && !!width ? width : this.rangeSliderRef.clientWidth;
    const position = calculateThumbPosition(value, this.props.min, this.props.max, trackWidth);
    return {
      left: `${position}%`
    };
  };
  toggleHasFocus = (shouldFocused = !this.state.hasFocus) => {
    this.setState({
      hasFocus: shouldFocused
    });
  };
  onThumbFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.toggleHasFocus(true);
  };
  onThumbBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.toggleHasFocus(false);
  };
  onInputFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.preventPopoverClose = true;
    this.setState({
      isPopoverOpen: true
    });
  };
  onInputBlur = e => setTimeout(() => {
    // Safari does not recognize any focus-related eventing for input[type=range]
    // making it impossible to capture its state using active/focus/relatedTarget
    // Instead, a prevention flag is set on mousedown, with a waiting period here.
    // Mousedown is viable because in the popover case, it is inaccessible via keyboard (intentionally)
    if (this.preventPopoverClose) {
      this.preventPopoverClose = false;
      return;
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.closePopover();
  }, 200);
  closePopover = () => {
    this.preventPopoverClose = false;
    this.setState({
      isPopoverOpen: false
    });
  };
  onResize = width => {
    this.setState({
      rangeWidth: width
    });
  };
  getNearestStep = value => {
    const steps = (this.props.max - this.props.min) / this.props.step;
    const approx = Math.round((value - this.props.min) * steps / (this.props.max - this.props.min)) / steps;
    const bound = Math.min(Math.max(approx, 0), 1);
    const nearest = bound * (this.props.max - this.props.min) + this.props.min;
    return Number(nearest.toPrecision(10)) * 100 / 100;
  };
  handleDrag = (x, isFirstInteraction) => {
    if (isFirstInteraction) {
      this.leftPosition = x;
      this.dragAcc = 0;
    }

    const {
      min,
      max
    } = this.props;
    const lowerValue = Number(this.lowerValue);
    const upperValue = Number(this.upperValue);
    const delta = this.leftPosition - x;
    this.leftPosition = x;
    this.dragAcc = this.dragAcc + delta;
    const percentageOfArea = this.dragAcc / this.rangeSliderRef.clientWidth;
    const percentageOfRange = percentageOfArea * (max - min);
    const newLower = this.getNearestStep(lowerValue - percentageOfRange);
    const newUpper = this.getNearestStep(upperValue - percentageOfRange);
    const noMovement = newLower === lowerValue;
    const isMin = min === lowerValue && min === newLower;
    const isMax = max === upperValue && max === newUpper;
    const isOutOfRange = newLower < min || newUpper > max;
    if (noMovement || isMin || isMax || isOutOfRange) return;

    this._handleOnChange(newLower, newUpper);

    this.dragAcc = 0;
  };

  render() {
    const {
      className,
      compressed,
      disabled,
      fullWidth,
      readOnly,
      id: propsId,
      max,
      min,
      name,
      step,
      showLabels,
      showInput,
      showTicks,
      tickInterval,
      ticks,
      levels,
      onBlur,
      onChange,
      onFocus,
      showRange,
      value,
      style,
      isInvalid,
      append,
      prepend,
      minInputProps,
      maxInputProps,
      isDraggable,
      ...rest
    } = this.props;
    const {
      id
    } = this.state;
    const digitTolerance = Math.max(String(min).length, String(max).length);
    const showInputOnly = showInput === 'inputWithPopover';
    const canShowDropdown = showInputOnly && !readOnly && !disabled;
    const minInput = !!showInput ? <OuiRangeInput // Overridable props
    aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} {...minInputProps} // Non-overridable props
    digitTolerance={digitTolerance} side="min" min={min} max={Number(this.upperValue)} step={step} value={this.lowerValue} disabled={disabled} compressed={compressed} onChange={this.handleLowerInputChange} onKeyDown={this.handleInputKeyDown} name={`${name}-minValue`} onFocus={canShowDropdown ? this.onInputFocus : onFocus} onBlur={canShowDropdown ? this.onInputBlur : onBlur} readOnly={readOnly} autoSize={!showInputOnly} fullWidth={!!showInputOnly && fullWidth} isInvalid={isInvalid} controlOnly={showInputOnly} onMouseDown={showInputOnly ? () => this.preventPopoverClose = true : undefined} /> : undefined;
    const maxInput = !!showInput ? <OuiRangeInput // Overridable props
    aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} {...maxInputProps} // Non-overridable props
    digitTolerance={digitTolerance} side="max" min={Number(this.lowerValue)} max={max} step={step} value={this.upperValue} disabled={disabled} compressed={compressed} onChange={this.handleUpperInputChange} onKeyDown={this.handleInputKeyDown} name={`${name}-maxValue`} onFocus={canShowDropdown ? this.onInputFocus : onFocus} onBlur={canShowDropdown ? this.onInputBlur : onBlur} readOnly={readOnly} autoSize={!showInputOnly} fullWidth={!!showInputOnly && fullWidth} controlOnly={showInputOnly} isInvalid={isInvalid} onMouseDown={showInputOnly ? () => this.preventPopoverClose = true : undefined} /> : undefined;
    const classes = classNames('ouiDualRange', className);
    const leftThumbPosition = this.state.rangeSliderRefAvailable ? this.calculateThumbPositionStyle(Number(this.lowerValue) || min, this.state.rangeWidth) : {
      left: '0'
    };
    const rightThumbPosition = this.state.rangeSliderRefAvailable ? this.calculateThumbPositionStyle(Number(this.upperValue) || max, this.state.rangeWidth) : {
      left: '0'
    };
    const theRange = <OuiRangeWrapper className={classes} fullWidth={fullWidth} compressed={compressed}>
        {showInput && !showInputOnly && <>
            {minInput}
            <div className={showTicks || ticks ? 'ouiRange__slimHorizontalSpacer' : 'ouiRange__horizontalSpacer'} />
          </>}
        {showLabels && <OuiRangeLabel side="min" disabled={disabled}>
            {min}
          </OuiRangeLabel>}
        <OuiRangeTrack compressed={compressed} disabled={disabled} max={max} min={min} step={step} showTicks={showTicks} tickInterval={tickInterval} ticks={ticks} levels={levels} onChange={this.handleSliderChange} value={value} aria-hidden={showInput === true}>
          <OuiRangeSlider className="ouiDualRange__slider" ref={this.handleRangeSliderRefUpdate} id={id} name={name} min={min} max={max} step={step} disabled={disabled} compressed={compressed} onChange={this.handleSliderChange} style={style} showTicks={showTicks} hasFocus={this.state.hasFocus} aria-hidden={true} tabIndex={-1} showRange={showRange} onFocus={onFocus} onBlur={onBlur} {...rest} />

          {showRange && this.isValid && <OuiRangeHighlight compressed={compressed} hasFocus={this.state.hasFocus} showTicks={showTicks} min={Number(min)} max={Number(max)} lowerValue={Number(this.lowerValue)} upperValue={Number(this.upperValue)} />}

          {this.state.rangeSliderRefAvailable && <React.Fragment>
              {isDraggable && this.isValid && <OuiRangeDraggable min={min} max={max} value={[Number(this.lowerValue), Number(this.upperValue)]} disabled={disabled} lowerPosition={leftThumbPosition.left} upperPosition={rightThumbPosition.left} showTicks={showTicks} compressed={compressed} onChange={this.handleDrag} onFocus={this.onThumbFocus} onBlur={this.onThumbBlur} onKeyDown={this.handleDraggableKeyDown} aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} />}

              <OuiRangeThumb min={min} max={Number(this.upperValue)} value={this.lowerValue} disabled={disabled} showTicks={showTicks} showInput={!!showInput} onKeyDown={this.handleLowerKeyDown} onFocus={this.onThumbFocus} onBlur={this.onThumbBlur} style={leftThumbPosition} aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} />

              <OuiRangeThumb min={Number(this.lowerValue)} max={max} value={this.upperValue} disabled={disabled} showTicks={showTicks} showInput={!!showInput} onKeyDown={this.handleUpperKeyDown} onFocus={this.onThumbFocus} onBlur={this.onThumbBlur} style={rightThumbPosition} aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} />
            </React.Fragment>}
        </OuiRangeTrack>
        {showLabels && <OuiRangeLabel disabled={disabled}>{max}</OuiRangeLabel>}
        {showInput && !showInputOnly && <>
            <div className={showTicks || ticks ? 'ouiRange__slimHorizontalSpacer' : 'ouiRange__horizontalSpacer'} />
            {maxInput}
          </>}
      </OuiRangeWrapper>;
    const thePopover = showInputOnly ? <OuiInputPopover className="ouiRange__popover" input={<OuiFormControlLayoutDelimited startControl={minInput} endControl={maxInput} isDisabled={disabled} fullWidth={fullWidth} compressed={compressed} readOnly={readOnly} append={append} prepend={prepend} />} fullWidth={fullWidth} isOpen={this.state.isPopoverOpen} closePopover={this.closePopover} disableFocusTrap={true} onPanelResize={this.onResize}>
        {theRange}
      </OuiInputPopover> : undefined;
    return thePopover || theRange;
  }

}
OuiDualRange.propTypes = {
  value: PropTypes.any.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,

  /**
     * Create colored indicators for certain intervals
     */
  levels: PropTypes.arrayOf(PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    color: PropTypes.oneOf(["primary", "success", "warning", "danger"]).isRequired
  }).isRequired),

  /**
     * Shows static min/max labels on the sides of the range slider
     */
  showLabels: PropTypes.bool,

  /**
     * Pass `true` to displays an extra input control for direct manipulation.
     * Pass `'inputWithPopover'` to only show the input but show the range in a dropdown.
     */
  showInput: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["inputWithPopover"])]),

  /**
     * Modifies the number of tick marks and at what interval
     */
  tickInterval: PropTypes.number,

  /**
     * Specified ticks at specified values
     */
  ticks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired
  }).isRequired),

  /**
     * Creates an input group with element(s) coming before input.  Will only show if `showInput = inputWithPopover`.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
     * Creates an input group with element(s) coming after input. Will only show if `showInput = inputWithPopover`.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
     *  Intended to be uses with aria attributes. Some attributes may be overwritten.
     */
  minInputProps: PropTypes.any,

  /**
     *  Intended to be uses with aria attributes. Some attributes may be overwritten.
     */
  maxInputProps: PropTypes.any,

  /**
     *  Creates a draggble highlighted range area
     */
  isDraggable: PropTypes.bool
};

try {
  OuiDualRange.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiDualRange',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: true,
        type: {
          name: '[ReactText, ReactText]'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: '(event: any) => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: '(event: any) => void'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: true,
        type: {
          name: '(values: [ReactText, ReactText], isValid: boolean, event?: any) => void'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      levels: {
        defaultValue: {
          value: '[]'
        },
        description: 'Create colored indicators for certain intervals',
        name: 'levels',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeLevel[]'
        }
      },
      showLabels: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows static min/max labels on the sides of the range slider',
        name: 'showLabels',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showInput: {
        defaultValue: {
          value: 'false'
        },
        description: 'Pass `true` to displays an extra input control for direct manipulation.\n' + "Pass `'inputWithPopover'` to only show the input but show the range in a dropdown.",
        name: 'showInput',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean | "inputWithPopover"'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeTick[]'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.  Will only show if `showInput = inputWithPopover`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input. Will only show if `showInput = inputWithPopover`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      minInputProps: {
        defaultValue: null,
        description: 'Intended to be uses with aria attributes. Some attributes may be overwritten.',
        name: 'minInputProps',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'Partial<OuiRangeInputProps>'
        }
      },
      maxInputProps: {
        defaultValue: null,
        description: 'Intended to be uses with aria attributes. Some attributes may be overwritten.',
        name: 'maxInputProps',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'Partial<OuiRangeInputProps>'
        }
      },
      isDraggable: {
        defaultValue: null,
        description: 'Creates a draggble highlighted range area',
        name: 'isDraggable',
        parent: {
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/dual_range.tsx',
          name: 'OuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasFocus: {
        defaultValue: null,
        description: '',
        name: 'hasFocus',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'showRange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'showTicks',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/range/range_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiDualRangeProps', 'HTMLAttributes', 'InputHTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}