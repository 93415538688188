/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

import React from 'react';

import {
  OuiButtonEmpty,
  OuiFlexGroup,
  OuiFlexItem,
} from '../../../../src/components';

export default () => (
  <OuiFlexGroup gutterSize="s" alignItems="center">
    <OuiFlexItem grow={false}>
      <OuiButtonEmpty flush="left">Flush left</OuiButtonEmpty>
    </OuiFlexItem>

    <OuiFlexItem grow={false}>
      <OuiButtonEmpty flush="right">Flush right</OuiButtonEmpty>
    </OuiFlexItem>

    <OuiFlexItem grow={false}>
      <OuiButtonEmpty flush="both">Flush both</OuiButtonEmpty>
    </OuiFlexItem>
  </OuiFlexGroup>
);
