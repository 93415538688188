/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiCallOut } from '../call_out';
import { OuiI18n } from '../i18n';
export var OuiForm = ({
  children,
  className,
  isInvalid,
  error,
  component = 'div',
  invalidCallout = 'above',
  ...rest
}) => {
  const handleFocus = useCallback(node => {
    node?.focus();
  }, []);
  const classes = classNames('ouiForm', className);
  let optionalErrors = null;

  if (error) {
    const errorTexts = Array.isArray(error) ? error : [error];
    optionalErrors = <ul>
        {errorTexts.map((error, index) => <li className="ouiForm__error" key={index}>
            {error}
          </li>)}
      </ul>;
  }

  let optionalErrorAlert;

  if (isInvalid && invalidCallout === 'above') {
    optionalErrorAlert = <OuiI18n token="ouiForm.addressFormErrors" default="Please address the highlighted errors.">
        {addressFormErrors => <OuiCallOut tabIndex={-1} ref={handleFocus} className="ouiForm__errors" title={addressFormErrors} color="danger" role="alert" aria-live="assertive">
            {optionalErrors}
          </OuiCallOut>}
      </OuiI18n>;
  }

  const Element = component;
  return <Element className={classes} {...rest}>
      {optionalErrorAlert}
      {children}
    </Element>;
};
OuiForm.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Which HTML element to render `div` or `form`
       */
  component: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOf(["form"]).isRequired, PropTypes.oneOf(["div"])]), PropTypes.oneOf(["form", "div"])]),
  isInvalid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),

  /**
       * Where to display the callout with the list of errors
       */
  invalidCallout: PropTypes.oneOf(["above", "none"])
};

try {
  OuiForm.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiForm',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      component: {
        defaultValue: {
          value: 'div'
        },
        description: 'Which HTML element to render `div` or `form`',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "form"',
          value: [{
            value: '"div"'
          }, {
            value: '"form"'
          }]
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      error: {
        defaultValue: null,
        description: '',
        name: 'error',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean | {} | ReactElement<any, string | ((props: any) => ReactElement<any, string | ... | (new (props: any) => Component<any, any, any>)>) | (new (props: any) => Component<...>)> | ReactNodeArray | ReactPortal | ReactNode[]'
        }
      },
      invalidCallout: {
        defaultValue: {
          value: 'above'
        },
        description: 'Where to display the callout with the list of errors',
        name: 'invalidCallout',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "above"',
          value: [{
            value: '"none"'
          }, {
            value: '"above"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'FormHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}