/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiContextMenuPanel } from './context_menu_panel';
import { OuiContextMenuItem } from './context_menu_item';
import { OuiHorizontalRule } from '../horizontal_rule';
var sizeToClassNameMap = {
  s: 'ouiContextMenu--small',
  m: null
};
export var SIZES = keysOf(sizeToClassNameMap);

var isItemSeparator = function isItemSeparator(item) {
  return item.isSeparator === true;
};

function mapIdsToPanels(panels) {
  var map = {};
  panels.forEach(function (panel) {
    map[panel.id] = panel;
  });
  return map;
}

function mapIdsToPreviousPanels(panels) {
  var idToPreviousPanelIdMap = {};
  panels.forEach(function (panel) {
    if (Array.isArray(panel.items)) {
      panel.items.forEach(function (item) {
        if (isItemSeparator(item)) return;
        var isCloseable = item.panel !== undefined;

        if (isCloseable) {
          idToPreviousPanelIdMap[item.panel] = panel.id;
        }
      });
    }
  });
  return idToPreviousPanelIdMap;
}

function mapPanelItemsToPanels(panels) {
  var idAndItemIndexToPanelIdMap = {};
  panels.forEach(function (panel) {
    idAndItemIndexToPanelIdMap[panel.id] = {};

    if (panel.items) {
      panel.items.forEach(function (item, index) {
        if (isItemSeparator(item)) return;

        if (item.panel) {
          idAndItemIndexToPanelIdMap[panel.id][index] = item.panel;
        }
      });
    }
  });
  return idAndItemIndexToPanelIdMap;
}

export class OuiContextMenu extends Component {
  static defaultProps = {
    panels: [],
    size: 'm'
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      panels
    } = nextProps;

    if (panels && prevState.prevProps.panels !== panels) {
      return {
        prevProps: {
          panels
        },
        idToPanelMap: mapIdsToPanels(panels),
        idToPreviousPanelIdMap: mapIdsToPreviousPanels(panels),
        idAndItemIndexToPanelIdMap: mapPanelItemsToPanels(panels)
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      prevProps: {},
      idToPanelMap: {},
      idToPreviousPanelIdMap: {},
      idAndItemIndexToPanelIdMap: {},
      idToRenderedItemsMap: this.mapIdsToRenderedItems(this.props.panels),
      height: undefined,
      outgoingPanelId: undefined,
      incomingPanelId: props.initialPanelId,
      transitionDirection: undefined,
      isOutgoingPanelVisible: false,
      focusedItemIndex: undefined,
      isUsingKeyboardToNavigate: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.panels !== this.props.panels) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        idToRenderedItemsMap: this.mapIdsToRenderedItems(this.props.panels)
      });
    }
  }

  hasPreviousPanel = panelId => {
    const previousPanelId = this.state.idToPreviousPanelIdMap[panelId];
    return typeof previousPanelId !== 'undefined';
  };

  showPanel(panelId, direction) {
    this.setState({
      outgoingPanelId: this.state.incomingPanelId,
      incomingPanelId: panelId,
      transitionDirection: direction,
      isOutgoingPanelVisible: true
    });
  }

  showNextPanel = itemIndex => {
    if (itemIndex == null) {
      return;
    }

    const nextPanelId = this.state.idAndItemIndexToPanelIdMap[this.state.incomingPanelId][itemIndex];

    if (nextPanelId) {
      if (this.state.isUsingKeyboardToNavigate) {
        this.setState(({
          idToPanelMap
        }) => ({
          focusedItemIndex: idToPanelMap[nextPanelId].initialFocusedItemIndex ?? 0
        }));
      }

      this.showPanel(nextPanelId, 'next');
    }
  };
  showPreviousPanel = () => {
    // If there's a previous panel, then we can close the current panel to go back to it.
    if (this.hasPreviousPanel(this.state.incomingPanelId)) {
      const previousPanelId = this.state.idToPreviousPanelIdMap[this.state.incomingPanelId]; // Set focus on the item which shows the panel we're leaving.

      const previousPanel = this.state.idToPanelMap[previousPanelId];
      const focusedItemIndex = previousPanel.items.findIndex(item => !isItemSeparator(item) && item.panel === this.state.incomingPanelId);

      if (focusedItemIndex !== -1) {
        this.setState({
          focusedItemIndex
        });
      }

      this.showPanel(previousPanelId, 'previous');
    }
  };
  onIncomingPanelHeightChange = height => {
    this.setState(({
      height: prevHeight
    }) => {
      if (height === prevHeight) {
        return null;
      }

      return {
        height
      };
    });
  };
  onOutGoingPanelTransitionComplete = () => {
    this.setState({
      isOutgoingPanelVisible: false
    });
  };
  onUseKeyboardToNavigate = () => {
    if (!this.state.isUsingKeyboardToNavigate) {
      this.setState({
        isUsingKeyboardToNavigate: true
      });
    }
  };
  mapIdsToRenderedItems = (panels = []) => {
    const idToRenderedItemsMap = {}; // Pre-rendering the items lets us check reference equality inside of OuiContextMenuPanel.

    panels.forEach(panel => {
      idToRenderedItemsMap[panel.id] = this.renderItems(panel.items);
    });
    return idToRenderedItemsMap;
  };

  renderItems(items = []) {
    return items.map((item, index) => {
      if (isItemSeparator(item)) {
        const {
          isSeparator: omit,
          key = index,
          ...rest
        } = item;
        return <OuiHorizontalRule key={key} margin="none" {...rest} />;
      }

      const {
        panel,
        name,
        key,
        icon,
        onClick,
        toolTipTitle,
        toolTipContent,
        ...rest
      } = item;
      const onClickHandler = panel ? event => {
        if (onClick && event) {
          event.persist();
        } // This component is commonly wrapped in a OuiOutsideClickDetector, which means we'll
        // need to wait for that logic to complete before re-rendering the DOM via showPanel.


        window.requestAnimationFrame(() => {
          if (onClick) {
            onClick(event);
          }

          this.showNextPanel(index);
        });
      } : onClick;
      return <OuiContextMenuItem key={key || (typeof name === 'string' ? name : undefined) || index} icon={icon} onClick={onClickHandler} hasPanel={Boolean(panel)} toolTipTitle={toolTipTitle} toolTipContent={toolTipContent} {...rest}>
          {name}
        </OuiContextMenuItem>;
    });
  }

  renderPanel(panelId, transitionType) {
    const panel = this.state.idToPanelMap[panelId];

    if (!panel) {
      return;
    } // As above, we need to wait for OuiOutsideClickDetector to complete its logic before
    // re-rendering via showPanel.


    let onClose;

    if (this.hasPreviousPanel(panelId)) {
      onClose = () => window.requestAnimationFrame(this.showPreviousPanel);
    }

    return <OuiContextMenuPanel key={panelId} size={this.props.size} className="ouiContextMenu__panel" onHeightChange={transitionType === 'in' ? this.onIncomingPanelHeightChange : undefined} onTransitionComplete={transitionType === 'out' ? this.onOutGoingPanelTransitionComplete : undefined} title={panel.title} onClose={onClose} transitionType={this.state.isOutgoingPanelVisible ? transitionType : undefined} transitionDirection={this.state.isOutgoingPanelVisible ? this.state.transitionDirection : undefined} hasFocus={transitionType === 'in'} items={this.state.idToRenderedItemsMap[panelId]} initialFocusedItemIndex={this.state.isUsingKeyboardToNavigate ? this.state.focusedItemIndex : panel.initialFocusedItemIndex} onUseKeyboardToNavigate={this.onUseKeyboardToNavigate} showNextPanel={this.showNextPanel} showPreviousPanel={this.showPreviousPanel}>
        {panel.content}
      </OuiContextMenuPanel>;
  }

  render() {
    const {
      panels,
      className,
      initialPanelId,
      size,
      ...rest
    } = this.props;
    const incomingPanel = this.renderPanel(this.state.incomingPanelId, 'in');
    let outgoingPanel;

    if (this.state.isOutgoingPanelVisible) {
      outgoingPanel = this.renderPanel(this.state.outgoingPanelId, 'out');
    }

    const width = this.state.idToPanelMap[this.state.incomingPanelId] && this.state.idToPanelMap[this.state.incomingPanelId].width ? this.state.idToPanelMap[this.state.incomingPanelId].width : undefined;
    const classes = classNames('ouiContextMenu', size && sizeToClassNameMap[size], className);
    return <div className={classes} style={{
      height: this.state.height,
      width: width
    }} {...rest}>
        {outgoingPanel}
        {incomingPanel}
      </div>;
  }

}
OuiContextMenu.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  panels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    title: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.node,
      key: PropTypes.string,
      panel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      isSeparator: PropTypes.oneOf([true]),

      /**
         * Defines the width of the HR.
         */
      size: PropTypes.oneOf(["full", "half", "quarter"]),
      margin: PropTypes.oneOf(["none", "xs", "s", "m", "l", "xl", "xxl"]),
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string
    }).isRequired),
    content: PropTypes.node,
    width: PropTypes.number,
    initialFocusedItemIndex: PropTypes.number,

    /**
       * Alters the size of the items and the title
       */
    size: PropTypes.oneOf(["s", "m"])
  }).isRequired),
  initialPanelId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),

  /**
       * Alters the size of the items and the title
       */
  size: PropTypes.oneOf(["s", "m"])
};

try {
  OuiContextMenu.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiContextMenu',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panels: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'panels',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiContextMenuPanelDescriptor[]'
        }
      },
      initialPanelId: {
        defaultValue: null,
        description: '',
        name: 'initialPanelId',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: 'Alters the size of the items and the title',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'Size',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}