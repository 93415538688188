/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
var tableLayoutToClassMap = {
  fixed: null,
  auto: 'ouiTable--auto'
};
export var OuiTable = ({
  children,
  className,
  compressed,
  tableLayout = 'fixed',
  responsive = true,
  ...rest
}) => {
  const classes = classNames('ouiTable', className, {
    'ouiTable--compressed': compressed,
    'ouiTable--responsive': responsive
  }, tableLayoutToClassMap[tableLayout]);
  return <table tabIndex={-1} className={classes} {...rest}>
      {children}
    </table>;
};
OuiTable.propTypes = {
  compressed: PropTypes.bool,
  responsive: PropTypes.bool,

  /**
     * Sets the table-layout CSS property
     */
  tableLayout: PropTypes.oneOf(["fixed", "auto"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiTable.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTable',
    methods: [],
    props: {
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/table/table.tsx',
          name: 'OuiTableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/table.tsx',
          name: 'OuiTableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'responsive',
        parent: {
          fileName: 'docs/src/components/table/table.tsx',
          name: 'OuiTableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/table.tsx',
          name: 'OuiTableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      tableLayout: {
        defaultValue: {
          value: 'fixed'
        },
        description: 'Sets the table-layout CSS property',
        name: 'tableLayout',
        parent: {
          fileName: 'docs/src/components/table/table.tsx',
          name: 'OuiTableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/table.tsx',
          name: 'OuiTableProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"auto" | "fixed"',
          value: [{
            value: '"auto"'
          }, {
            value: '"fixed"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiTableProps', 'CommonProps', 'TableHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}