/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiNotificationBadge } from '../badge';
import { OuiLoadingSpinner } from '../loading';
import { OuiInnerText } from '../inner_text';
export var OuiFacetButton = ({
  children,
  className,
  icon,
  isDisabled = false,
  isLoading = false,
  isSelected = false,
  quantity,
  buttonRef,
  ...rest
}) => {
  // If in the loading state, force disabled to true
  isDisabled = isLoading ? true : isDisabled;
  const classes = classNames('ouiFacetButton', {
    'ouiFacetButton--isSelected': isSelected,
    'ouiFacetButton--unSelected': !isSelected
  }, className); // Add quantity number if provided or loading indicator

  let buttonQuantity;

  if (isLoading) {
    buttonQuantity = <OuiLoadingSpinner className="ouiFacetButton__spinner" size="m" />;
  } else if (typeof quantity === 'number') {
    buttonQuantity = <OuiNotificationBadge className="ouiFacetButton__quantity" size="m" color={!isSelected || isDisabled ? 'subdued' : 'accent'}>
        {quantity}
      </OuiNotificationBadge>;
  } // Add an icon to the button if one exists.


  let buttonIcon;

  if (React.isValidElement(icon)) {
    buttonIcon = React.cloneElement(icon, {
      className: classNames(icon.props.className, 'ouiFacetButton__icon')
    });
  }

  return <OuiInnerText>
      {(ref, innerText) => <button className={classes} disabled={isDisabled} type="button" ref={buttonRef} title={rest['aria-label'] || innerText} {...rest}>
          <span className="ouiFacetButton__content">
            {buttonIcon}
            <span className="ouiFacetButton__text" data-text={innerText} ref={ref}>
              {children}
            </span>
            {buttonQuantity}
          </span>
        </button>}
    </OuiInnerText>;
};
OuiFacetButton.propTypes = {
  buttonRef: PropTypes.any,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,

  /**
     * Any node, but preferably a `OuiIcon` or `OuiAvatar`
     */
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,

  /**
     * Adds/swaps for loading spinner & disables
     */
  isLoading: PropTypes.bool,

  /**
     * Changes visual of button to indicate it's currently selected
     */
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,

  /**
     * Adds a notification indicator for displaying the quantity provided
     */
  quantity: PropTypes.number,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiFacetButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFacetButton',
    methods: [],
    props: {
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLButtonElement) => void'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      icon: {
        defaultValue: null,
        description: 'Any node, but preferably a `OuiIcon` or `OuiAvatar`',
        name: 'icon',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      isDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Adds/swaps for loading spinner & disables',
        name: 'isLoading',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSelected: {
        defaultValue: {
          value: 'false'
        },
        description: "Changes visual of button to indicate it's currently selected",
        name: 'isSelected',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      quantity: {
        defaultValue: null,
        description: 'Adds a notification indicator for displaying the quantity provided',
        name: 'quantity',
        parent: {
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/facet/facet_button.tsx',
          name: 'OuiFacetButtonProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiFacetButtonProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}