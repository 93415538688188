/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import classNames from 'classnames';
import PropTypes from "prop-types";
import React from 'react';
import { OuiStepNumber } from './step_number';
import { useI18nCompleteStep, useI18nDisabledStep, useI18nIncompleteStep, useI18nStep, useI18nWarningStep } from './step_strings';
export var OuiStepHorizontal = ({
  className,
  step = 1,
  title,
  isSelected,
  isComplete,
  onClick,
  disabled,
  status,
  ...rest
}) => {
  const buttonTitle = useI18nStep({
    number: step,
    title
  });
  const completeTitle = useI18nCompleteStep({
    number: step,
    title
  });
  const disabledTitle = useI18nDisabledStep({
    number: step,
    title
  });
  const incompleteTitle = useI18nIncompleteStep({
    number: step,
    title
  });
  const warningTitle = useI18nWarningStep({
    number: step,
    title
  });
  const classes = classNames('ouiStepHorizontal', className, {
    'ouiStepHorizontal-isSelected': isSelected,
    'ouiStepHorizontal-isComplete': isComplete,
    'ouiStepHorizontal-isIncomplete': !isSelected && !isComplete,
    'ouiStepHorizontal-isDisabled': disabled
  });
  if (disabled) status = 'disabled';else if (isComplete) status = 'complete';else if (isSelected) status = status;else if (!status) status = 'incomplete';
  let stepTitle = buttonTitle;
  if (status === 'disabled') stepTitle = disabledTitle;
  if (status === 'complete') stepTitle = completeTitle;
  if (status === 'incomplete') stepTitle = incompleteTitle;
  if (status === 'warning') stepTitle = warningTitle;

  const onStepClick = event => {
    if (!disabled) onClick(event);
  };

  return <button className={classes} title={stepTitle} onClick={onStepClick} disabled={disabled} {...rest}>
      <OuiStepNumber className="ouiStepHorizontal__number" status={status} number={step} />

      <span className="ouiStepHorizontal__title">{title}</span>
    </button>;
};
OuiStepHorizontal.propTypes = {
  /**
     * Adds to the line before the indicator for showing current progress
     */
  isSelected: PropTypes.bool,

  /**
     * Adds to the line after the indicator for showing current progress
     */
  isComplete: PropTypes.bool,
  onClick: PropTypes.func.isRequired,

  /**
     * Makes the whole step button disabled.
     */
  disabled: PropTypes.bool,

  /**
     * The number of the step in the list of steps
     */
  step: PropTypes.number,
  title: PropTypes.string,

  /**
     * Visual representation of the step number indicator.
     * May replace the number provided in props.step with alternate styling.
     * The `isSelected`, `isComplete`, and `disabled` props will override these.
     */
  status: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiStepHorizontal.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiStepHorizontal',
    methods: [],
    props: {
      isSelected: {
        defaultValue: null,
        description: 'Adds to the line before the indicator for showing current progress',
        name: 'isSelected',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isComplete: {
        defaultValue: null,
        description: 'Adds to the line after the indicator for showing current progress',
        name: 'isComplete',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: true,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      disabled: {
        defaultValue: null,
        description: 'Makes the whole step button disabled.',
        name: 'disabled',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number of the step in the list of steps',
        name: 'step',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      status: {
        defaultValue: null,
        description: 'Visual representation of the step number indicator.\n' + 'May replace the number provided in props.step with alternate styling.\n' + 'The `isSelected`, `isComplete`, and `disabled` props will override these.',
        name: 'status',
        parent: {
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step_horizontal.tsx',
          name: 'OuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"danger" | "warning" | "disabled" | "loading" | "complete" | "incomplete"',
          value: [{
            value: '"danger"'
          }, {
            value: '"warning"'
          }, {
            value: '"disabled"'
          }, {
            value: '"loading"'
          }, {
            value: '"complete"'
          }, {
            value: '"incomplete"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiStepHorizontalProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}