/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

import ActionTypes from './action_types';

export const toggleLocale = (locale) => ({
  type: ActionTypes.TOGGLE_LOCALE,
  data: {
    locale,
  },
});
