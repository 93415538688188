/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { OuiFilterButton } from '../../filter_group';
import { Query } from '../query';
export class FieldValueToggleGroupFilter extends Component {
  resolveDisplay(config, query, item) {
    const clause = query.getSimpleFieldClause(config.field, item.value);

    if (clause) {
      if (Query.isMust(clause)) {
        return {
          active: true,
          name: item.name
        };
      }

      return {
        active: true,
        name: item.negatedName ? item.negatedName : `Not ${item.name}`
      };
    }

    return {
      active: false,
      name: item.name
    };
  }

  valueChanged(item, active) {
    const {
      field
    } = this.props.config;
    const {
      value,
      operator
    } = item;
    const query = active ? this.props.query.removeSimpleFieldClauses(field) : this.props.query.removeSimpleFieldClauses(field).addSimpleFieldValue(field, value, true, operator);
    this.props.onChange(query);
  }

  render() {
    const {
      config,
      query
    } = this.props;
    return config.items.map((item, index) => {
      const {
        active,
        name
      } = this.resolveDisplay(config, query, item);

      const onClick = () => {
        this.valueChanged(item, active);
      };

      const key = `field_value_toggle_filter_item_${index}`;
      const isLastItem = index === config.items.length - 1;
      return <OuiFilterButton key={key} onClick={onClick} hasActiveFilters={active} noDivider={!isLastItem} aria-pressed={!!active} withNext={!isLastItem}>
          {name}
        </OuiFilterButton>;
    });
  }

}
FieldValueToggleGroupFilter.propTypes = {
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["field_value_toggle_group"]).isRequired,
    field: PropTypes.string.isRequired,

    /**
       * See #FieldValueToggleGroupFilterItemType
       */
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired]).isRequired,
      name: PropTypes.string.isRequired,
      negatedName: PropTypes.string,
      operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
    }).isRequired).isRequired,
    available: PropTypes.func
  }).isRequired,
  query: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

try {
  FieldValueToggleGroupFilter.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'FieldValueToggleGroupFilter',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      config: {
        defaultValue: null,
        description: '',
        name: 'config',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: 'FieldValueToggleGroupFilterConfigType'
        }
      },
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: 'Query'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: '(value: Query) => void'
        }
      }
    },
    extendedInterfaces: ['FieldValueToggleGroupFilterProps']
  };
} catch (e) {}