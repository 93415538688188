/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { keys } from '../../services';
import { OuiButtonIcon } from '../button';
import { OuiFocusTrap } from '../focus_trap';
import { OuiOverlayMask } from '../overlay_mask';
import { OuiI18n } from '../i18n';
export var OuiModal = ({
  className,
  children,
  initialFocus,
  onClose,
  maxWidth = true,
  style,
  ...rest
}) => {
  const onKeyDown = event => {
    if (event.key === keys.ESCAPE) {
      event.preventDefault();
      event.stopPropagation();
      onClose(event);
    }
  };

  let newStyle;
  let widthClassName;

  if (maxWidth === true) {
    widthClassName = 'ouiModal--maxWidth-default';
  } else if (maxWidth !== false) {
    const value = typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;
    newStyle = { ...style,
      maxWidth: value
    };
  }

  const classes = classnames('ouiModal', widthClassName, className);
  return <OuiOverlayMask>
      <OuiFocusTrap initialFocus={initialFocus}>
        {// Create a child div instead of applying these props directly to FocusTrap, or else
        // fallbackFocus won't work.
      }
        <div className={classes} onKeyDown={onKeyDown} tabIndex={0} style={newStyle || style} {...rest}>
          <OuiI18n token="ouiModal.closeModal" default="Closes this modal window">
            {closeModal => <OuiButtonIcon iconType="cross" onClick={onClose} className="ouiModal__closeIcon" color="text" aria-label={closeModal} />}
          </OuiI18n>
          <div className="ouiModal__flex">{children}</div>
        </div>
      </OuiFocusTrap>
    </OuiOverlayMask>;
};
OuiModal.propTypes = {
  className: PropTypes.string,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,

  /**
     * Sets the max-width of the modal.
     * Set to `true` to use the default (`ouiBreakpoints 'm'`),
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  maxWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
     * Specifies what element should initially have focus.
     * Can be a DOM node, or a selector string (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.
     */
  initialFocus: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.func.isRequired, PropTypes.string.isRequired])
};

try {
  OuiModal.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiModal',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: {
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        }],
        required: true,
        type: {
          name: '(event?: any) => void'
        }
      },
      maxWidth: {
        defaultValue: {
          value: 'true'
        },
        description: 'Sets the max-width of the modal.\n' + "Set to `true` to use the default (`ouiBreakpoints 'm'`),\n" + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'maxWidth',
        parent: {
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus.\n' + 'Can be a DOM node, or a selector string (which will be passed to document.querySelector() to find the DOM node), or a function that returns a DOM node.',
        name: 'initialFocus',
        parent: {
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/modal/modal.tsx',
          name: 'OuiModalProps'
        }],
        required: false,
        type: {
          name: 'string | HTMLElement | (() => HTMLElement)'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiModalProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}