/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { timeUnits, timeUnitsPlural } from '../time_units';
import { OuiI18n } from '../../../i18n';
import { OuiFlexGroup, OuiFlexItem } from '../../../flex';
import { OuiTitle } from '../../../title';
import { OuiSpacer } from '../../../spacer';
import { OuiSelect, OuiFieldNumber } from '../../../form';
import { OuiButton } from '../../../button';
import { htmlIdGenerator } from '../../../../services';
import { OuiScreenReaderOnly } from '../../../accessibility';
import { keysOf } from '../../../common';
var refreshUnitsOptions = keysOf(timeUnits).filter(function (timeUnit) {
  return timeUnit === 'h' || timeUnit === 'm' || timeUnit === 's';
}).map(function (timeUnit) {
  return {
    value: timeUnit,
    text: timeUnitsPlural[timeUnit]
  };
});
var MILLISECONDS_IN_SECOND = 1000;
var MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
var MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;

function fromMilliseconds(milliseconds) {
  var round = function round(value) {
    return parseFloat(value.toFixed(2));
  };

  if (milliseconds > MILLISECONDS_IN_HOUR) {
    return {
      units: 'h',
      value: round(milliseconds / MILLISECONDS_IN_HOUR)
    };
  }

  if (milliseconds > MILLISECONDS_IN_MINUTE) {
    return {
      units: 'm',
      value: round(milliseconds / MILLISECONDS_IN_MINUTE)
    };
  }

  return {
    units: 's',
    value: round(milliseconds / MILLISECONDS_IN_SECOND)
  };
}

function toMilliseconds(units, value) {
  switch (units) {
    case 'h':
      return Math.round(value * MILLISECONDS_IN_HOUR);

    case 'm':
      return Math.round(value * MILLISECONDS_IN_MINUTE);

    case 's':
    default:
      return Math.round(value * MILLISECONDS_IN_SECOND);
  }
}

export class OuiRefreshInterval extends Component {
  state = fromMilliseconds(this.props.refreshInterval);
  generateId = htmlIdGenerator();
  onValueChange = event => {
    const sanitizedValue = parseFloat(event.target.value);
    this.setState({
      value: isNaN(sanitizedValue) ? '' : sanitizedValue
    }, this.applyRefreshInterval);
  };
  onUnitsChange = event => {
    this.setState({
      units: event.target.value
    }, this.applyRefreshInterval);
  };
  startRefresh = () => {
    const {
      applyRefreshInterval
    } = this.props;
    const {
      value,
      units
    } = this.state;

    if (value !== '' && value > 0 && applyRefreshInterval !== undefined) {
      applyRefreshInterval({
        refreshInterval: toMilliseconds(units, value),
        isPaused: false
      });
    }
  };
  handleKeyDown = ({
    key
  }) => {
    if (key === 'Enter') {
      this.startRefresh();
    }
  };
  applyRefreshInterval = () => {
    const {
      applyRefreshInterval,
      isPaused
    } = this.props;
    const {
      units,
      value
    } = this.state;

    if (value === '') {
      return;
    }

    if (!applyRefreshInterval) {
      return;
    }

    const refreshInterval = toMilliseconds(units, value);
    applyRefreshInterval({
      refreshInterval,
      isPaused: refreshInterval <= 0 ? true : isPaused
    });
  };
  toggleRefresh = () => {
    const {
      applyRefreshInterval,
      isPaused
    } = this.props;
    const {
      units,
      value
    } = this.state;

    if (!applyRefreshInterval || value === '') {
      return;
    }

    applyRefreshInterval({
      refreshInterval: toMilliseconds(units, value),
      isPaused: !isPaused
    });
  };

  render() {
    const {
      applyRefreshInterval,
      isPaused
    } = this.props;
    const {
      value,
      units
    } = this.state;
    const legendId = this.generateId();
    const refreshSelectionId = this.generateId();

    if (!applyRefreshInterval) {
      return null;
    }

    const options = refreshUnitsOptions.find(({
      value
    }) => value === units);
    const optionText = options ? options.text : '';
    return <fieldset>
        <OuiTitle size="xxxs">
          <legend id={legendId}>
            <OuiI18n token="ouiRefreshInterval.legend" default="Refresh every" />
          </legend>
        </OuiTitle>
        <OuiSpacer size="s" />
        <OuiFlexGroup gutterSize="s" responsive={false}>
          <OuiFlexItem>
            <OuiFieldNumber compressed value={value} onChange={this.onValueChange} onKeyDown={this.handleKeyDown} aria-label="Refresh interval value" aria-describedby={`${refreshSelectionId} ${legendId}`} data-test-subj="superDatePickerRefreshIntervalInput" />
          </OuiFlexItem>
          <OuiFlexItem>
            <OuiSelect compressed aria-label="Refresh interval units" aria-describedby={`${refreshSelectionId} ${legendId}`} value={units} options={refreshUnitsOptions} onChange={this.onUnitsChange} onKeyDown={this.handleKeyDown} data-test-subj="superDatePickerRefreshIntervalUnitsSelect" />
          </OuiFlexItem>
          <OuiFlexItem grow={false}>
            <OuiButton className="ouiRefreshInterval__startButton" iconType={isPaused ? 'play' : 'stop'} size="s" onClick={this.toggleRefresh} disabled={value === '' || value <= 0} data-test-subj="superDatePickerToggleRefreshButton" aria-describedby={refreshSelectionId}>
              {isPaused ? <OuiI18n token="ouiRefreshInterval.start" default="Start" /> : <OuiI18n token="ouiRefreshInterval.stop" default="Stop" />}
            </OuiButton>
          </OuiFlexItem>
        </OuiFlexGroup>
        <OuiScreenReaderOnly>
          <p id={refreshSelectionId}>
            <OuiI18n token="ouiRefreshInterval.fullDescription" default="Refresh interval currently set to {optionValue} {optionText}." values={{
            optionValue: value,
            optionText
          }} />
          </p>
        </OuiScreenReaderOnly>
      </fieldset>;
  }

}
OuiRefreshInterval.propTypes = {
  applyRefreshInterval: PropTypes.func,
  isPaused: PropTypes.bool.isRequired,
  refreshInterval: PropTypes.number.isRequired
};

try {
  OuiRefreshInterval.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRefreshInterval',
    methods: [],
    props: {
      applyRefreshInterval: {
        defaultValue: null,
        description: '',
        name: 'applyRefreshInterval',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/refresh_interval.tsx',
          name: 'OuiRefreshIntervalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/refresh_interval.tsx',
          name: 'OuiRefreshIntervalProps'
        }],
        required: false,
        type: {
          name: 'ApplyRefreshInterval'
        }
      },
      isPaused: {
        defaultValue: null,
        description: '',
        name: 'isPaused',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/refresh_interval.tsx',
          name: 'OuiRefreshIntervalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/refresh_interval.tsx',
          name: 'OuiRefreshIntervalProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      refreshInterval: {
        defaultValue: null,
        description: '',
        name: 'refreshInterval',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/refresh_interval.tsx',
          name: 'OuiRefreshIntervalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/refresh_interval.tsx',
          name: 'OuiRefreshIntervalProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['OuiRefreshIntervalProps']
  };
} catch (e) {}