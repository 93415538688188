/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useMouseMove } from '../../../services';
export var OuiRangeDraggable = ({
  className,
  showTicks,
  lowerPosition,
  upperPosition,
  compressed,
  onChange,
  min,
  max,
  disabled,
  value,
  ...rest
}) => {
  const outerStyle = {
    left: `calc(${lowerPosition})`,
    right: `calc(100% - ${upperPosition} - 16px)`
  };
  const classes = classNames('ouiRangeDraggable', {
    'ouiRangeDraggable--hasTicks': showTicks,
    'ouiRangeDraggable--compressed': compressed,
    'ouiRangeDraggable--disabled': disabled
  }, className);

  const handleChange = ({
    x
  }, isFirstInteraction) => {
    if (disabled) return;
    onChange(x, isFirstInteraction);
  };

  const [handleMouseDown, handleInteraction] = useMouseMove(handleChange);
  const commonProps = {
    className: classes,
    role: 'slider',
    'aria-valuemin': min,
    'aria-valuemax': max,
    'aria-valuenow': value[0],
    'aria-valuetext': `${value[0]}, ${value[1]}`,
    'aria-disabled': !!disabled,
    tabIndex: !!disabled ? -1 : 0
  };
  return <div style={outerStyle} {...commonProps} {...rest}>
      <div className="ouiRangeDraggle__inner" onMouseDown={handleMouseDown} onTouchStart={handleInteraction} onTouchMove={handleInteraction} />
    </div>;
};
OuiRangeDraggable.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  disabled: PropTypes.bool,
  compressed: PropTypes.bool,
  showTicks: PropTypes.bool,
  lowerPosition: PropTypes.string.isRequired,
  upperPosition: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

try {
  OuiRangeDraggable.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeDraggable',
    methods: [],
    props: {
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'number[]'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      lowerPosition: {
        defaultValue: null,
        description: '',
        name: 'lowerPosition',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      upperPosition: {
        defaultValue: null,
        description: '',
        name: 'upperPosition',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_draggable.tsx',
          name: 'OuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: '(x: number, isFirstInteraction?: boolean) => void'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiRangeDraggableProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}