/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiValidatableControl } from '../validatable_control';
var resizeToClassNameMap = {
  vertical: 'ouiTextArea--resizeVertical',
  horizontal: 'ouiTextArea--resizeHorizontal',
  both: 'ouiTextArea--resizeBoth',
  none: 'ouiTextArea--resizeNone'
};
export var RESIZE = Object.keys(resizeToClassNameMap);
export var OuiTextArea = ({
  children,
  className,
  compressed,
  fullWidth = false,
  id,
  inputRef,
  isInvalid,
  name,
  placeholder,
  resize = 'vertical',
  rows,
  ...rest
}) => {
  const classes = classNames('ouiTextArea', resizeToClassNameMap[resize], {
    'ouiTextArea--fullWidth': fullWidth,
    'ouiTextArea--compressed': compressed
  }, className);
  let definedRows;

  if (rows) {
    definedRows = rows;
  } else if (compressed) {
    definedRows = 3;
  } else {
    definedRows = 6;
  }

  return <OuiValidatableControl isInvalid={isInvalid}>
      <textarea className={classes} {...rest} rows={definedRows} name={name} id={id} ref={inputRef} placeholder={placeholder}>
        {children}
      </textarea>
    </OuiValidatableControl>;
};
OuiTextArea.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  isInvalid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  compressed: PropTypes.bool,

  /**
       * Which direction, if at all, should the textarea resize
       */
  resize: PropTypes.oneOf(["vertical", "horizontal", "both", "none"]),
  inputRef: PropTypes.any
};

try {
  OuiTextArea.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTextArea',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/text_area/text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/text_area/text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/text_area/text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      resize: {
        defaultValue: {
          value: 'vertical'
        },
        description: 'Which direction, if at all, should the textarea resize',
        name: 'resize',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/text_area/text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"both" | "none" | "horizontal" | "vertical"',
          value: [{
            value: '"both"'
          }, {
            value: '"none"'
          }, {
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/text_area/text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLTextAreaElement>'
        }
      }
    },
    extendedInterfaces: ['TextareaHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}