/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiFieldNumber } from '../field_number';
export var OuiRangeInput = ({
  min,
  max,
  step,
  value,
  disabled,
  compressed,
  onChange,
  name,
  side = 'max',
  digitTolerance,
  fullWidth,
  autoSize = true,
  ...rest
}) => {
  // Chrome will properly size the input based on the max value, but FF & IE do not.
  // Calculate the width of the input based on highest number of characters.
  // Add 2 to accommodate for input stepper
  const widthStyle = autoSize ? {
    width: `${digitTolerance / 1.25 + 2}em`
  } : undefined;
  return <OuiFieldNumber name={name} className={`ouiRangeInput ouiRangeInput--${side}`} min={Number(min)} max={Number(max)} step={step} value={value === '' ? '' : Number(value)} disabled={disabled} compressed={compressed} onChange={onChange} style={widthStyle} fullWidth={fullWidth} {...rest} />;
};
OuiRangeInput.propTypes = {
  autoSize: PropTypes.bool,
  digitTolerance: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  side: PropTypes.oneOf(["min", "max"]),
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired
};

try {
  OuiRangeInput.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeInput',
    methods: [],
    props: {
      autoSize: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'autoSize',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      digitTolerance: {
        defaultValue: null,
        description: '',
        name: 'digitTolerance',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      side: {
        defaultValue: {
          value: 'max'
        },
        description: '',
        name: 'side',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"max" | "min"',
          value: [{
            value: '"max"'
          }, {
            value: '"min"'
          }]
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_input.tsx',
          name: 'OuiRangeInputProps'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      step: {
        defaultValue: null,
        description: 'Specifies the granularity that the value must adhere to.\n' + "Accepts a `number` or the string `'any'` for no stepping to allow for any value.\n" + 'Defaults to `1`',
        name: 'step',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number | "any"'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      controlOnly: {
        defaultValue: null,
        description: 'Completely removes form control layout wrapper and ignores\n' + 'icon, prepend, and append. Best used inside OuiFormControlLayoutDelimited.',
        name: 'controlOnly',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/field_number/field_number.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiRangeInputProps', 'HTMLAttributes', 'InputHTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}