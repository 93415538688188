/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiSpacer } from '../../../../../src/components/spacer';
import { OuiTitle } from '../../../../../src/components/title';
import { OuiText } from '../../../../../src/components/text';
export var LANGUAGES = ['javascript', 'html'];
export var GuideSectionExampleText = ({
  title,
  children,
  wrapText = true
}) => {
  let titleNode;

  if (title) {
    titleNode = <>
        <OuiSpacer />
        <OuiTitle>
          <h2>{title}</h2>
        </OuiTitle>
        <OuiSpacer size="m" />
      </>;
  }

  let textNode = children;

  if (children && wrapText) {
    textNode = <OuiText>{children}</OuiText>;
  }

  return <>
      {titleNode}
      {textNode}
      {(titleNode || textNode) && <OuiSpacer />}
    </>;
};
GuideSectionExampleText.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  wrapText: PropTypes.bool
};

try {
  GuideSectionExampleText.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'GuideSectionExampleText',
    methods: [],
    props: {
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      wrapText: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'wrapText',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}