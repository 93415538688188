/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiPageSideBar--paddingSmall',
  m: 'ouiPageSideBar--paddingMedium',
  l: 'ouiPageSideBar--paddingLarge'
};
export var PADDING_SIZES = keysOf(paddingSizeToClassNameMap);
export var OuiPageSideBar = ({
  children,
  className,
  sticky,
  paddingSize = 'none',
  ...rest
}) => {
  const classes = classNames('ouiPageSideBar', paddingSizeToClassNameMap[paddingSize], {
    'ouiPageSideBar--sticky': sticky
  }, className);
  return <div className={classes} {...rest}>
      {children}
    </div>;
};
OuiPageSideBar.propTypes = {
  /**
     * Adds `position: sticky` and affords for any fixed position headers
     */
  sticky: PropTypes.bool,

  /**
     * Adds padding around the children
     */
  paddingSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiPageSideBar.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPageSideBar',
    methods: [],
    props: {
      sticky: {
        defaultValue: null,
        description: 'Adds `position: sticky` and affords for any fixed position headers',
        name: 'sticky',
        parent: {
          fileName: 'docs/src/components/page/page_side_bar/page_side_bar.tsx',
          name: 'OuiPageSideBarProps'
        },
        declarations: [{
          fileName: 'docs/src/components/page/page_side_bar/page_side_bar.tsx',
          name: 'OuiPageSideBarProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adds padding around the children',
        name: 'paddingSize',
        parent: {
          fileName: 'docs/src/components/page/page_side_bar/page_side_bar.tsx',
          name: 'OuiPageSideBarProps'
        },
        declarations: [{
          fileName: 'docs/src/components/page/page_side_bar/page_side_bar.tsx',
          name: 'OuiPageSideBarProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiPageSideBarProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}