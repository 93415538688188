/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Fragment, useContext } from 'react';
import { OuiI18nConsumer } from '../context';
import { I18nContext } from '../context/context';
import { processStringToChildren } from './i18n_util';

function errorOnMissingValues(token) {
  throw new Error("I18n mapping for token \"".concat(token, "\" is a formatting function but no values were provided."));
}

function lookupToken(token, i18nMapping, valueDefault, i18nMappingFunc, values) {
  var renderable = i18nMapping && i18nMapping[token] || valueDefault;

  if (typeof renderable === 'function') {
    if (values === undefined) {
      return errorOnMissingValues(token);
    } // @ts-ignore TypeScript complains that `DEFAULT` doesn't have a call signature but we verified `renderable` is a function


    return renderable(values);
  } else if (values === undefined || typeof renderable !== 'string') {
    if (i18nMappingFunc && typeof valueDefault === 'string') {
      renderable = i18nMappingFunc(valueDefault);
    } // there's a hole in the typings here as there is no guarantee that i18nMappingFunc
    // returned the same type of the default value, but we need to keep that assumption


    return renderable;
  }

  var children = processStringToChildren(renderable, values, i18nMappingFunc);

  if (typeof children === 'string') {
    // likewise, `processStringToChildren` returns a string or ReactChild[] depending on
    // the type of `values`, so we will make the assumption that the default value is correct.
    return children;
  }

  var Component = () => {
    return <Fragment>{children}</Fragment>;
  }; // same reasons as above, we can't promise the transforms match the default's type


  return React.createElement(Component, values);
}

function isI18nTokensShape(x) {
  return x.tokens != null;
} // Must use the generics <T extends {}>
// If instead typed with React.FunctionComponent there isn't feedback given back to the dev
// when using a `values` object with a renderer callback.


var OuiI18n = props => <OuiI18nConsumer>
    {i18nConfig => {
    const {
      mapping,
      mappingFunc
    } = i18nConfig;

    if (isI18nTokensShape(props)) {
      return props.children(props.tokens.map((token, idx) => lookupToken(token, mapping, props.defaults[idx], mappingFunc)));
    }

    const tokenValue = lookupToken(props.token, mapping, props.default, mappingFunc, props.values);

    if (props.children) {
      return props.children(tokenValue);
    } else {
      return tokenValue;
    }
  }}
  </OuiI18nConsumer>; // A single default could be a string, react child, or render function


function useOuiI18n(...props) {
  const i18nConfig = useContext(I18nContext);
  const {
    mapping,
    mappingFunc
  } = i18nConfig;

  if (typeof props[0] === 'string') {
    const [token, defaultValue, values] = props;
    return lookupToken(token, mapping, defaultValue, mappingFunc, values);
  } else {
    const [tokens, defaultValues] = props;
    return tokens.map((token, idx) => lookupToken(token, mapping, defaultValues[idx], mappingFunc));
  }
}

export { OuiI18n, useOuiI18n };

try {
  OuiI18n.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiI18n',
    methods: [],
    props: {
      default: {
        defaultValue: null,
        description: '',
        name: 'default',
        parent: {
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        },
        declarations: [{
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: false,
        type: {
          name: 'Renderable<T>'
        }
      },
      values: {
        defaultValue: null,
        description: '',
        name: 'values',
        parent: {
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        },
        declarations: [{
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: false,
        type: {
          name: '{}'
        }
      },
      token: {
        defaultValue: null,
        description: '',
        name: 'token',
        parent: {
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        },
        declarations: [{
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      tokens: {
        defaultValue: null,
        description: '',
        name: 'tokens',
        parent: {
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        },
        declarations: [{
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      defaults: {
        defaultValue: null,
        description: '',
        name: 'defaults',
        parent: {
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        },
        declarations: [{
          fileName: 'docs/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        }],
        required: false,
        type: {
          name: 'any[]'
        }
      }
    },
    extendedInterfaces: ['I18nTokenShape', 'I18nTokensShape']
  };
} catch (e) {}