/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiFormLabel = ({
  type = 'label',
  isFocused,
  isInvalid,
  children,
  className,
  ...rest
}) => {
  const classes = classNames('ouiFormLabel', className, {
    'ouiFormLabel-isFocused': isFocused,
    'ouiFormLabel-isInvalid': isInvalid
  });

  if (type === 'legend') {
    return <legend className={classes} {...rest}>
        {children}
      </legend>;
  } else {
    return <label className={classes} {...rest}>
        {children}
      </label>;
  }
};
OuiFormLabel.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Default type is a `label` but can be changed to a `legend`
     * if using inside a `fieldset`.
     */

  /**
     * Default type is a `label` but can be changed to a `legend`
     * if using inside a `fieldset`.
     */
  type: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.oneOf(["label"]), PropTypes.oneOf(["label", "legend"])]), PropTypes.oneOfType([PropTypes.oneOf(["legend"]).isRequired, PropTypes.oneOf(["label", "legend"])])]),
  isFocused: PropTypes.bool,
  isInvalid: PropTypes.bool
};

try {
  OuiFormLabel.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFormLabel',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      type: {
        defaultValue: {
          value: 'label'
        },
        description: 'Default type is a `label` but can be changed to a `legend`\n' + 'if using inside a `fieldset`.',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'OuiFormLabelCommonProps'
        }, {
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'OuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"label" | "legend"',
          value: [{
            value: '"label"'
          }, {
            value: '"legend"'
          }]
        }
      },
      isFocused: {
        defaultValue: null,
        description: '',
        name: 'isFocused',
        parent: {
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'OuiFormLabelCommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'OuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'OuiFormLabelCommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/form_label/form_label.tsx',
          name: 'OuiFormLabelCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'LabelHTMLAttributes', 'OuiFormLabelCommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}