/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { prettyDuration } from '../pretty_duration';
import { OuiI18n } from '../../../i18n';
import { htmlIdGenerator } from '../../../../services';
import { OuiTitle } from '../../../title';
import { OuiLink } from '../../../link';
import { OuiHorizontalRule } from '../../../horizontal_rule';
var generateId = htmlIdGenerator();
export var OuiRecentlyUsed = ({
  applyTime,
  commonlyUsedRanges,
  dateFormat,
  recentlyUsedRanges = []
}) => {
  const legendId = generateId();

  if (recentlyUsedRanges.length === 0) {
    return null;
  }

  const links = recentlyUsedRanges.map(({
    start,
    end
  }) => {
    const applyRecentlyUsed = () => {
      applyTime({
        start,
        end
      });
    };

    return <li className="ouiQuickSelectPopover__sectionItem" key={`${start}-${end}`}>
        <OuiLink onClick={applyRecentlyUsed}>
          {prettyDuration(start, end, commonlyUsedRanges, dateFormat)}
        </OuiLink>
      </li>;
  });
  return <fieldset>
      <OuiTitle size="xxxs">
        <legend id={legendId}>
          <OuiI18n token="ouiRecentlyUsed.legend" default="Recently used date ranges" />
        </legend>
      </OuiTitle>
      <div className="ouiQuickSelectPopover__section">
        <ul>{links}</ul>
      </div>
      <OuiHorizontalRule margin="s" />
    </fieldset>;
};
OuiRecentlyUsed.propTypes = {
  applyTime: PropTypes.func.isRequired,
  commonlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  dateFormat: PropTypes.string.isRequired,
  recentlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired)
};
OuiRecentlyUsed.displayName = 'OuiRecentlyUsed';

try {
  OuiRecentlyUsed.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRecentlyUsed',
    methods: [],
    props: {
      applyTime: {
        defaultValue: null,
        description: '',
        name: 'applyTime',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        }],
        required: true,
        type: {
          name: 'ApplyTime'
        }
      },
      commonlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'commonlyUsedRanges',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      recentlyUsedRanges: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'recentlyUsedRanges',
        parent: {
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'OuiRecentlyUsedProps'
        }],
        required: false,
        type: {
          name: 'DurationRange[]'
        }
      }
    },
    extendedInterfaces: ['OuiRecentlyUsedProps']
  };
} catch (e) {}