/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { OuiFilterButton } from '../../filter_group';
import { isNil } from '../../../services/predicate';
import { Query } from '../query';
export class IsFilter extends Component {
  resolveDisplay(clause) {
    const {
      name,
      negatedName
    } = this.props.config;

    if (isNil(clause)) {
      return {
        hasActiveFilters: false,
        name
      };
    }

    return Query.isMust(clause) ? {
      hasActiveFilters: true,
      name
    } : {
      hasActiveFilters: true,
      name: negatedName ? negatedName : `Not ${name}`
    };
  }

  valueChanged(field, checked) {
    const query = checked ? this.props.query.removeIsClause(field) : this.props.query.addMustIsClause(field);
    this.props.onChange(query);
  }

  render() {
    const {
      query,
      config
    } = this.props;
    const clause = query.getIsClause(config.field);
    const checked = !isNil(clause);
    const {
      hasActiveFilters,
      name
    } = this.resolveDisplay(clause);

    const onClick = () => {
      this.valueChanged(config.field, checked);
    };

    return <OuiFilterButton onClick={onClick} hasActiveFilters={hasActiveFilters} aria-pressed={!!hasActiveFilters}>
        {name}
      </OuiFilterButton>;
  }

}
IsFilter.propTypes = {
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["is"]).isRequired,
    field: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    negatedName: PropTypes.string,
    available: PropTypes.func
  }).isRequired,
  query: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

try {
  IsFilter.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'IsFilter',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      config: {
        defaultValue: null,
        description: '',
        name: 'config',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: 'IsFilterConfigType'
        }
      },
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: 'Query'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: '(value: Query) => void'
        }
      }
    },
    extendedInterfaces: ['IsFilterProps']
  };
} catch (e) {}