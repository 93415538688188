/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
var directionToClassNameMap = {
  row: null,
  column: 'ouiFlexGrid--directionColumn'
};
export var DIRECTIONS = keysOf(directionToClassNameMap);
var gutterSizeToClassNameMap = {
  none: 'ouiFlexGrid--gutterNone',
  s: 'ouiFlexGrid--gutterSmall',
  m: 'ouiFlexGrid--gutterMedium',
  l: 'ouiFlexGrid--gutterLarge',
  xl: 'ouiFlexGrid--gutterXLarge'
};
export var GUTTER_SIZES = keysOf(gutterSizeToClassNameMap);
var columnsToClassNameMap = {
  0: 'ouiFlexGrid--wrap',
  1: 'ouiFlexGrid--single',
  2: 'ouiFlexGrid--halves',
  3: 'ouiFlexGrid--thirds',
  4: 'ouiFlexGrid--fourths'
};
export var COLUMNS = Object.keys(columnsToClassNameMap).map(function (columns) {
  return parseInt(columns, 10);
});
export var OuiFlexGrid = ({
  children,
  className,
  gutterSize = 'l',
  direction = 'row',
  responsive = true,
  columns = 0,
  component: Component = 'div',
  ...rest
}) => {
  const classes = classNames('ouiFlexGrid', gutterSize ? gutterSizeToClassNameMap[gutterSize] : undefined, columns != null ? columnsToClassNameMap[columns] : undefined, direction ? directionToClassNameMap[direction] : undefined, {
    'ouiFlexGrid--responsive': responsive
  }, className);
  return (// @ts-ignore difficult to verify `rest` applies to `Component`
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
};
OuiFlexGrid.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,

  /**
     * Number of columns `1-4`, pass `0` for normal display
     */
  columns: PropTypes.oneOf([0, 1, 2, 3, 4]),

  /**
     * Flex layouts default to left-right then top-down (`row`).
     * Change this prop to `column` to create a top-down then left-right display.
     * Only works with column count of `1-4`.
     */
  direction: PropTypes.oneOf(["row", "column"]),

  /**
     * Space between flex items
     */
  gutterSize: PropTypes.oneOf(["none", "s", "m", "l", "xl"]),

  /**
     * Force each item to be display block on smaller screens
     */
  responsive: PropTypes.bool,

  /**
     * The tag to render
     */
  component: PropTypes.any
};

try {
  OuiFlexGrid.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiFlexGrid',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      columns: {
        defaultValue: {
          value: '0'
        },
        description: 'Number of columns `1-4`, pass `0` for normal display',
        name: 'columns',
        parent: {
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        },
        declarations: [{
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'FlexGridColumns',
          value: [{
            value: '0'
          }, {
            value: '1'
          }, {
            value: '4'
          }, {
            value: '2'
          }, {
            value: '3'
          }]
        }
      },
      direction: {
        defaultValue: {
          value: 'row'
        },
        description: 'Flex layouts default to left-right then top-down (`row`).\n' + 'Change this prop to `column` to create a top-down then left-right display.\n' + 'Only works with column count of `1-4`.',
        name: 'direction',
        parent: {
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        },
        declarations: [{
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Space between flex items',
        name: 'gutterSize',
        parent: {
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        },
        declarations: [{
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"none"'
          }]
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: 'Force each item to be display block on smaller screens',
        name: 'responsive',
        parent: {
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        },
        declarations: [{
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      component: {
        defaultValue: null,
        description: 'The tag to render',
        name: 'component',
        parent: {
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        },
        declarations: [{
          fileName: 'docs/src/components/flex/flex_grid.tsx',
          name: 'OuiFlexGridProps'
        }],
        required: false,
        type: {
          name: 'any HTML Element',
          raw: '"symbol" | "object" | "s" | "desc" | "a" | "abbr" | "address" | "area" | "article" | "aside" | "audio" | "b" | "base" | "bdi" | "bdo" | "blockquote" | "body" | "br" | "button" | "canvas" | ... 155 more ... | "version-selector"',
          value: [{
            value: '"symbol"'
          }, {
            value: '"object"'
          }, {
            value: '"s"'
          }, {
            value: '"desc"'
          }, {
            value: '"a"'
          }, {
            value: '"abbr"'
          }, {
            value: '"address"'
          }, {
            value: '"area"'
          }, {
            value: '"article"'
          }, {
            value: '"aside"'
          }, {
            value: '"audio"'
          }, {
            value: '"b"'
          }, {
            value: '"base"'
          }, {
            value: '"bdi"'
          }, {
            value: '"bdo"'
          }, {
            value: '"blockquote"'
          }, {
            value: '"body"'
          }, {
            value: '"br"'
          }, {
            value: '"button"'
          }, {
            value: '"canvas"'
          }, {
            value: '"caption"'
          }, {
            value: '"cite"'
          }, {
            value: '"code"'
          }, {
            value: '"col"'
          }, {
            value: '"colgroup"'
          }, {
            value: '"data"'
          }, {
            value: '"datalist"'
          }, {
            value: '"dd"'
          }, {
            value: '"del"'
          }, {
            value: '"details"'
          }, {
            value: '"dfn"'
          }, {
            value: '"dialog"'
          }, {
            value: '"div"'
          }, {
            value: '"dl"'
          }, {
            value: '"dt"'
          }, {
            value: '"em"'
          }, {
            value: '"embed"'
          }, {
            value: '"fieldset"'
          }, {
            value: '"figcaption"'
          }, {
            value: '"figure"'
          }, {
            value: '"footer"'
          }, {
            value: '"form"'
          }, {
            value: '"h1"'
          }, {
            value: '"h2"'
          }, {
            value: '"h3"'
          }, {
            value: '"h4"'
          }, {
            value: '"h5"'
          }, {
            value: '"h6"'
          }, {
            value: '"head"'
          }, {
            value: '"header"'
          }, {
            value: '"hgroup"'
          }, {
            value: '"hr"'
          }, {
            value: '"html"'
          }, {
            value: '"i"'
          }, {
            value: '"iframe"'
          }, {
            value: '"img"'
          }, {
            value: '"input"'
          }, {
            value: '"ins"'
          }, {
            value: '"kbd"'
          }, {
            value: '"label"'
          }, {
            value: '"legend"'
          }, {
            value: '"li"'
          }, {
            value: '"link"'
          }, {
            value: '"main"'
          }, {
            value: '"map"'
          }, {
            value: '"mark"'
          }, {
            value: '"menu"'
          }, {
            value: '"meta"'
          }, {
            value: '"meter"'
          }, {
            value: '"nav"'
          }, {
            value: '"noscript"'
          }, {
            value: '"ol"'
          }, {
            value: '"optgroup"'
          }, {
            value: '"option"'
          }, {
            value: '"output"'
          }, {
            value: '"p"'
          }, {
            value: '"param"'
          }, {
            value: '"picture"'
          }, {
            value: '"pre"'
          }, {
            value: '"progress"'
          }, {
            value: '"q"'
          }, {
            value: '"rp"'
          }, {
            value: '"rt"'
          }, {
            value: '"ruby"'
          }, {
            value: '"samp"'
          }, {
            value: '"script"'
          }, {
            value: '"section"'
          }, {
            value: '"select"'
          }, {
            value: '"slot"'
          }, {
            value: '"small"'
          }, {
            value: '"source"'
          }, {
            value: '"span"'
          }, {
            value: '"strong"'
          }, {
            value: '"style"'
          }, {
            value: '"sub"'
          }, {
            value: '"summary"'
          }, {
            value: '"sup"'
          }, {
            value: '"table"'
          }, {
            value: '"tbody"'
          }, {
            value: '"td"'
          }, {
            value: '"template"'
          }, {
            value: '"textarea"'
          }, {
            value: '"tfoot"'
          }, {
            value: '"th"'
          }, {
            value: '"thead"'
          }, {
            value: '"time"'
          }, {
            value: '"title"'
          }, {
            value: '"tr"'
          }, {
            value: '"track"'
          }, {
            value: '"u"'
          }, {
            value: '"ul"'
          }, {
            value: '"var"'
          }, {
            value: '"video"'
          }, {
            value: '"wbr"'
          }, {
            value: '"circle"'
          }, {
            value: '"clipPath"'
          }, {
            value: '"defs"'
          }, {
            value: '"ellipse"'
          }, {
            value: '"feBlend"'
          }, {
            value: '"feColorMatrix"'
          }, {
            value: '"feComponentTransfer"'
          }, {
            value: '"feComposite"'
          }, {
            value: '"feConvolveMatrix"'
          }, {
            value: '"feDiffuseLighting"'
          }, {
            value: '"feDisplacementMap"'
          }, {
            value: '"feDistantLight"'
          }, {
            value: '"feFlood"'
          }, {
            value: '"feFuncA"'
          }, {
            value: '"feFuncB"'
          }, {
            value: '"feFuncG"'
          }, {
            value: '"feFuncR"'
          }, {
            value: '"feGaussianBlur"'
          }, {
            value: '"feImage"'
          }, {
            value: '"feMerge"'
          }, {
            value: '"feMergeNode"'
          }, {
            value: '"feMorphology"'
          }, {
            value: '"feOffset"'
          }, {
            value: '"fePointLight"'
          }, {
            value: '"feSpecularLighting"'
          }, {
            value: '"feSpotLight"'
          }, {
            value: '"feTile"'
          }, {
            value: '"feTurbulence"'
          }, {
            value: '"filter"'
          }, {
            value: '"foreignObject"'
          }, {
            value: '"g"'
          }, {
            value: '"image"'
          }, {
            value: '"line"'
          }, {
            value: '"linearGradient"'
          }, {
            value: '"marker"'
          }, {
            value: '"mask"'
          }, {
            value: '"metadata"'
          }, {
            value: '"path"'
          }, {
            value: '"pattern"'
          }, {
            value: '"polygon"'
          }, {
            value: '"polyline"'
          }, {
            value: '"radialGradient"'
          }, {
            value: '"rect"'
          }, {
            value: '"stop"'
          }, {
            value: '"svg"'
          }, {
            value: '"switch"'
          }, {
            value: '"text"'
          }, {
            value: '"textPath"'
          }, {
            value: '"tspan"'
          }, {
            value: '"use"'
          }, {
            value: '"view"'
          }, {
            value: '"big"'
          }, {
            value: '"keygen"'
          }, {
            value: '"menuitem"'
          }, {
            value: '"noindex"'
          }, {
            value: '"webview"'
          }, {
            value: '"animate"'
          }, {
            value: '"animateMotion"'
          }, {
            value: '"animateTransform"'
          }, {
            value: '"feDropShadow"'
          }, {
            value: '"mpath"'
          }, {
            value: '"version-selector"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiFlexGridProps']
  };
} catch (e) {}