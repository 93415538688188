/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component, createRef } from 'react';
import PropTypes from "prop-types";
import { htmlIdGenerator } from '../../../services';
import { OuiTabs } from '../tabs';
import { OuiTab } from '../tab';
/**
 * Marked as const so type is `['initial', 'selected']` instead of `string[]`
 */

export var AUTOFOCUS = ['initial', 'selected'];
export class OuiTabbedContent extends Component {
  static defaultProps = {
    autoFocus: 'initial'
  };
  rootId = htmlIdGenerator()();
  tabsRef = createRef();

  constructor(props) {
    super(props);
    const {
      initialSelectedTab,
      selectedTab,
      tabs
    } = props; // Only track selection state if it's not controlled externally.

    let selectedTabId;

    if (!selectedTab) {
      selectedTabId = initialSelectedTab && initialSelectedTab.id || tabs[0].id;
    }

    this.state = {
      selectedTabId,
      inFocus: false
    };
  }

  componentDidMount() {
    // IE11 doesn't support the `relatedTarget` event property for blur events
    // but does add it for focusout. React doesn't support `onFocusOut` so here we are.
    if (this.tabsRef.current) {
      // Current short-term solution for event listener (see https://github.com/elastic/eui/pull/2717)
      this.tabsRef.current.addEventListener('focusout', this.removeFocus);
    }
  }

  componentWillUnmount() {
    if (this.tabsRef.current) {
      // Current short-term solution for event listener (see https://github.com/elastic/eui/pull/2717)
      this.tabsRef.current.removeEventListener('focusout', this.removeFocus);
    }
  }

  focusTab = () => {
    const targetTab = this.tabsRef.current.querySelector(`#${this.state.selectedTabId}`);
    targetTab.focus();
  };
  initializeFocus = () => {
    if (!this.state.inFocus && this.props.autoFocus === 'selected') {
      // Must wait for setState to finish before calling `.focus()`
      // as the focus call triggers a blur on the first tab
      this.setState({
        inFocus: true
      }, () => {
        this.focusTab();
      });
    }
  }; // todo: figure out type for blurEvent

  removeFocus = blurEvent => {
    // only set inFocus to false if the wrapping div doesn't contain the now-focusing element
    const currentTarget = blurEvent.currentTarget;
    const relatedTarget = blurEvent.relatedTarget;

    if (currentTarget.contains(relatedTarget) === false) {
      this.setState({
        inFocus: false
      });
    }
  };
  onTabClick = selectedTab => {
    const {
      onTabClick,
      selectedTab: externalSelectedTab
    } = this.props;

    if (onTabClick) {
      onTabClick(selectedTab);
    } // Only track selection state if it's not controlled externally.


    if (!externalSelectedTab) {
      this.setState({
        selectedTabId: selectedTab.id
      }, () => {
        this.focusTab();
      });
    }
  };

  render() {
    const {
      className,
      display,
      expand,
      initialSelectedTab,
      onTabClick,
      selectedTab: externalSelectedTab,
      size,
      tabs,
      autoFocus,
      ...rest
    } = this.props; // Allow the consumer to control tab selection.

    const selectedTab = externalSelectedTab || tabs.find(tab => tab.id === this.state.selectedTabId);
    const {
      content: selectedTabContent,
      id: selectedTabId
    } = selectedTab;
    return <div className={className} {...rest}>
        <OuiTabs ref={this.tabsRef} expand={expand} display={display} size={size} onFocus={this.initializeFocus}>
          {tabs.map(tab => {
          const {
            id,
            name,
            content,
            // eslint-disable-line no-unused-vars
            ...tabProps
          } = tab;
          const props = {
            key: id,
            id,
            ...tabProps,
            onClick: () => this.onTabClick(tab),
            isSelected: tab === selectedTab,
            'aria-controls': `${this.rootId}`
          };
          return <OuiTab {...props}>{name}</OuiTab>;
        })}
        </OuiTabs>

        <div role="tabpanel" id={`${this.rootId}`} aria-labelledby={selectedTabId}>
          {selectedTabContent}
        </div>
      </div>;
  }

}
OuiTabbedContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * When tabbing into the tabs, set the focus on `initial` for the first tab,
       * or `selected` for the currently selected tab. Best use case is for inside of
       * overlay content like popovers or flyouts.
       */
  autoFocus: PropTypes.oneOf(["initial", "selected"]),

  /**
       * Choose `default` or alternative `condensed` display styles
       */
  display: PropTypes.oneOf(["condensed", "default"]),

  /**
       * Evenly stretches each tab to fill the horizontal space
       */
  expand: PropTypes.bool,

  /**
       * Use this prop to set the initially selected tab while letting the tabbed content component
       * control selection state internally
       */
  initialSelectedTab: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired
  }),
  onTabClick: PropTypes.func,

  /**
       * Use this prop if you want to control selection state within the owner component
       */
  selectedTab: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired
  }),
  size: PropTypes.oneOf(["s", "m", "l"]),

  /**
       * Each tab needs id and content properties, so we can associate it with its panel for accessibility.
       * The name property (a node) is also required to display to the user.
       */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired
  }).isRequired).isRequired
};

try {
  AUTOFOCUS.__docgenInfo = {
    tags: {},
    description: "Marked as const so type is `['initial', 'selected']` instead of `string[]`",
    displayName: 'AUTOFOCUS',
    methods: [],
    props: {},
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}

try {
  OuiTabbedContent.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTabbedContent',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      autoFocus: {
        defaultValue: {
          value: 'initial'
        },
        description: 'When tabbing into the tabs, set the focus on `initial` for the first tab,\n' + 'or `selected` for the currently selected tab. Best use case is for inside of\n' + 'overlay content like popovers or flyouts.',
        name: 'autoFocus',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"initial" | "selected"',
          value: [{
            value: '"initial"'
          }, {
            value: '"selected"'
          }]
        }
      },
      display: {
        defaultValue: null,
        description: 'Choose `default` or alternative `condensed` display styles',
        name: 'display',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "condensed"',
          value: [{
            value: '"default"'
          }, {
            value: '"condensed"'
          }]
        }
      },
      expand: {
        defaultValue: null,
        description: 'Evenly stretches each tab to fill the horizontal space',
        name: 'expand',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialSelectedTab: {
        defaultValue: null,
        description: 'Use this prop to set the initially selected tab while letting the tabbed content component\n' + 'control selection state internally',
        name: 'initialSelectedTab',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiTabbedContentTab'
        }
      },
      onTabClick: {
        defaultValue: null,
        description: '',
        name: 'onTabClick',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(selectedTab: OuiTabbedContentTab) => void'
        }
      },
      selectedTab: {
        defaultValue: null,
        description: 'Use this prop if you want to control selection state within the owner component',
        name: 'selectedTab',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiTabbedContentTab'
        }
      },
      size: {
        defaultValue: null,
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      tabs: {
        defaultValue: null,
        description: 'Each tab needs id and content properties, so we can associate it with its panel for accessibility.\n' + 'The name property (a node) is also required to display to the user.',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'OuiTabbedContentTab[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}