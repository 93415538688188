/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiButton } from '../../button';
import { OuiI18n } from '../../i18n';
import { OuiToolTip } from '../../tool_tip';
export class OuiSuperUpdateButton extends Component {
  static defaultProps = {
    needsUpdate: false,
    isLoading: false,
    isDisabled: false,
    showTooltip: false
  };
  _isMounted = false;
  tooltip = null;

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate() {
    if (this.props.showTooltip && this.props.needsUpdate && !this.props.isDisabled && !this.props.isLoading) {
      this.showTooltip();
      this.tooltipTimeout = setTimeout(() => {
        this.hideTooltip();
      }, 2000);
    }
  }

  setTootipRef = node => {
    this.tooltip = node;
  };
  showTooltip = () => {
    if (!this._isMounted || !this.tooltip) {
      return;
    }

    this.tooltip.showToolTip();
  };
  hideTooltip = () => {
    if (!this._isMounted || !this.tooltip) {
      return;
    }

    this.tooltip.hideToolTip();
  };

  render() {
    const {
      className,
      needsUpdate,
      isLoading,
      isDisabled,
      onClick,
      toolTipProps,
      showTooltip,
      textProps: restTextProps,
      ...rest
    } = this.props;
    const classes = classNames('ouiSuperUpdateButton', className);
    let buttonText = <OuiI18n token="ouiSuperUpdateButton.refreshButtonLabel" default="Refresh" />;

    if (needsUpdate || isLoading) {
      buttonText = isLoading ? <OuiI18n token="ouiSuperUpdateButton.updatingButtonLabel" default="Updating" /> : <OuiI18n token="ouiSuperUpdateButton.updateButtonLabel" default="Update" />;
    }

    let tooltipContent;

    if (isDisabled) {
      tooltipContent = <OuiI18n token="ouiSuperUpdateButton.cannotUpdateTooltip" default="Cannot update" />;
    } else if (needsUpdate && !isLoading) {
      tooltipContent = <OuiI18n token="ouiSuperUpdateButton.clickToApplyTooltip" default="Click to apply" />;
    }

    return <OuiToolTip ref={this.setTootipRef} content={tooltipContent} position="bottom" {...toolTipProps}>
        <OuiButton className={classes} color={needsUpdate || isLoading ? 'success' : 'primary'} fill iconType={needsUpdate || isLoading ? 'kqlFunction' : 'refresh'} textProps={{ ...restTextProps,
        className: classNames('ouiSuperUpdateButton__text', restTextProps?.className)
      }} isDisabled={isDisabled} onClick={onClick} isLoading={isLoading} {...rest}>
          {buttonText}
        </OuiButton>
      </OuiToolTip>;
  }

}
OuiSuperUpdateButton.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  needsUpdate: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,

  /**
       * Passes props to `OuiToolTip`
       */
  toolTipProps: PropTypes.shape({
    /**
       * Passes onto the the trigger.
       */
    anchorClassName: PropTypes.string,

    /**
       * The in-view trigger for your tooltip.
       */
    children: PropTypes.element.isRequired,

    /**
       * Passes onto the tooltip itself, not the trigger.
       */
    className: PropTypes.string,

    /**
       * The main content of your tooltip.
       */
    content: PropTypes.node,

    /**
       * Common display alternatives for the anchor wrapper
       */
    display: PropTypes.oneOf(["inlineBlock", "block"]),

    /**
       * Delay before showing tooltip. Good for repeatable items.
       */
    delay: PropTypes.oneOf(["regular", "long"]).isRequired,

    /**
       * An optional title for your tooltip.
       */
    title: PropTypes.node,

    /**
       * Unless you provide one, this will be randomly generated.
       */
    id: PropTypes.string,

    /**
       * Suggested position. If there is not enough room for it this will be changed.
       */
    position: PropTypes.oneOf(["top", "right", "bottom", "left"]).isRequired,

    /**
       * If supplied, called when mouse movement causes the tool tip to be
       * hidden.
       */
    onMouseOut: PropTypes.func
  }),

  /**
       * Show the "Click to apply" tooltip
       */
  showTooltip: PropTypes.bool.isRequired
};

try {
  OuiSuperUpdateButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSuperUpdateButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of our named colors.\n' + '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ButtonColor',
          value: [{
            value: '"text"'
          }, {
            value: '"secondary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"danger"'
          }, {
            value: '"warning"'
          }, {
            value: '"ghost"'
          }, {
            value: '"primary"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: 'Use size `s` in confined spaces',
        name: 'size',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'Size',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      iconType: {
        defaultValue: null,
        description: 'Any `type` accepted by OuiIcon',
        name: 'iconType',
        parent: {
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      fill: {
        defaultValue: null,
        description: 'Make button a solid color for prominence',
        name: 'fill',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minWidth: {
        defaultValue: null,
        description: 'Override the default minimum width',
        name: 'minWidth',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: 'Extends the button to 100% width',
        name: 'fullWidth',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contentProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the button's content",
        name: 'contentProps',
        parent: {
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button.tsx',
          name: 'OuiButtonProps'
        }],
        required: false,
        type: {
          name: 'OuiButtonContentType'
        }
      },
      iconSide: {
        defaultValue: null,
        description: 'Can only be one side `left` or `right`',
        name: 'iconSide',
        parent: {
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToastSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      textProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the content's text/children only (not icon)",
        name: 'textProps',
        parent: {
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: "HTMLAttributes<HTMLSpanElement> & CommonProps & { ref?: Ref<HTMLSpanElement>; 'data-text'?: string; }"
        }
      },
      iconSize: {
        defaultValue: null,
        description: '',
        name: 'iconSize',
        parent: {
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        },
        declarations: [{
          fileName: 'docs/src/components/button/button_content.tsx',
          name: 'OuiButtonContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'Size',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      isDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      needsUpdate: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'needsUpdate',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: 'Passes props to `OuiToolTip`',
        name: 'toolTipProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OuiToolTipProps'
        }
      },
      showTooltip: {
        defaultValue: {
          value: 'false'
        },
        description: 'Show the "Click to apply" tooltip',
        name: 'showTooltip',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'OuiButtonProps', 'OuiButtonContentProps']
  };
} catch (e) {}