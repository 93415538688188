export var ouiAnimSlightBounce = "cubic-bezier(0.34, 1.61, 0.7, 1)"
export var ouiAnimSlightResistance = "cubic-bezier(0.694, 0.0482, 0.335, 1)"
export var ouiAnimSpeedExtraFast = 90
export var ouiAnimSpeedFast = 150
export var ouiAnimSpeedNormal = 250
export var ouiAnimSpeedSlow = 350
export var ouiAnimSpeedExtraSlow = 500
export var euiAnimSlightBounce = "cubic-bezier(0.34, 1.61, 0.7, 1)"
export var euiAnimSlightResistance = "cubic-bezier(0.694, 0.0482, 0.335, 1)"
export var euiAnimSpeedExtraFast = 90
export var euiAnimSpeedFast = 150
export var euiAnimSpeedNormal = 250
export var euiAnimSpeedSlow = 350
export var euiAnimSpeedExtraSlow = 500
export default {"ouiAnimSlightBounce":"cubic-bezier(0.34, 1.61, 0.7, 1)","ouiAnimSlightResistance":"cubic-bezier(0.694, 0.0482, 0.335, 1)","ouiAnimSpeedExtraFast":90,"ouiAnimSpeedFast":150,"ouiAnimSpeedNormal":250,"ouiAnimSpeedSlow":350,"ouiAnimSpeedExtraSlow":500,"euiAnimSlightBounce":"cubic-bezier(0.34, 1.61, 0.7, 1)","euiAnimSlightResistance":"cubic-bezier(0.694, 0.0482, 0.335, 1)","euiAnimSpeedExtraFast":90,"euiAnimSpeedFast":150,"euiAnimSpeedNormal":250,"euiAnimSpeedSlow":350,"euiAnimSpeedExtraSlow":500}
