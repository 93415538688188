/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
import { OuiPageHeaderContent } from './page_header_content';
import { setPropsForRestrictedPageWidth } from '../_restrict_width';
var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiPageHeader--paddingSmall',
  m: 'ouiPageHeader--paddingMedium',
  l: 'ouiPageHeader--paddingLarge'
};
export var PADDING_SIZES = keysOf(paddingSizeToClassNameMap);
export var OuiPageHeader = ({
  className,
  restrictWidth = false,
  paddingSize = 'none',
  bottomBorder,
  style,
  // Page header content shared props:
  alignItems,
  responsive = true,
  children,
  // Page header content only props:
  pageTitle,
  tabs,
  tabsProps,
  description,
  rightSideItems,
  rightSideGroupProps,
  ...rest
}) => {
  const {
    widthClassName,
    newStyle
  } = setPropsForRestrictedPageWidth(restrictWidth, style);
  const classes = classNames('ouiPageHeader', paddingSizeToClassNameMap[paddingSize], {
    'ouiPageHeader--bottomBorder': bottomBorder,
    'ouiPageHeader--responsive': responsive === true,
    'ouiPageHeader--responsiveReverse': responsive === 'reverse',
    'ouiPageHeader--tabsAtBottom': pageTitle && tabs,
    [`ouiPage--${widthClassName}`]: widthClassName
  }, `ouiPageHeader--${alignItems ?? 'center'}`, className);

  if (!pageTitle && !tabs && !description && !rightSideItems) {
    return <header className={classes} style={newStyle || style} {...rest}>
        {children}
      </header>;
  }

  return <header className={classes} style={newStyle || style} {...rest}>
      <OuiPageHeaderContent alignItems={alignItems} responsive={responsive} pageTitle={pageTitle} tabs={tabs} tabsProps={tabsProps} description={description} rightSideItems={rightSideItems} rightSideGroupProps={rightSideGroupProps}>
        {children}
      </OuiPageHeaderContent>
    </header>;
};
OuiPageHeader.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Wrapped in an `H1` so choose appropriately.
     * A simple string is best
     */
  pageTitle: PropTypes.node,

  /**
     * In-app navigation presented as large borderless tabs.
     * Accepts an array of `OuiTab` objects;
     * HELP: This is evaluating to `any[]` in the props table
     */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Visible text of the tab
       */
    label: PropTypes.node.isRequired
  }).isRequired),

  /**
     * Any extras to apply to the outer tabs container.
     * Extends `OuiTabs`
     */
  tabsProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
         * ReactNode to render as this component's content
         */
    children: PropTypes.node
  }),

  /**
       * Position is dependent on existing with a `pageTitle` or `tabs`
       * Automatically get wrapped in a single paragraph tag inside an OuiText block
       */
  description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),

  /**
       * Set to false if you don't want the children to stack at small screen sizes.
       * Set to `reverse` to display the right side content first for the sack of hierarchy (like global time)
       */
  responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["reverse"])]),

  /**
       * Vertical alignment of the left and right side content;
       * Default is `middle` for custom content, but `top` for when `pageTitle` or `tabs` are included
       */
  alignItems: PropTypes.any,

  /**
       * Pass custom an array of content to this side usually up to 3 buttons.
       * The first button should be primary, usually with `fill` and will be visually displayed as the last item,
       * but first in the tab order
       */
  rightSideItems: PropTypes.arrayOf(PropTypes.node.isRequired),

  /**
       * Additional OuiFlexGroup props to pass to the container of the `rightSideItems`
       */
  rightSideGroupProps: PropTypes.any,

  /**
       * Custom children will be rendered before the `tabs` unless no `pageTitle` is present, then it will be the last item
       */
  children: PropTypes.node,

  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1000px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),

  /**
       * Adjust the padding.
       * When using this setting it's best to be consistent throughout all similar usages
       */
  paddingSize: PropTypes.any,

  /**
       * Adds a bottom border to separate it from the content after
       */
  bottomBorder: PropTypes.bool
};

try {
  OuiPageHeader.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPageHeader',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'Custom children will be rendered before the `tabs` unless no `pageTitle` is present, then it will be the last item',
        name: 'children',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      pageTitle: {
        defaultValue: null,
        description: 'Wrapped in an `H1` so choose appropriately.\nA simple string is best',
        name: 'pageTitle',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      tabs: {
        defaultValue: null,
        description: 'In-app navigation presented as large borderless tabs.\n' + 'Accepts an array of `OuiTab` objects;\n' + 'HELP: This is evaluating to `any[]` in the props table',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Tab[]'
        }
      },
      tabsProps: {
        defaultValue: null,
        description: 'Any extras to apply to the outer tabs container.\nExtends `OuiTabs`',
        name: 'tabsProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<OuiTabsProps, "property" | "dir" | "slot" | "style" | "title" | "children" | "color" | "defaultChecked" | "defaultValue" | "suppressContentEditableWarning" | ... 244 more ... | "data-test-subj">'
        }
      },
      description: {
        defaultValue: null,
        description: 'Position is dependent on existing with a `pageTitle` or `tabs`\n' + 'Automatically get wrapped in a single paragraph tag inside an OuiText block',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: "Set to false if you don't want the children to stack at small screen sizes.\n" + 'Set to `reverse` to display the right side content first for the sack of hierarchy (like global time)',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "reverse"'
        }
      },
      alignItems: {
        defaultValue: null,
        description: 'Vertical alignment of the left and right side content;\n' + 'Default is `middle` for custom content, but `top` for when `pageTitle` or `tabs` are included',
        name: 'alignItems',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "top" | "bottom" | "stretch"',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"stretch"'
          }]
        }
      },
      rightSideItems: {
        defaultValue: null,
        description: 'Pass custom an array of content to this side usually up to 3 buttons.\n' + 'The first button should be primary, usually with `fill` and will be visually displayed as the last item,\n' + 'but first in the tab order',
        name: 'rightSideItems',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode[]'
        }
      },
      rightSideGroupProps: {
        defaultValue: null,
        description: 'Additional OuiFlexGroup props to pass to the container of the `rightSideItems`',
        name: 'rightSideGroupProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<OuiFlexGroupProps>'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1000px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adjust the padding.\n' + "When using this setting it's best to be consistent throughout all similar usages",
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      bottomBorder: {
        defaultValue: null,
        description: 'Adds a bottom border to separate it from the content after',
        name: 'bottomBorder',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}