export var ouiSize = 16
export var ouiSizeXs = 4
export var ouiSizeS = 8
export var ouiSizeM = 12
export var ouiSizeL = 24
export var ouiSizeXl = 32
export var ouiSizeXxl = 40
export var ouiButtonMinWidth = 112
export var ouiScrollBar = 16
export var ouiScrollBarCorner = 6
export var euiSize = 16
export var euiSizeXs = 4
export var euiSizeS = 8
export var euiSizeM = 12
export var euiSizeL = 24
export var euiSizeXl = 32
export var euiSizeXxl = 40
export var euiButtonMinWidth = 112
export var euiScrollBar = 16
export var euiScrollBarCorner = 6
export default {"ouiSize":16,"ouiSizeXS":4,"ouiSizeS":8,"ouiSizeM":12,"ouiSizeL":24,"ouiSizeXL":32,"ouiSizeXXL":40,"ouiButtonMinWidth":112,"ouiScrollBar":16,"ouiScrollBarCorner":6,"euiSize":16,"euiSizeXS":4,"euiSizeS":8,"euiSizeM":12,"euiSizeL":24,"euiSizeXL":32,"euiSizeXXL":40,"euiButtonMinWidth":112,"euiScrollBar":16,"euiScrollBarCorner":6}
