/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiSuperSelect } from '../../form';
import { OuiColorPaletteDisplay } from '../color_palette_display';
export var OuiColorPalettePicker = ({
  className,
  compressed = false,
  disabled,
  fullWidth = false,
  isInvalid = false,
  onChange,
  readOnly = false,
  valueOfSelected,
  palettes,
  append,
  prepend,
  selectionDisplay = 'palette',
  ...rest
}) => {
  const getPalette = ({
    type,
    palette
  }) => {
    // Working around ExclusiveUnion
    return type === 'gradient' ? <OuiColorPaletteDisplay type={type} palette={palette} /> : <OuiColorPaletteDisplay type={type} palette={palette} />;
  };

  const paletteOptions = palettes.map(item => {
    const {
      type,
      value,
      title,
      palette,
      ...rest
    } = item;
    const paletteForDisplay = item.type !== 'text' ? getPalette(item) : null;
    return {
      value: String(value),
      inputDisplay: selectionDisplay === 'title' || type === 'text' ? title : paletteForDisplay,
      dropdownDisplay: <div className="ouiColorPalettePicker__item">
            {title && type !== 'text' && <div className="ouiColorPalettePicker__itemTitle">{title}</div>}
            {type === 'text' ? title : paletteForDisplay}
          </div>,
      ...rest
    };
  });
  return <OuiSuperSelect className={className} options={paletteOptions} valueOfSelected={valueOfSelected} onChange={onChange} hasDividers isInvalid={isInvalid} compressed={compressed} disabled={disabled} readOnly={readOnly} fullWidth={fullWidth} append={append} prepend={prepend} {...rest} />;
};
OuiColorPalettePicker.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  compressed: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,

  /**
     * Creates an input group with element(s) coming before input.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),

  /**
     * Creates an input group with element(s) coming after input.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  valueOfSelected: PropTypes.any,

  /**
       * Classes for the context menu item
       */
  itemClassName: PropTypes.string,

  /**
       * You must pass an `onChange` function to handle the update of the value
       */
  onChange: PropTypes.func,

  /**
       * Applied to the outermost wrapper (popover)
       */
  popoverClassName: PropTypes.string,

  /**
       * Controls whether the options are shown. Default: false
       */
  isOpen: PropTypes.bool,

  /**
       *  Specify what should be displayed after a selection: a `palette` or `title`
       */
  selectionDisplay: PropTypes.oneOf(["palette", "title"]),

  /**
       * An array of one of the following objects: #OuiColorPalettePickerPaletteText, #OuiColorPalettePickerPaletteFixed, #OuiColorPalettePickerPaletteGradient
       */
  palettes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    /**
       *  For storing unique value of item
       */
    value: PropTypes.string.isRequired,

    /**
       *  The name of your palette
       */
    title: PropTypes.string.isRequired,

    /**
       * `text`: a text only option (a title is required).
       */
    type: PropTypes.oneOf(["text"]).isRequired,

    /**
       * Array of color `strings` or an array of #ColorStop. The stops must be numbers in an ordered range.
       */
    palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired).isRequired]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }).isRequired, PropTypes.shape({
    /**
       *  For storing unique value of item
       */
    value: PropTypes.string.isRequired,

    /**
       *  The name of your palette
       */
    title: PropTypes.string,

    /**
       * `fixed`: individual color blocks
       */
    type: PropTypes.oneOf(["fixed"]).isRequired,

    /**
       * Array of color `strings` or an array of #ColorStop. The stops must be numbers in an ordered range.
       */
    palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired).isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }).isRequired, PropTypes.shape({
    /**
       *  For storing unique value of item
       */
    value: PropTypes.string.isRequired,

    /**
       *  The name of your palette
       */
    title: PropTypes.string,

    /**
       * `gradient`: each color fades into the next
       */
    type: PropTypes.oneOf(["gradient"]).isRequired,

    /**
       * Array of color `strings` or an array of #ColorStop. The stops must be numbers in an ordered range.
       */
    palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired).isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }).isRequired]).isRequired).isRequired
};

try {
  OuiColorPalettePicker.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiColorPalettePicker',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'You must pass an `onChange` function to handle the update of the value',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(value: string) => void'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Controls whether the options are shown. Default: false',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select_control.tsx',
          name: 'OuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      valueOfSelected: {
        defaultValue: null,
        description: '',
        name: 'valueOfSelected',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      itemClassName: {
        defaultValue: null,
        description: 'Classes for the context menu item',
        name: 'itemClassName',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      popoverClassName: {
        defaultValue: null,
        description: 'Applied to the outermost wrapper (popover)',
        name: 'popoverClassName',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      selectionDisplay: {
        defaultValue: {
          value: 'palette'
        },
        description: 'Specify what should be displayed after a selection: a `palette` or `title`',
        name: 'selectionDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/color_picker/color_palette_picker/color_palette_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"title" | "palette"',
          value: [{
            value: '"title"'
          }, {
            value: '"palette"'
          }]
        }
      },
      palettes: {
        defaultValue: null,
        description: 'An array of one of the following objects: #OuiColorPalettePickerPaletteText, #OuiColorPalettePickerPaletteFixed, #OuiColorPalettePickerPaletteGradient',
        name: 'palettes',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/color_picker/color_palette_picker/color_palette_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'OuiColorPalettePickerPaletteProps[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'OuiSuperSelectControlProps', 'HTMLAttributes', 'ButtonHTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}