/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { Component } from 'react';
export class OuiObserver extends Component {
  name = 'OuiObserver';
  childNode = null;
  observer = null;

  componentDidMount() {
    if (this.childNode == null) {
      throw new Error(`${this.name} did not receive a ref`);
    }
  }

  componentWillUnmount() {
    if (this.observer != null) {
      this.observer.disconnect();
    }
  }

  updateChildNode = ref => {
    if (this.childNode === ref) return; // node hasn't changed
    // if there's an existing observer disconnect it

    if (this.observer != null) {
      this.observer.disconnect();
      this.observer = null;
    }

    this.childNode = ref;

    if (this.childNode != null) {
      this.beginObserve();
    }
  };
  beginObserve = () => {
    throw new Error('OuiObserver has no default observation method');
  };

  render() {
    const props = this.props;
    return props.children(this.updateChildNode);
  }

}

try {
  OuiObserver.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiObserver',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/src/components/observer/observer.ts',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'docs/src/components/observer/observer.ts',
          name: 'BaseProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(ref: any) => ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}