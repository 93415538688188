/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { resolveWidthAsStyle } from './utils';
export var OuiTableHeaderCellCheckbox = ({
  children,
  className,
  scope = 'col',
  style,
  width,
  ...rest
}) => {
  const classes = classNames('ouiTableHeaderCellCheckbox', className);
  const styleObj = resolveWidthAsStyle(style, width);
  return <th className={classes} scope={scope} style={styleObj} {...rest}>
      <div className="ouiTableCellContent">{children}</div>
    </th>;
};
OuiTableHeaderCellCheckbox.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  scope: PropTypes.oneOf(["col", "row", "colgroup", "rowgroup"])
};

try {
  OuiTableHeaderCellCheckbox.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTableHeaderCellCheckbox',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      width: {
        defaultValue: null,
        description: '',
        name: 'width',
        parent: {
          fileName: 'docs/src/components/table/table_header_cell_checkbox.tsx',
          name: 'OuiTableHeaderCellCheckboxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/table_header_cell_checkbox.tsx',
          name: 'OuiTableHeaderCellCheckboxProps'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'ThHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiTableHeaderCellCheckboxProps']
  };
} catch (e) {}