/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiIcon } from '../icon';
import { OuiI18n } from '../i18n';
var statusToClassNameMap = {
  complete: 'ouiTourStepIndicator--complete',
  incomplete: 'ouiTourStepIndicator--incomplete',
  active: 'ouiTourStepIndicator--active'
};
export var STATUS = keysOf(statusToClassNameMap);
export var OuiTourStepIndicator = ({
  className,
  number,
  status,
  ...rest
}) => {
  const classes = classNames('ouiTourStepIndicator', status ? statusToClassNameMap[status] : undefined, className);
  let indicatorIcon;

  if (status === 'active') {
    indicatorIcon = <OuiI18n token="ouiTourStepIndicator.isActive" default="active">
        {isActive => <OuiIcon type="dot" className="ouiStepNumber__icon" aria-label={isActive} color="success" aria-current="step" />}
      </OuiI18n>;
  } else if (status === 'complete') {
    indicatorIcon = <OuiI18n token="ouiTourStepIndicator.isComplete" default="complete">
        {isComplete => <OuiIcon type="dot" className="ouiStepNumber__icon" aria-label={isComplete} color="subdued" />}
      </OuiI18n>;
  } else if (status === 'incomplete') {
    indicatorIcon = <OuiI18n token="ouiTourStepIndicator.isIncomplete" default="incomplete">
        {isIncomplete => <OuiIcon type="dot" className="ouiStepNumber__icon" aria-label={isIncomplete} color="subdued" />}
      </OuiI18n>;
  }

  return <OuiI18n token="ouiTourStepIndicator.ariaLabel" default="Step {number} {status}" values={{
    status,
    number
  }}>
      {ariaLabel => <li className={classes} aria-label={ariaLabel} {...rest}>
          {indicatorIcon}
        </li>}
    </OuiI18n>;
};
OuiTourStepIndicator.propTypes = {
  number: PropTypes.number.isRequired,
  status: PropTypes.oneOf(["complete", "incomplete", "active"]).isRequired,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiTourStepIndicator.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTourStepIndicator',
    methods: [],
    props: {
      number: {
        defaultValue: null,
        description: '',
        name: 'number',
        parent: {
          fileName: 'docs/src/components/tour/tour_step_indicator.tsx',
          name: 'OuiTourStepIndicatorProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step_indicator.tsx',
          name: 'OuiTourStepIndicatorProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      status: {
        defaultValue: null,
        description: '',
        name: 'status',
        parent: {
          fileName: 'docs/src/components/tour/tour_step_indicator.tsx',
          name: 'OuiTourStepIndicatorProps'
        },
        declarations: [{
          fileName: 'docs/src/components/tour/tour_step_indicator.tsx',
          name: 'OuiTourStepIndicatorProps'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"complete" | "incomplete" | "active"',
          value: [{
            value: '"complete"'
          }, {
            value: '"incomplete"'
          }, {
            value: '"active"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiTourStepIndicatorProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}