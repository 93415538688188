/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
export var OuiTreeViewNode = () => <div />;
OuiTreeViewNode.propTypes = {
  /** An array of OuiTreeViewNodes to render as children
     */
  children: PropTypes.arrayOf(PropTypes.shape({
    /** An array of OuiTreeViewNodes to render as children
       */
    children: PropTypes.arrayOf(PropTypes.any.isRequired),

    /** The readable label for the item
       */
    label: PropTypes.node.isRequired,

    /** A unique ID
       */
    id: PropTypes.string.isRequired,

    /** An icon to use on the left of the label
       */
    icon: PropTypes.element,

    /** Display a different icon when the item is expanded.
      For instance, an open folder or a down arrow
      */
    iconWhenExpanded: PropTypes.element,

    /** Use an empty icon to keep items without an icon
      lined up with their siblings
      */
    useEmptyIcon: PropTypes.bool,

    /** Whether or not the item is expanded.
       */
    isExpanded: PropTypes.bool,

    /** Optional class to throw on the node
       */
    className: PropTypes.string,

    /** Function to call when the item is clicked.
       The open state of the item will always be toggled.
       */
    callback: PropTypes.func
  }).isRequired),

  /** The readable label for the item
     */
  label: PropTypes.node.isRequired,

  /** A unique ID
     */
  id: PropTypes.string.isRequired,

  /** An icon to use on the left of the label
     */
  icon: PropTypes.element,

  /** Display a different icon when the item is expanded.
    For instance, an open folder or a down arrow
    */
  iconWhenExpanded: PropTypes.element,

  /** Use an empty icon to keep items without an icon
    lined up with their siblings
    */
  useEmptyIcon: PropTypes.bool,

  /** Whether or not the item is expanded.
     */
  isExpanded: PropTypes.bool,

  /** Optional class to throw on the node
     */
  className: PropTypes.string,

  /** Function to call when the item is clicked.
     The open state of the item will always be toggled.
     */
  callback: PropTypes.func
};

try {
  OuiTreeViewNode.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTreeViewNode',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: 'An array of OuiTreeViewNodes to render as children',
        name: 'children',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Node[]'
        }
      },
      label: {
        defaultValue: null,
        description: 'The readable label for the item',
        name: 'label',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: true,
        type: {
          name: 'any'
        }
      },
      id: {
        defaultValue: null,
        description: 'A unique ID',
        name: 'id',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      icon: {
        defaultValue: null,
        description: 'An icon to use on the left of the label',
        name: 'icon',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      iconWhenExpanded: {
        defaultValue: null,
        description: 'Display a different icon when the item is expanded.\n' + 'For instance, an open folder or a down arrow',
        name: 'iconWhenExpanded',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      useEmptyIcon: {
        defaultValue: null,
        description: 'Use an empty icon to keep items without an icon\n' + 'lined up with their siblings',
        name: 'useEmptyIcon',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isExpanded: {
        defaultValue: null,
        description: 'Whether or not the item is expanded.',
        name: 'isExpanded',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: 'Optional class to throw on the node',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      callback: {
        defaultValue: null,
        description: 'Function to call when the item is clicked.\n' + 'The open state of the item will always be toggled.',
        name: 'callback',
        parent: {
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'docs/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: '() => string'
        }
      }
    },
    extendedInterfaces: ['Node']
  };
} catch (e) {}