export var ouiZLevel0 = 0
export var ouiZLevel1 = 1000
export var ouiZLevel2 = 2000
export var ouiZLevel3 = 3000
export var ouiZLevel4 = 4000
export var ouiZLevel5 = 5000
export var ouiZLevel6 = 6000
export var ouiZLevel7 = 7000
export var ouiZLevel8 = 8000
export var ouiZLevel9 = 9000
export var ouiZToastList = 9000
export var ouiZModal = 8000
export var ouiZMask = 6000
export var ouiZNavigation = 6000
export var ouiZContentMenu = 2000
export var ouiZHeader = 1000
export var ouiZFlyout = 1000
export var ouiZMaskBelowHeader = 1000
export var ouiZContent = 0
export var euiZLevel0 = 0
export var euiZLevel1 = 1000
export var euiZLevel2 = 2000
export var euiZLevel3 = 3000
export var euiZLevel4 = 4000
export var euiZLevel5 = 5000
export var euiZLevel6 = 6000
export var euiZLevel7 = 7000
export var euiZLevel8 = 8000
export var euiZLevel9 = 9000
export var euiZToastList = 9000
export var euiZModal = 8000
export var euiZMask = 6000
export var euiZNavigation = 6000
export var euiZContentMenu = 2000
export var euiZHeader = 1000
export var euiZFlyout = 1000
export var euiZMaskBelowHeader = 1000
export var euiZContent = 0
export default {"ouiZLevel0":0,"ouiZLevel1":1000,"ouiZLevel2":2000,"ouiZLevel3":3000,"ouiZLevel4":4000,"ouiZLevel5":5000,"ouiZLevel6":6000,"ouiZLevel7":7000,"ouiZLevel8":8000,"ouiZLevel9":9000,"ouiZToastList":9000,"ouiZModal":8000,"ouiZMask":6000,"ouiZNavigation":6000,"ouiZContentMenu":2000,"ouiZHeader":1000,"ouiZFlyout":1000,"ouiZMaskBelowHeader":1000,"ouiZContent":0,"euiZLevel0":0,"euiZLevel1":1000,"euiZLevel2":2000,"euiZLevel3":3000,"euiZLevel4":4000,"euiZLevel5":5000,"euiZLevel6":6000,"euiZLevel7":7000,"euiZLevel8":8000,"euiZLevel9":9000,"euiZToastList":9000,"euiZModal":8000,"euiZMask":6000,"euiZNavigation":6000,"euiZContentMenu":2000,"euiZHeader":1000,"euiZFlyout":1000,"euiZMaskBelowHeader":1000,"euiZContent":0}
