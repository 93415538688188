/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import range from 'lodash/range';
import { isEvenlyDivisibleBy } from '../../../services';
import { OuiRangeLevels, LEVEL_COLORS } from './range_levels';
import { OuiRangeTicks } from './range_ticks';
export { LEVEL_COLORS };
export class OuiRangeTrack extends Component {
  validateValueIsInStep = value => {
    if (value < this.props.min) {
      throw new Error(`The value of ${value} is lower than the min value of ${this.props.min}.`);
    }

    if (value > this.props.max) {
      throw new Error(`The value of ${value} is higher than the max value of ${this.props.max}.`);
    } // Error out if the value doesn't line up with the sequence of steps


    if (!isEvenlyDivisibleBy(value - this.props.min, this.props.step !== undefined ? this.props.step : 1)) {
      throw new Error(`The value of ${value} is not included in the possible sequence provided by the step of ${this.props.step}.`);
    } // Return the value if nothing fails


    return value;
  };
  calculateSequence = (min, max, interval) => {
    // Loop from min to max, creating adding values at each interval
    const sequence = range(min, max, interval); // range is non-inclusive of max, so make it inclusive

    if (max % interval === 0 && !sequence.includes(max)) {
      sequence.push(max);
    }

    return sequence;
  };
  calculateTicks = (min, max, step, tickInterval, customTicks) => {
    let ticks;

    if (customTicks) {
      // If custom values were passed, use those for the sequence
      // But make sure they align with the possible sequence
      ticks = customTicks.map(tick => {
        return this.validateValueIsInStep(tick.value);
      });
    } else {
      // If a custom interval was passed, use those for the sequence
      // But make sure they align with the possible sequence
      const interval = tickInterval || step;
      const tickSequence = this.calculateSequence(min, max, interval);
      ticks = tickSequence.map(tick => {
        return this.validateValueIsInStep(tick);
      });
    } // Error out if there are too many ticks to render


    if (ticks.length > 20) {
      throw new Error(`The number of ticks to render is too high (${ticks.length}), reduce the interval.`);
    }

    return ticks;
  };

  render() {
    const {
      children,
      disabled,
      max,
      min,
      step,
      showTicks,
      tickInterval,
      ticks,
      levels,
      onChange,
      value,
      compressed,
      ...rest
    } = this.props; // TODO: Move these to only re-calculate if no-value props have changed

    this.validateValueIsInStep(max);
    const tickSequence = showTicks === true && this.calculateTicks(min, max, step, tickInterval, ticks);
    const trackClasses = classNames('ouiRangeTrack', {
      'ouiRangeTrack--disabled': disabled,
      'ouiRangeTrack--hasLevels': levels && !!levels.length,
      'ouiRangeTrack--hasTicks': tickSequence || ticks,
      'ouiRangeTrack--compressed': compressed
    });
    return <div className={trackClasses} {...rest}>
        {levels && !!levels.length && <OuiRangeLevels compressed={compressed} levels={levels} max={max} min={min} showTicks={showTicks} />}
        {tickSequence && <OuiRangeTicks disabled={disabled} compressed={compressed} onChange={onChange} ticks={ticks} tickSequence={tickSequence} value={value} min={min} max={max} interval={tickInterval || step} />}
        {children}
      </div>;
  }

}
OuiRangeTrack.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired).isRequired]),
  compressed: PropTypes.bool,
  disabled: PropTypes.bool,
  showTicks: PropTypes.bool,
  tickInterval: PropTypes.number,
  ticks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired
  }).isRequired),
  onChange: PropTypes.func,
  levels: PropTypes.arrayOf(PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    color: PropTypes.oneOf(["primary", "success", "warning", "danger"]).isRequired
  }).isRequired)
};

try {
  OuiRangeTrack.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeTrack',
    methods: [],
    props: {
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      step: {
        defaultValue: null,
        description: '',
        name: 'step',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'string | number | ReactText[]'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: '',
        name: 'tickInterval',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      ticks: {
        defaultValue: null,
        description: '',
        name: 'ticks',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeTick[]'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      levels: {
        defaultValue: null,
        description: '',
        name: 'levels',
        parent: {
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_track.tsx',
          name: 'OuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'OuiRangeLevel[]'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiRangeTrackProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}