/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Fragment, cloneElement, useContext } from 'react';
import PropTypes from "prop-types";
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { OuiDroppableContext } from './droppable';
var spacingToClassNameMap = {
  none: null,
  s: 'ouiDraggable--s',
  m: 'ouiDraggable--m',
  l: 'ouiDraggable--l'
};
export var OuiDraggable = ({
  customDragHandle = false,
  draggableId,
  isDragDisabled = false,
  isRemovable = false,
  index,
  children,
  className,
  spacing = 'none',
  style,
  'data-test-subj': dataTestSubj = 'draggable',
  ...rest
}) => {
  const {
    cloneItems
  } = useContext(OuiDroppableContext);
  return <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled} {...rest}>
      {(provided, snapshot, rubric) => {
      const classes = classNames('ouiDraggable', {
        'ouiDraggable--hasClone': cloneItems,
        'ouiDraggable--hasCustomDragHandle': customDragHandle,
        'ouiDraggable--isDragging': snapshot.isDragging,
        'ouiDraggable--withoutDropAnimation': isRemovable
      }, spacingToClassNameMap[spacing], className);
      const childClasses = classNames('ouiDraggable__item', {
        'ouiDraggable__item--hasCustomDragHandle': customDragHandle,
        'ouiDraggable__item--isDisabled': isDragDisabled,
        'ouiDraggable__item--isDragging': snapshot.isDragging,
        'ouiDraggable__item--isDropAnimating': snapshot.isDropAnimating
      });
      const DraggableElement = typeof children === 'function' ? children(provided, snapshot, rubric) : children; // as specified by `DraggableProps`

      return <Fragment>
            <div {...provided.draggableProps} {...!customDragHandle ? provided.dragHandleProps : {}} ref={provided.innerRef} data-test-subj={dataTestSubj} className={classes} style={{ ...style,
          ...provided.draggableProps.style
        }}>
              {cloneElement(DraggableElement, {
            className: classNames(DraggableElement.props.className, childClasses)
          })}
            </div>
            {cloneItems && snapshot.isDragging && <div className={classNames(classes, 'ouiDraggable--clone')}>
                {DraggableElement}
              </div>}
          </Fragment>;
    }}
    </Draggable>;
};
OuiDraggable.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.any.isRequired]).isRequired,
  className: PropTypes.string,

  /**
     * Whether the `children` will provide and set up its own drag handle
     */
  customDragHandle: PropTypes.bool,

  /**
     * Whether the item is currently in a position to be removed
     */
  isRemovable: PropTypes.bool,

  /**
     * Adds padding to the draggable item
     */
  spacing: PropTypes.oneOf(["none", "s", "m", "l"]),
  style: PropTypes.any,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiDraggable.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiDraggable',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement | DraggableChildrenFn'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      customDragHandle: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether the `children` will provide and set up its own drag handle',
        name: 'customDragHandle',
        parent: {
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isRemovable: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether the item is currently in a position to be removed',
        name: 'isRemovable',
        parent: {
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      spacing: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adds padding to the draggable item',
        name: 'spacing',
        parent: {
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        },
        declarations: [{
          fileName: 'docs/src/components/drag_and_drop/draggable.tsx',
          name: 'OuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      draggableId: {
        defaultValue: null,
        description: '',
        name: 'draggableId',
        parent: {
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      isDragDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isDragDisabled',
        parent: {
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disableInteractiveElementBlocking: {
        defaultValue: null,
        description: '',
        name: 'disableInteractiveElementBlocking',
        parent: {
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      shouldRespectForcePress: {
        defaultValue: null,
        description: '',
        name: 'shouldRespectForcePress',
        parent: {
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react-beautiful-dnd/index.d.ts',
          name: 'DraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiDraggableProps', 'CommonProps']
  };
} catch (e) {}