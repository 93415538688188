/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
export var panelPaddingValues = {
  none: 0,
  s: 8,
  m: 16,
  l: 24
};
var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiPanel--paddingSmall',
  m: 'ouiPanel--paddingMedium',
  l: 'ouiPanel--paddingLarge'
};
export var SIZES = keysOf(paddingSizeToClassNameMap);
var borderRadiusToClassNameMap = {
  none: 'ouiPanel--borderRadiusNone',
  m: 'ouiPanel--borderRadiusMedium'
};
export var BORDER_RADII = keysOf(borderRadiusToClassNameMap);
export var COLORS = ['transparent', 'plain', 'subdued', 'accent', 'primary', 'success', 'warning', 'danger'];
export var OuiPanel = ({
  children,
  className,
  paddingSize = 'm',
  borderRadius = 'm',
  color = 'plain',
  hasShadow = true,
  hasBorder,
  grow = true,
  panelRef,
  element,
  ...rest
}) => {
  // Shadows are only allowed when there's a white background (plain)
  const canHaveShadow = color === 'plain';
  const canHaveBorder = color === 'plain' || color === 'transparent';
  const classes = classNames('ouiPanel', paddingSizeToClassNameMap[paddingSize], borderRadiusToClassNameMap[borderRadius], `ouiPanel--${color}`, {
    /* ToDo: Remove unnecessary logic: the old beta theme has
     * evolved and the statement below about the `Next` theme
     * is not valid.
     */
    // The `no` classes turn off the option for default theme
    // While the `has` classes turn it on for Next
    'ouiPanel--hasShadow': canHaveShadow && hasShadow === true,
    'ouiPanel--noShadow': !canHaveShadow || hasShadow === false,
    'ouiPanel--hasBorder': canHaveBorder && hasBorder === true,
    'ouiPanel--noBorder': !canHaveBorder || hasBorder === false,
    'ouiPanel--flexGrowZero': !grow,
    'ouiPanel--isClickable': rest.onClick
  }, className);

  if (rest.onClick && element !== 'div') {
    return <button ref={panelRef} className={classes} {...rest}>
        {children}
      </button>;
  }

  return <div ref={panelRef} className={classes} {...rest}>
      {children}
    </div>;
};
OuiPanel.propTypes = {
  element: PropTypes.oneOfType([PropTypes.oneOf(["button"]), PropTypes.oneOf(["div"])]),

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     * Default is `undefined` and will default to that theme's panel style
     */

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     * Default is `undefined` and will default to that theme's panel style
     */
  hasBorder: PropTypes.bool,

  /**
     * Padding for all four sides
     */

  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,

  /**
     * Corner border radius
     */

  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,

  /**
     * When true the panel will grow in height to match `OuiFlexItem`
     */

  /**
     * When true the panel will grow in height to match `OuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiPanel.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPanel',
    methods: [],
    props: {
      element: {
        defaultValue: null,
        description: '',
        name: 'element',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelDivlike'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelDivlike'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelButtonlike'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "div"',
          value: [{
            value: '"button"'
          }, {
            value: '"div"'
          }]
        }
      },
      hasShadow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: null,
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`\n' + "Default is `undefined` and will default to that theme's panel style",
        name: 'hasBorder',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      borderRadius: {
        defaultValue: {
          value: 'm'
        },
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"m" | "none"',
          value: [{
            value: '"m"'
          }, {
            value: '"none"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'When true the panel will grow in height to match `OuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      color: {
        defaultValue: {
          value: 'plain'
        },
        description: 'Background color of the panel;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"subdued" | "success" | "accent" | "danger" | "warning" | "primary" | "transparent" | "plain"',
          value: [{
            value: '"subdued"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"danger"'
          }, {
            value: '"warning"'
          }, {
            value: '"primary"'
          }, {
            value: '"transparent"'
          }, {
            value: '"plain"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', '_OuiPanelDivlike', '_OuiPanelProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}