/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiRangeTooltip = ({
  value,
  valueAppend,
  valuePrepend,
  max,
  min,
  name,
  showTicks,
  compressed
}) => {
  const classes = classNames('ouiRangeTooltip', {
    'ouiRangeTooltip--compressed': compressed
  }); // Calculate the left position based on value

  let val = 0;

  if (typeof value === 'number') {
    val = value;
  } else if (typeof value === 'string') {
    val = parseFloat(value);
  }

  const decimal = (val - min) / (max - min); // Must be between 0-100%

  let valuePosition = decimal <= 1 ? decimal : 1;
  valuePosition = valuePosition >= 0 ? valuePosition : 0;
  let valuePositionSide;
  let valuePositionStyle;

  if (valuePosition > 0.5) {
    valuePositionSide = 'left';
    valuePositionStyle = {
      right: `${(1 - valuePosition) * 100}%`
    };
  } else {
    valuePositionSide = 'right';
    valuePositionStyle = {
      left: `${valuePosition * 100}%`
    };
  } // Change left/right position based on value (half way point)


  const valueClasses = classNames('ouiRangeTooltip__value', `ouiRangeTooltip__value--${valuePositionSide}`, {
    'ouiRangeTooltip__value--hasTicks': showTicks
  });
  return <div className={classes}>
      <output className={valueClasses} htmlFor={name} style={valuePositionStyle}>
        {valuePrepend}
        {value}
        {valueAppend}
      </output>
    </div>;
};
OuiRangeTooltip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  valueAppend: PropTypes.node,
  valuePrepend: PropTypes.node,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  name: PropTypes.string,
  showTicks: PropTypes.bool,
  compressed: PropTypes.bool
};

try {
  OuiRangeTooltip.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiRangeTooltip',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      valueAppend: {
        defaultValue: null,
        description: '',
        name: 'valueAppend',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      valuePrepend: {
        defaultValue: null,
        description: '',
        name: 'valuePrepend',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      showTicks: {
        defaultValue: null,
        description: '',
        name: 'showTicks',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/range/range_tooltip.tsx',
          name: 'OuiRangeTooltipProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiRangeTooltipProps']
  };
} catch (e) {}