/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../common';
import { OuiIcon } from '../icon';
import { OuiFlexGroup, OuiFlexItem } from '../flex';
var sizeToClassNameMap = {
  xs: 'ouiHealth--textSizeXS',
  s: 'ouiHealth--textSizeS',
  m: 'ouiHealth--textSizeM',
  inherit: 'ouiHealth--textSizeInherit'
};
export var TEXT_SIZES = keysOf(sizeToClassNameMap);
export var OuiHealth = ({
  children,
  className,
  color,
  textSize = 's',
  ...rest
}) => {
  const classes = classNames('ouiHealth', textSize ? sizeToClassNameMap[textSize] : null, className);
  return <div className={classes} {...rest}>
      <OuiFlexGroup gutterSize="xs" alignItems="center" responsive={false}>
        <OuiFlexItem grow={false}>
          <OuiIcon type="dot" color={color} />
        </OuiFlexItem>
        <OuiFlexItem grow={false}>{children}</OuiFlexItem>
      </OuiFlexGroup>
    </div>;
};
OuiHealth.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Sets the color of the dot icon.
       * It accepts any `IconColor`: `default`, `primary`, `success`, `accent`, `warning`, `danger`, `text`,
       * `subdued` or `ghost`; or any valid CSS color value as a `string`
       * **`secondary` color is DEPRECATED, use `success` instead**
       */
  color: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf(["default", "primary", "secondary", "success", "accent", "warning", "danger", "text", "subdued", "ghost", "inherit"]).isRequired]),

  /**
       * Matches the text scales of OuiText.
       * The `inherit` style will get its font size from the parent element
       */
  textSize: PropTypes.any
};

try {
  OuiHealth.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiHealth',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: null,
        description: 'Sets the color of the dot icon.\n' + 'It accepts any `IconColor`: `default`, `primary`, `success`, `accent`, `warning`, `danger`, `text`,\n' + '`subdued` or `ghost`; or any valid CSS color value as a `string`\n' + '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/health/health.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      textSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Matches the text scales of OuiText.\n' + 'The `inherit` style will get its font size from the parent element',
        name: 'textSize',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/health/health.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m" | "inherit"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"inherit"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}