/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
import { setPropsForRestrictedPageWidth } from '../_restrict_width';
var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiPage--paddingSmall',
  m: 'ouiPage--paddingMedium',
  l: 'ouiPage--paddingLarge'
};
export var PADDING_SIZES = keysOf(paddingSizeToClassNameMap);
export var OuiPageContentBody = ({
  children,
  restrictWidth = false,
  paddingSize = 'none',
  style,
  className,
  ...rest
}) => {
  const {
    widthClassName,
    newStyle
  } = setPropsForRestrictedPageWidth(restrictWidth, style);
  const classes = classNames('ouiPageContentBody', paddingSizeToClassNameMap[paddingSize], {
    [`ouiPage--${widthClassName}`]: widthClassName
  }, className);
  return <div className={classes} style={newStyle || style} {...rest}>
      {children}
    </div>;
};
OuiPageContentBody.propTypes = {
  /**
     * Adjust the padding.
     * When using this setting it's best to be consistent throughout all similar usages
     */
  paddingSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1000px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired])
};

try {
  OuiPageContentBody.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPageContentBody',
    methods: [],
    props: {
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adjust the padding.\n' + "When using this setting it's best to be consistent throughout all similar usages",
        name: 'paddingSize',
        parent: {
          fileName: 'docs/src/components/page/page_content/page_content_body.tsx',
          name: 'OuiPageContentBodyProps'
        },
        declarations: [{
          fileName: 'docs/src/components/page/page_content/page_content_body.tsx',
          name: 'OuiPageContentBodyProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1000px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | number | string'
        }
      }
    },
    extendedInterfaces: ['OuiPageContentBodyProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}