/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiIcon } from '../icon';
import { OuiI18n, useOuiI18n } from '../i18n';
import { keysOf } from '../common';
import { getSecureRelForTarget } from '../../services';
import { OuiScreenReaderOnly } from '../accessibility';
import { validateHref } from '../../services/security/href_validator';
var colorsToClassNameMap = {
  primary: 'ouiLink--primary',
  subdued: 'ouiLink--subdued',
  secondary: 'ouiLink--secondary',
  success: 'ouiLink--success',
  accent: 'ouiLink--accent',
  danger: 'ouiLink--danger',
  warning: 'ouiLink--warning',
  ghost: 'ouiLink--ghost',
  text: 'ouiLink--text'
};
export var COLORS = keysOf(colorsToClassNameMap);
var OuiLink = forwardRef(({
  children,
  color = 'primary',
  className,
  href,
  external,
  target,
  rel,
  type = 'button',
  onClick,
  disabled: _disabled,
  ...rest
}, ref) => {
  const isHrefValid = !href || validateHref(href);
  const disabled = _disabled || !isHrefValid;
  const externalLinkIcon = <OuiIcon aria-label={useOuiI18n('ouiLink.external.ariaLabel', 'External link')} size="s" className="ouiLink__externalIcon" type="popout" />;
  const newTargetScreenreaderText = <OuiScreenReaderOnly>
        <span>
          <OuiI18n token="ouiLink.newTarget.screenReaderOnlyText" default="(opens in a new tab or window)" />
        </span>
      </OuiScreenReaderOnly>;

  if (href === undefined || !isHrefValid) {
    const buttonProps = {
      className: classNames('ouiLink', disabled ? 'ouiLink-disabled' : colorsToClassNameMap[color], className),
      type,
      onClick,
      disabled,
      ...rest
    };
    return <button ref={ref} {...buttonProps}>
          {children}
        </button>;
  }

  const secureRel = getSecureRelForTarget({
    href,
    target,
    rel
  });
  const anchorProps = {
    className: classNames('ouiLink', colorsToClassNameMap[color], className),
    href,
    target,
    rel: secureRel,
    onClick,
    ...rest
  };
  const showExternalLinkIcon = target === '_blank' && external !== false || external === true;
  return <a ref={ref} {...anchorProps}>
        {children}
        {showExternalLinkIcon && externalLinkIcon}
        {target === '_blank' && newTargetScreenreaderText}
      </a>;
});
OuiLink.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  type: PropTypes.oneOf(["button", "reset", "submit"]),

  /**
     * Any of our named colors.
     * **`secondary` color is DEPRECATED, use `success` instead**
     */

  /**
     * Any of our named colors.
     * **`secondary` color is DEPRECATED, use `success` instead**
     */
  color: PropTypes.oneOf(["primary", "subdued", "secondary", "success", "accent", "danger", "warning", "text", "ghost"]),
  onClick: PropTypes.func,

  /**
     * Set to true to show an icon indicating that it is an external link;
     * Defaults to true if `target="_blank"`
     */
  external: PropTypes.bool
};
OuiLink.displayName = 'OuiLink';
export { OuiLink };

try {
  OuiLink.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiLink',
    methods: [],
    props: {
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'OuiLinkAnchorProps'
        },
        declarations: [{
          fileName: 'docs/src/components/link/link.tsx',
          name: 'OuiLinkAnchorProps'
        }, {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkButtonProps'
        }],
        required: false,
        type: {
          name: '((event: MouseEvent<HTMLButtonElement, MouseEvent>) => void) | ((event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void)'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      type: {
        defaultValue: {
          value: 'button'
        },
        description: '',
        name: 'type',
        parent: {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        },
        declarations: [{
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        }, {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkButtonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'primary'
        },
        description: 'Any of our named colors.\n' + '**`secondary` color is DEPRECATED, use `success` instead**',
        name: 'color',
        parent: {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        },
        declarations: [{
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        }, {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkButtonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'OuiLinkColor',
          value: [{
            value: '"text"'
          }, {
            value: '"subdued"'
          }, {
            value: '"secondary"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"danger"'
          }, {
            value: '"warning"'
          }, {
            value: '"ghost"'
          }, {
            value: '"primary"'
          }]
        }
      },
      external: {
        defaultValue: null,
        description: 'Set to true to show an icon indicating that it is an external link;\n' + 'Defaults to true if `target="_blank"`',
        name: 'external',
        parent: {
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        },
        declarations: [{
          fileName: 'docs/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement | HTMLAnchorElement>'
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', 'OuiLinkAnchorProps', 'CommonProps', 'AnchorHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'LinkAnchorProps', 'Attributes']
  };
} catch (e) {}