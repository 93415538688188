/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiContextMenuItem } from '../../context_menu';
export var OuiTableSortMobileItem = ({
  children,
  onSort,
  isSorted,
  isSortAscending,
  className,
  ariaLabel,
  ...rest
}) => {
  let sortIcon = 'empty';

  if (isSorted) {
    sortIcon = isSortAscending ? 'sortUp' : 'sortDown';
  }

  const buttonClasses = classNames('ouiTableSortMobileItem', className, {
    'ouiTableSortMobileItem-isSorted': isSorted
  });
  const columnTitle = ariaLabel ? ariaLabel : children;
  const statefulAriaLabel = `Sort ${columnTitle} ${isSortAscending ? 'descending' : 'ascending'}`;
  return <OuiContextMenuItem className={buttonClasses} icon={sortIcon} onClick={onSort} aria-label={statefulAriaLabel} {...rest}>
      {children}
    </OuiContextMenuItem>;
};
OuiTableSortMobileItem.propTypes = {
  /**
     * Callback to know when an item has been clicked
     */
  onSort: PropTypes.func,

  /**
     * Indicates current option is the sorted on column
     */
  isSorted: PropTypes.bool,

  /**
     * Indicates which direction the current column is sorted on
     */
  isSortAscending: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiTableSortMobileItem.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiTableSortMobileItem',
    methods: [],
    props: {
      onSort: {
        defaultValue: null,
        description: 'Callback to know when an item has been clicked',
        name: 'onSort',
        parent: {
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      isSorted: {
        defaultValue: null,
        description: 'Indicates current option is the sorted on column',
        name: 'isSorted',
        parent: {
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSortAscending: {
        defaultValue: null,
        description: 'Indicates which direction the current column is sorted on',
        name: 'isSortAscending',
        parent: {
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ariaLabel: {
        defaultValue: null,
        description: '',
        name: 'ariaLabel',
        parent: {
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'docs/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'OuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiTableSortMobileItemProps', 'CommonProps']
  };
} catch (e) {}