/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { htmlIdGenerator } from '../../../services/accessibility';
import { OuiIcon } from '../../icon';
export var OuiSwitch = ({
  label,
  id,
  checked,
  disabled,
  compressed,
  onChange,
  className,
  showLabel = true,
  type = 'button',
  labelProps,
  ...rest
}) => {
  const [switchId] = useState(id || htmlIdGenerator()());
  const [labelId] = useState(labelProps?.id || htmlIdGenerator()());
  const onClick = useCallback(e => {
    if (disabled) {
      return;
    }

    const event = e;
    event.target.checked = !checked;
    onChange(event);
  }, [checked, disabled, onChange]);
  const classes = classNames('ouiSwitch', {
    'ouiSwitch--compressed': compressed
  }, className);
  const labelClasses = classNames('ouiSwitch__label', labelProps?.className);

  if (showLabel === false && typeof label !== 'string') {
    console.warn('OuiSwitch `label` must be a string when `showLabel` is false.');
  }

  return <div className={classes}>
      <button id={switchId} aria-checked={checked || false} className="ouiSwitch__button" role="switch" type={type} disabled={disabled} onClick={onClick} aria-label={showLabel ? undefined : label} aria-labelledby={showLabel ? labelId : undefined} {...rest}>
        <span className="ouiSwitch__body">
          <span className="ouiSwitch__thumb" />
          <span className="ouiSwitch__track">
            {!compressed && <React.Fragment>
                <OuiIcon type="cross" size="m" className="ouiSwitch__icon" />

                <OuiIcon type="check" size="m" className="ouiSwitch__icon ouiSwitch__icon--checked" />
              </React.Fragment>}
          </span>
        </span>
      </button>

      {showLabel && // <button> + <label> has poor screen reader support.
    // Click handler added to simulate natural, secondary <label> interactivity.
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <span {...labelProps} className={labelClasses} id={labelId} onClick={onClick}>
          {label}
        </span>}
    </div>;
};
OuiSwitch.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Whether to render the render the text label
       */
  showLabel: PropTypes.bool,

  /**
       * Must be a string if `showLabel` prop is false
       */
  label: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  compressed: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "reset", "button"]),

  /**
       * Object of props passed to the label's <span/>
       */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  })
};

try {
  OuiSwitch.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSwitch',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      showLabel: {
        defaultValue: {
          value: 'true'
        },
        description: 'Whether to render the render the text label',
        name: 'showLabel',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      label: {
        defaultValue: null,
        description: 'Must be a string if `showLabel` prop is false',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(event: any) => void'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      type: {
        defaultValue: {
          value: 'button'
        },
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      labelProps: {
        defaultValue: null,
        description: "Object of props passed to the label's <span/>",
        name: 'labelProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}