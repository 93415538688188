/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
var colorToClassMap = {
  accent: null,
  subdued: 'ouiNotificationBadge--subdued'
};
export var COLORS = keysOf(colorToClassMap);
var sizeToClassNameMap = {
  s: null,
  m: 'ouiNotificationBadge--medium'
};
export var SIZES = keysOf(sizeToClassNameMap);
export var OuiNotificationBadge = ({
  children,
  className,
  size = 's',
  color = 'accent',
  ...rest
}) => {
  const classes = classNames('ouiNotificationBadge', sizeToClassNameMap[size], colorToClassMap[color], className);
  return <span className={classes} {...rest}>
      {children}
    </span>;
};
OuiNotificationBadge.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["s", "m"]),
  color: PropTypes.oneOf(["accent", "subdued"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiNotificationBadge.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiNotificationBadge',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'OuiNotificationBadgeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'OuiNotificationBadgeProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      size: {
        defaultValue: {
          value: 's'
        },
        description: '',
        name: 'size',
        parent: {
          fileName: 'docs/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'OuiNotificationBadgeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'OuiNotificationBadgeProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'Size',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'accent'
        },
        description: '',
        name: 'color',
        parent: {
          fileName: 'docs/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'OuiNotificationBadgeProps'
        },
        declarations: [{
          fileName: 'docs/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'OuiNotificationBadgeProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"subdued" | "accent"',
          value: [{
            value: '"subdued"'
          }, {
            value: '"accent"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiNotificationBadgeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}