/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import classNames from 'classnames';
import PropTypes from "prop-types";
import React, { createElement } from 'react';
import { OuiTitle } from '../title';
import { OuiStepNumber } from './step_number';
export var OuiStep = ({
  className,
  children,
  headingElement = 'p',
  step = 1,
  title,
  titleSize = 's',
  status,
  ...rest
}) => {
  const classes = classNames('ouiStep', {
    'ouiStep--small': titleSize === 'xs',
    'ouiStep-isDisabled': status === 'disabled'
  }, className);
  const numberClasses = classNames('ouiStep__circle', {
    'ouiStepNumber--small': titleSize === 'xs'
  });
  return <div className={classes} {...rest}>
      <div className="ouiStep__titleWrapper">
        <OuiStepNumber className={numberClasses} number={step} status={status} titleSize={titleSize} isHollow={status === 'incomplete'} />
        <OuiTitle size={titleSize} className="ouiStep__title">
          {createElement(headingElement, null, title)}
        </OuiTitle>
      </div>

      <div className="ouiStep__content">{children}</div>
    </div>;
};
OuiStep.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,

  /**
     * The HTML tag used for the title
     */
  headingElement: PropTypes.string,

  /**
     * The number of the step in the list of steps
     */
  step: PropTypes.number,
  title: PropTypes.string.isRequired,

  /**
     * May replace the number provided in props.step with alternate styling.
     */
  status: PropTypes.any,

  /**
     * Title sizing equivalent to OuiTitle, but only `m`, `s` and `xs`. Defaults to `s`
     */
  titleSize: PropTypes.any
};

try {
  OuiStep.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiStep',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      headingElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'The HTML tag used for the title',
        name: 'headingElement',
        parent: {
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number of the step in the list of steps',
        name: 'step',
        parent: {
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      status: {
        defaultValue: null,
        description: 'May replace the number provided in props.step with alternate styling.',
        name: 'status',
        parent: {
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"danger" | "warning" | "disabled" | "loading" | "complete" | "incomplete"',
          value: [{
            value: '"danger"'
          }, {
            value: '"warning"'
          }, {
            value: '"disabled"'
          }, {
            value: '"loading"'
          }, {
            value: '"complete"'
          }, {
            value: '"incomplete"'
          }]
        }
      },
      titleSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Title sizing equivalent to OuiTitle, but only `m`, `s` and `xs`. Defaults to `s`',
        name: 'titleSize',
        parent: {
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        },
        declarations: [{
          fileName: 'docs/src/components/steps/step.tsx',
          name: 'OuiStepInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"xs" | "s" | "m"',
          value: [{
            value: '"xs"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'OuiStepInterface']
  };
} catch (e) {}