/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiI18n } from '../i18n';
import { htmlIdGenerator } from '../../services';
import { useOuiResizableContainerContext } from './context';
var generatePanelId = htmlIdGenerator('resizable-button');
export var OuiResizableButton = ({
  isHorizontal,
  className,
  id,
  registration,
  disabled,
  onFocus,
  onBlur,
  ...rest
}) => {
  const resizerId = useRef(id || generatePanelId());
  const {
    registry: {
      resizers
    } = {
      resizers: {}
    }
  } = useOuiResizableContainerContext();
  const isDisabled = useMemo(() => disabled || resizers[resizerId.current] && resizers[resizerId.current].isDisabled, [resizers, disabled]);
  const classes = classNames('ouiResizableButton', {
    'ouiResizableButton--vertical': !isHorizontal,
    'ouiResizableButton--horizontal': isHorizontal,
    'ouiResizableButton--disabled': isDisabled
  }, className);
  const previousRef = useRef();
  const onRef = useCallback(ref => {
    if (!registration) return;
    const id = resizerId.current;

    if (ref) {
      previousRef.current = ref;
      registration.register({
        id,
        ref,
        isFocused: false,
        isDisabled: disabled || false
      });
    } else {
      if (previousRef.current != null) {
        registration.deregister(id);
        previousRef.current = undefined;
      }
    }
  }, [registration, disabled]);

  const setFocus = e => e.currentTarget.focus();

  const handleFocus = () => {
    onFocus && onFocus(resizerId.current);
  };

  return <OuiI18n tokens={['ouiResizableButton.horizontalResizerAriaLabel', 'ouiResizableButton.verticalResizerAriaLabel']} defaults={['Press left or right to adjust panels size', 'Press up or down to adjust panels size']}>
      {([horizontalResizerAriaLabel, verticalResizerAriaLabel]) => <button id={resizerId.current} ref={onRef} aria-label={isHorizontal ? horizontalResizerAriaLabel : verticalResizerAriaLabel} className={classes} data-test-subj="ouiResizableButton" type="button" onClick={setFocus} onFocus={handleFocus} onBlur={onBlur} disabled={isDisabled} {...rest} />}
    </OuiI18n>;
};
OuiResizableButton.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};
export function ouiResizableButtonWithControls(controls) {
  return props => <OuiResizableButton {...controls} {...props} />;
}

try {
  ouiResizableButtonWithControls.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'ouiResizableButtonWithControls',
    methods: [],
    props: {
      onKeyDown: {
        defaultValue: null,
        description: '',
        name: 'onKeyDown',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: '(eve: OuiResizableButtonKeyDownEvent) => void'
        }
      },
      onMouseDown: {
        defaultValue: null,
        description: '',
        name: 'onMouseDown',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: '(eve: OuiResizableButtonMouseEvent) => void'
        }
      },
      onTouchStart: {
        defaultValue: null,
        description: '',
        name: 'onTouchStart',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: '(eve: OuiResizableButtonMouseEvent) => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: '(id: string) => void'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      registration: {
        defaultValue: null,
        description: '',
        name: 'registration',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: '{ register: (resizer: OuiResizableButtonController) => void; deregister: (resizerId: string) => void; }'
        }
      },
      isHorizontal: {
        defaultValue: null,
        description: '',
        name: 'isHorizontal',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiResizableButtonControls', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}

try {
  OuiResizableButton.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiResizableButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyDown: {
        defaultValue: null,
        description: '',
        name: 'onKeyDown',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: '(eve: OuiResizableButtonKeyDownEvent) => void'
        }
      },
      onMouseDown: {
        defaultValue: null,
        description: '',
        name: 'onMouseDown',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: '(eve: OuiResizableButtonMouseEvent) => void'
        }
      },
      onTouchStart: {
        defaultValue: null,
        description: '',
        name: 'onTouchStart',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: '(eve: OuiResizableButtonMouseEvent) => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: '(id: string) => void'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      registration: {
        defaultValue: null,
        description: '',
        name: 'registration',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: '{ register: (resizer: OuiResizableButtonController) => void; deregister: (resizerId: string) => void; }'
        }
      },
      isHorizontal: {
        defaultValue: null,
        description: '',
        name: 'isHorizontal',
        parent: {
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        },
        declarations: [{
          fileName: 'docs/src/components/resizable_container/resizable_button.tsx',
          name: 'OuiResizableButtonControls'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['OuiResizableButtonControls', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}