/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiBadge } from '../../badge';
import { OuiI18n } from '../../i18n';
export class OuiComboBoxPill extends Component {
  static defaultProps = {
    color: 'hollow'
  };
  onCloseButtonClick = () => {
    const {
      onClose,
      option
    } = this.props;

    if (onClose) {
      onClose(option);
    }
  };

  render() {
    const {
      asPlainText,
      children,
      className,
      color,
      onClick,
      onClickAriaLabel,
      onClose,
      // eslint-disable-line no-unused-vars
      option,
      // eslint-disable-line no-unused-vars
      ...rest
    } = this.props;
    const classes = classNames('ouiComboBoxPill', {
      'ouiComboBoxPill--plainText': asPlainText
    }, className);
    const onClickProps = onClick && onClickAriaLabel ? {
      onClick,
      onClickAriaLabel
    } : {};

    if (onClose) {
      return <OuiI18n token="ouiComboBoxPill.removeSelection" default="Remove {children} from selection in this group" values={{
        children
      }}>
          {removeSelection => <OuiBadge className={classes} closeButtonProps={{
          tabIndex: -1
        }} color={color} iconOnClick={this.onCloseButtonClick} iconOnClickAriaLabel={removeSelection} iconSide="right" iconType="cross" title={children} {...onClickProps} {...rest}>
              {children}
            </OuiBadge>}
        </OuiI18n>;
    }

    if (asPlainText) {
      return <span className={classes} {...rest}>
          {children}
        </span>;
    }

    return <OuiBadge className={classes} color={color} title={children} {...rest} {...onClickProps}>
        {children}
      </OuiBadge>;
  }

}
OuiComboBoxPill.propTypes = {
  asPlainText: PropTypes.bool,
  children: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  onClickAriaLabel: PropTypes.any,
  onClose: PropTypes.func,
  option: PropTypes.shape({
    isGroupLabelOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    key: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      isGroupLabelOption: PropTypes.bool,
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.any.isRequired),
      value: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string
    }).isRequired),
    value: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string
  }).isRequired,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};

try {
  OuiComboBoxPill.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiComboBoxPill',
    methods: [],
    props: {
      asPlainText: {
        defaultValue: null,
        description: '',
        name: 'asPlainText',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: {
          value: 'hollow'
        },
        description: '',
        name: 'color',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void'
        }
      },
      onClickAriaLabel: {
        defaultValue: null,
        description: '',
        name: 'onClickAriaLabel',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: false,
        type: {
          name: 'OptionHandler<T>'
        }
      },
      option: {
        defaultValue: null,
        description: '',
        name: 'option',
        parent: {
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        },
        declarations: [{
          fileName: 'docs/src/components/combo_box/combo_box_input/combo_box_pill.tsx',
          name: 'OuiComboBoxPillProps'
        }],
        required: true,
        type: {
          name: 'OuiComboBoxOptionOption<T>'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiComboBoxPillProps', 'CommonProps']
  };
} catch (e) {}