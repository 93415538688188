/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiPanel } from '../../panel/panel';
var verticalPositionToClassNameMap = {
  center: 'ouiPageContent--verticalCenter'
};
var horizontalPositionToClassNameMap = {
  center: 'ouiPageContent--horizontalCenter'
};
export var OuiPageContent = ({
  verticalPosition,
  horizontalPosition,
  panelPaddingSize,
  paddingSize = 'l',
  borderRadius,
  children,
  className,
  role: _role = 'main',
  ...rest
}) => {
  const role = _role === null ? undefined : _role;
  const borderRadiusClass = borderRadius === 'none' ? 'ouiPageContent--borderRadiusNone' : '';
  const classes = classNames('ouiPageContent', borderRadiusClass, verticalPosition ? verticalPositionToClassNameMap[verticalPosition] : null, horizontalPosition ? horizontalPositionToClassNameMap[horizontalPosition] : null, className);
  return <OuiPanel className={classes} paddingSize={panelPaddingSize ?? paddingSize} borderRadius={borderRadius} role={role} {...rest}>
      {children}
    </OuiPanel>;
};
OuiPageContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     * Default is `undefined` and will default to that theme's panel style
     */

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     * Default is `undefined` and will default to that theme's panel style
     */
  hasBorder: PropTypes.bool,

  /**
     * Padding for all four sides
     */

  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,

  /**
     * Corner border radius
     */

  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,

  /**
     * When true the panel will grow in height to match `OuiFlexItem`
     */

  /**
     * When true the panel will grow in height to match `OuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  element: PropTypes.oneOf(["div"]),

  /**
       * **DEPRECATED: use `paddingSize` instead.**
       */
  panelPaddingSize: PropTypes.any,
  verticalPosition: PropTypes.oneOf(["center"]),
  horizontalPosition: PropTypes.oneOf(["center"]),

  /**
       * There should only be one OuiPageContent per page and should contain the main contents.
       * If this is untrue, set role = `null`, or change it to match your needed aria role
       */
  role: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf([null])])
};

try {
  OuiPageContent.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPageContent',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: null,
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`\n' + "Default is `undefined` and will default to that theme's panel style",
        name: 'hasBorder',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      borderRadius: {
        defaultValue: null,
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"m" | "none"',
          value: [{
            value: '"m"'
          }, {
            value: '"none"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: 'When true the panel will grow in height to match `OuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      color: {
        defaultValue: null,
        description: 'Background color of the panel;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }, {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"subdued" | "success" | "accent" | "danger" | "warning" | "primary" | "transparent" | "plain"',
          value: [{
            value: '"subdued"'
          }, {
            value: '"success"'
          }, {
            value: '"accent"'
          }, {
            value: '"danger"'
          }, {
            value: '"warning"'
          }, {
            value: '"primary"'
          }, {
            value: '"transparent"'
          }, {
            value: '"plain"'
          }]
        }
      },
      element: {
        defaultValue: null,
        description: '',
        name: 'element',
        parent: {
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelDivlike'
        },
        declarations: [{
          fileName: 'docs/src/components/panel/panel.tsx',
          name: '_OuiPanelDivlike'
        }],
        required: false,
        type: {
          name: '"div"'
        }
      },
      panelPaddingSize: {
        defaultValue: null,
        description: '**DEPRECATED: use `paddingSize` instead.**',
        name: 'panelPaddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_content/page_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      verticalPosition: {
        defaultValue: null,
        description: '',
        name: 'verticalPosition',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_content/page_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"center"'
        }
      },
      horizontalPosition: {
        defaultValue: null,
        description: '',
        name: 'horizontalPosition',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_content/page_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"center"'
        }
      },
      role: {
        defaultValue: null,
        description: 'There should only be one OuiPageContent per page and should contain the main contents.\n' + 'If this is untrue, set role = `null`, or change it to match your needed aria role',
        name: 'role',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_content/page_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['CommonProps', '_OuiPanelProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', '_OuiPanelDivlike']
  };
} catch (e) {}