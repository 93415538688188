/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/**
 * NOTE: We can't test this component because Enzyme doesn't support rendering
 * into portals.
 */
import { Component } from 'react';
import PropTypes from "prop-types";
import { createPortal } from 'react-dom';
import { keysOf } from '../common';
export var insertPositions = {
  after: 'afterend',
  before: 'beforebegin'
};
export var INSERT_POSITIONS = keysOf(insertPositions);
export class OuiPortal extends Component {
  constructor(props) {
    super(props);
    const {
      insert
    } = this.props;
    this.portalNode = document.createElement('div');

    if (insert == null) {
      // no insertion defined, append to body
      document.body.appendChild(this.portalNode);
    } else {
      // inserting before or after an element
      const {
        sibling,
        position
      } = insert;
      sibling.insertAdjacentElement(insertPositions[position], this.portalNode);
    }
  }

  componentDidMount() {
    this.updatePortalRef(this.portalNode);
  }

  componentWillUnmount() {
    if (this.portalNode.parentNode) {
      this.portalNode.parentNode.removeChild(this.portalNode);
    }

    this.updatePortalRef(null);
  }

  updatePortalRef(ref) {
    if (this.props.portalRef) {
      this.props.portalRef(ref);
    }
  }

  render() {
    return createPortal(this.props.children, this.portalNode);
  }

}
OuiPortal.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  insert: PropTypes.shape({
    sibling: PropTypes.any.isRequired,
    position: PropTypes.oneOf(["after", "before"]).isRequired
  }),
  portalRef: PropTypes.func
};

try {
  OuiPortal.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPortal',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'docs/src/components/portal/portal.tsx',
          name: 'OuiPortalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/portal/portal.tsx',
          name: 'OuiPortalProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      insert: {
        defaultValue: null,
        description: '',
        name: 'insert',
        parent: {
          fileName: 'docs/src/components/portal/portal.tsx',
          name: 'OuiPortalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/portal/portal.tsx',
          name: 'OuiPortalProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      portalRef: {
        defaultValue: null,
        description: '',
        name: 'portalRef',
        parent: {
          fileName: 'docs/src/components/portal/portal.tsx',
          name: 'OuiPortalProps'
        },
        declarations: [{
          fileName: 'docs/src/components/portal/portal.tsx',
          name: 'OuiPortalProps'
        }],
        required: false,
        type: {
          name: '(ref: HTMLDivElement) => void'
        }
      }
    },
    extendedInterfaces: ['OuiPortalProps']
  };
} catch (e) {}