/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

import { createContext, useContext } from 'react';

export const ExampleContext = createContext<{ parentPath: string }>({
  parentPath: '/',
});

export const useExitPath = () => {
  const { parentPath } = useContext(ExampleContext);
  return `/#${parentPath}`;
};
