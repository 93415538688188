/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { OuiFieldSearch } from '../form';
export class OuiSearchBox extends Component {
  static defaultProps = {
    placeholder: 'Search...',
    incremental: false
  };
  inputElement = null;

  componentDidUpdate(oldProps) {
    if (oldProps.query !== this.props.query && this.inputElement != null) {
      this.inputElement.value = this.props.query;
      this.inputElement.dispatchEvent(new Event('change'));
    }
  }

  render() {
    const {
      query,
      incremental,
      ...rest
    } = this.props;
    let ariaLabel;

    if (incremental) {
      ariaLabel = 'This is a search bar. As you type, the results lower in the page will automatically filter.';
    } else {
      ariaLabel = 'This is a search bar. After typing your query, hit enter to filter the results lower in the page.';
    }

    return <OuiFieldSearch inputRef={input => this.inputElement = input} fullWidth defaultValue={query} incremental={incremental} aria-label={ariaLabel} {...rest} />;
  }

}
OuiSearchBox.propTypes = {
  query: PropTypes.string.isRequired,
  // This is optional in OuiFieldSearchProps
  onSearch: PropTypes.func.isRequired
};

try {
  OuiSearchBox.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiSearchBox',
    methods: [],
    props: {
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'docs/src/components/search_bar/search_box.tsx',
          name: 'OuiSearchBoxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/search_box.tsx',
          name: 'OuiSearchBoxProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onSearch: {
        defaultValue: null,
        description: 'Called when the user presses [Enter] OR on change if the incremental prop is `true`.\n' + "If you don't need the on[Enter] functionality, prefer using onChange",
        name: 'onSearch',
        parent: {
          fileName: 'docs/src/components/search_bar/search_box.tsx',
          name: 'OuiSearchBoxProps'
        },
        declarations: [{
          fileName: 'docs/src/components/search_bar/search_box.tsx',
          name: 'OuiSearchBoxProps'
        }],
        required: true,
        type: {
          name: '(queryText: string) => void'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      placeholder: {
        defaultValue: {
          value: 'Search...'
        },
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      incremental: {
        defaultValue: {
          value: 'false'
        },
        description: 'When `true` the search will be executed (that is, the `onSearch` will be called) as the\n' + 'user types.',
        name: 'incremental',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      isClearable: {
        defaultValue: null,
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'docs/src/components/form/field_search/field_search.tsx',
          name: 'OuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['OuiSearchBoxProps', 'OuiFieldSearchProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}