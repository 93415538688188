/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */
import React from 'react';
import PropTypes from "prop-types";
import { OuiCodeBlock } from '../../../../../src/components/code';
import { OuiSpacer } from '../../../../../src/components/spacer';
export var GuideSectionSnippets = ({
  snippets
}) => {
  let snippetCode;

  if (typeof snippets === 'string') {
    snippetCode = <OuiCodeBlock language="html" fontSize="m" paddingSize="m" isCopyable>
        {snippets}
      </OuiCodeBlock>;
  } else {
    snippetCode = snippets.map((snip, index) => <React.Fragment key={`snippet${index}`}>
        <OuiCodeBlock language="html" fontSize="m" paddingSize="m" isCopyable>
          {snip}
        </OuiCodeBlock>
        {index < snippets.length - 1 && <OuiSpacer size="xs" />}
      </React.Fragment>);
  }

  return <React.Fragment>{snippetCode}</React.Fragment>;
};
GuideSectionSnippets.propTypes = {
  snippets: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired
};

try {
  GuideSectionSnippets.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'GuideSectionSnippets',
    methods: [],
    props: {
      snippets: {
        defaultValue: null,
        description: '',
        name: 'snippets',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src-docs/src/components/guide_section/guide_section_parts/guide_section_snippets.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string | string[]'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}