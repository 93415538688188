/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { OuiTab, OuiTabs } from '../../tabs';
import { OuiFlexGroup, OuiFlexItem } from '../../flex';
import { OuiSpacer } from '../../spacer';
import { OuiTitle } from '../../title';
import { OuiText } from '../../text';
import { useIsWithinBreakpoints } from '../../../services/hooks';
export var ALIGN_ITEMS = ['top', 'bottom', 'center', 'stretch']; // Gets all the tab props including the button or link props

export var OuiPageHeaderContent = ({
  className,
  pageTitle,
  tabs,
  tabsProps,
  description,
  alignItems = 'top',
  responsive = true,
  rightSideItems,
  rightSideGroupProps,
  children,
  ...rest
}) => {
  const isResponsiveBreakpoint = useIsWithinBreakpoints(['xs', 's'], !!responsive);
  const classes = classNames('ouiPageHeaderContent');
  let descriptionNode;

  if (description) {
    descriptionNode = <>
        {(pageTitle || tabs) && <OuiSpacer />}
        <OuiText grow={false}>
          <p>{description}</p>
        </OuiText>
      </>;
  }

  let pageTitleNode;

  if (pageTitle) {
    pageTitleNode = <OuiTitle size="l">
        <h1>{pageTitle}</h1>
      </OuiTitle>;
  }

  let tabsNode;

  if (tabs) {
    const renderTabs = () => {
      return tabs.map((tab, index) => {
        const {
          label,
          ...tabRest
        } = tab;
        return <OuiTab key={index} {...tabRest}>
            {label}
          </OuiTab>;
      });
    };

    tabsNode = <>
        {pageTitleNode && <OuiSpacer />}
        <OuiTabs {...tabsProps} display="condensed" size="l">
          {renderTabs()}
        </OuiTabs>
      </>;
  }

  const childrenNode = children && <>
      <OuiSpacer />
      {children}
    </>;
  let bottomContentNode;

  if (childrenNode || tabsNode && pageTitleNode) {
    bottomContentNode = <div className="ouiPageHeaderContent__bottom">
        {childrenNode}
        {pageTitleNode && tabsNode}
      </div>;
  }
  /**
   * The left side order depends on if a `pageTitle` was supplied.
   * If not, but there are `tabs`, then the tabs become the page title
   */


  let leftSideOrder;

  if (tabsNode && !pageTitleNode) {
    leftSideOrder = <>
        {tabsNode}
        {descriptionNode}
      </>;
  } else {
    leftSideOrder = <>
        {pageTitleNode}
        {descriptionNode}
      </>;
  }

  let rightSideFlexItem;

  if (rightSideItems && rightSideItems.length) {
    const wrapWithFlex = () => {
      return rightSideItems.map((item, index) => {
        return <OuiFlexItem grow={false} key={index}>
            {item}
          </OuiFlexItem>;
      });
    };

    rightSideFlexItem = <OuiFlexItem grow={false}>
        <OuiFlexGroup wrap responsive={false} {...rightSideGroupProps} className={classNames('ouiPageHeaderContent__rightSideItems', rightSideGroupProps?.className)}>
          {wrapWithFlex()}
        </OuiFlexGroup>
      </OuiFlexItem>;
  }

  return alignItems === 'top' || isResponsiveBreakpoint ? <div className={classes} {...rest}>
      <OuiFlexGroup responsive={!!responsive} className="ouiPageHeaderContent__top" alignItems="flexStart" gutterSize="l">
        {isResponsiveBreakpoint && responsive === 'reverse' ? <>
            {rightSideFlexItem}
            <OuiFlexItem>{leftSideOrder}</OuiFlexItem>
          </> : <>
            <OuiFlexItem>{leftSideOrder}</OuiFlexItem>
            {rightSideFlexItem}
          </>}
      </OuiFlexGroup>
      {bottomContentNode}
    </div> : <div className={classes} {...rest}>
      <OuiFlexGroup responsive={!!responsive} className="ouiPageHeaderContent__top" alignItems={alignItems === 'bottom' ? 'flexEnd' : alignItems} gutterSize="l">
        <OuiFlexItem>
          {leftSideOrder}
          {bottomContentNode}
        </OuiFlexItem>
        {rightSideFlexItem}
      </OuiFlexGroup>
    </div>;
};
OuiPageHeaderContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Wrapped in an `H1` so choose appropriately.
     * A simple string is best
     */
  pageTitle: PropTypes.node,

  /**
     * In-app navigation presented as large borderless tabs.
     * Accepts an array of `OuiTab` objects;
     * HELP: This is evaluating to `any[]` in the props table
     */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Visible text of the tab
       */
    label: PropTypes.node.isRequired
  }).isRequired),

  /**
     * Any extras to apply to the outer tabs container.
     * Extends `OuiTabs`
     */
  tabsProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,

    /**
         * ReactNode to render as this component's content
         */
    children: PropTypes.node
  }),

  /**
       * Position is dependent on existing with a `pageTitle` or `tabs`
       * Automatically get wrapped in a single paragraph tag inside an OuiText block
       */
  description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),

  /**
       * Set to false if you don't want the children to stack at small screen sizes.
       * Set to `reverse` to display the right side content first for the sack of hierarchy (like global time)
       */
  responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["reverse"])]),

  /**
       * Vertical alignment of the left and right side content;
       * Default is `middle` for custom content, but `top` for when `pageTitle` or `tabs` are included
       */
  alignItems: PropTypes.any,

  /**
       * Pass custom an array of content to this side usually up to 3 buttons.
       * The first button should be primary, usually with `fill` and will be visually displayed as the last item,
       * but first in the tab order
       */
  rightSideItems: PropTypes.arrayOf(PropTypes.node.isRequired),

  /**
       * Additional OuiFlexGroup props to pass to the container of the `rightSideItems`
       */
  rightSideGroupProps: PropTypes.any,

  /**
       * Custom children will be rendered before the `tabs` unless no `pageTitle` is present, then it will be the last item
       */
  children: PropTypes.node
};

try {
  OuiPageHeaderContent.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiPageHeaderContent',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'Custom children will be rendered before the `tabs` unless no `pageTitle` is present, then it will be the last item',
        name: 'children',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      pageTitle: {
        defaultValue: null,
        description: 'Wrapped in an `H1` so choose appropriately.\nA simple string is best',
        name: 'pageTitle',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      tabs: {
        defaultValue: null,
        description: 'In-app navigation presented as large borderless tabs.\n' + 'Accepts an array of `OuiTab` objects;\n' + 'HELP: This is evaluating to `any[]` in the props table',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Tab[]'
        }
      },
      tabsProps: {
        defaultValue: null,
        description: 'Any extras to apply to the outer tabs container.\nExtends `OuiTabs`',
        name: 'tabsProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<OuiTabsProps, "property" | "dir" | "slot" | "style" | "title" | "children" | "color" | "defaultChecked" | "defaultValue" | "suppressContentEditableWarning" | ... 244 more ... | "data-test-subj">'
        }
      },
      description: {
        defaultValue: null,
        description: 'Position is dependent on existing with a `pageTitle` or `tabs`\n' + 'Automatically get wrapped in a single paragraph tag inside an OuiText block',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: "Set to false if you don't want the children to stack at small screen sizes.\n" + 'Set to `reverse` to display the right side content first for the sack of hierarchy (like global time)',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "reverse"'
        }
      },
      alignItems: {
        defaultValue: {
          value: 'top'
        },
        description: 'Vertical alignment of the left and right side content;\n' + 'Default is `middle` for custom content, but `top` for when `pageTitle` or `tabs` are included',
        name: 'alignItems',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "top" | "bottom" | "stretch"',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"stretch"'
          }]
        }
      },
      rightSideItems: {
        defaultValue: null,
        description: 'Pass custom an array of content to this side usually up to 3 buttons.\n' + 'The first button should be primary, usually with `fill` and will be visually displayed as the last item,\n' + 'but first in the tab order',
        name: 'rightSideItems',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode[]'
        }
      },
      rightSideGroupProps: {
        defaultValue: null,
        description: 'Additional OuiFlexGroup props to pass to the container of the `rightSideItems`',
        name: 'rightSideGroupProps',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/page/page_header/page_header_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<OuiFlexGroupProps>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}