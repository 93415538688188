/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import classNames from 'classnames';
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from 'react';
import { useCombinedRefs } from '../../services';
import { OuiScreenReaderOnly } from '../accessibility';
import { OuiI18n } from '../i18n';
import { useResizeObserver } from '../observer/resize_observer';
import { OuiPortal } from '../portal'; // Exported for testing

export var paddingSizeToClassNameMap = {
  none: null,
  s: 'ouiBottomBar--paddingSmall',
  m: 'ouiBottomBar--paddingMedium',
  l: 'ouiBottomBar--paddingLarge'
};
export var POSITIONS = ['static', 'fixed', 'sticky'];
export var OuiBottomBar = forwardRef(({
  position = 'fixed',
  paddingSize = 'm',
  affordForDisplacement = true,
  children,
  className,
  bodyClassName,
  landmarkHeading,
  usePortal = true,
  left,
  right,
  bottom,
  top,
  style,
  ...rest
}, ref) => {
  // Force some props if `fixed` position, but not if the user has supplied these
  affordForDisplacement = position !== 'fixed' ? false : affordForDisplacement;
  usePortal = position !== 'fixed' ? false : usePortal;
  const [resizeRef, setResizeRef] = useState(null);
  const setRef = useCombinedRefs([setResizeRef, ref]); // TODO: Allow this hooke to be conditional

  const dimensions = useResizeObserver(resizeRef);
  useEffect(() => {
    if (affordForDisplacement && usePortal) {
      document.body.style.paddingBottom = `${dimensions.height}px`;
    }

    if (bodyClassName) {
      document.body.classList.add(bodyClassName);
    }

    return () => {
      if (affordForDisplacement && usePortal) {
        document.body.style.paddingBottom = '';
      }

      if (bodyClassName) {
        document.body.classList.remove(bodyClassName);
      }
    };
  }, [affordForDisplacement, usePortal, dimensions, bodyClassName]);
  const classes = classNames('ouiBottomBar', `ouiBottomBar--${position}`, paddingSizeToClassNameMap[paddingSize], className);
  const newStyle = {
    left,
    right,
    bottom,
    top,
    ...style
  };
  const bar = <>
        <OuiI18n token="ouiBottomBar.screenReaderHeading" default="Page level controls">
          {screenReaderHeading => // Though it would be better to use aria-labelledby than aria-label and not repeat the same string twice
      // A bug in voiceover won't list some landmarks in the rotor without an aria-label
      <section aria-label={landmarkHeading ? landmarkHeading : screenReaderHeading} className={classes} ref={setRef} style={newStyle} {...rest}>
              <OuiScreenReaderOnly>
                <h2>
                  {landmarkHeading ? landmarkHeading : screenReaderHeading}
                </h2>
              </OuiScreenReaderOnly>
              {children}
            </section>}
        </OuiI18n>
        <OuiScreenReaderOnly>
          <p aria-live="assertive">
            {landmarkHeading ? <OuiI18n token="ouiBottomBar.customScreenReaderAnnouncement" default="There is a new region landmark called {landmarkHeading} with page level controls at the end of the document." values={{
          landmarkHeading
        }} /> : <OuiI18n token="ouiBottomBar.screenReaderAnnouncement" default="There is a new region landmark with page level controls at the end of the document." />}
          </p>
        </OuiScreenReaderOnly>
      </>;
  return usePortal ? <OuiPortal>{bar}</OuiPortal> : bar;
});
OuiBottomBar.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * How to position the bottom bar against its parent.
       */
  position: PropTypes.oneOfType([PropTypes.oneOf(["fixed"]), PropTypes.oneOf(["static", "sticky"]).isRequired]),

  /**
       * Whether to wrap in an OuiPortal which appends the component to the body element.
       * Only works if `position` is `fixed`.
       */
  usePortal: PropTypes.bool,

  /**
       * Whether the component should apply padding on the document body element to afford for its own displacement height.
       * Only works if `usePortal` is true and `position` is `fixed`.
       */
  affordForDisplacement: PropTypes.bool,

  /**
       * Padding applied to the bar. Default is 'm'.
       */
  paddingSize: PropTypes.oneOf(["none", "s", "m", "l"]),

  /**
       * Optional class applied to the body element on mount.
       */
  bodyClassName: PropTypes.string,

  /**
       * Customize the screen reader heading that helps users find this control. Default is 'Page level controls'.
       */
  landmarkHeading: PropTypes.string,

  /**
       * Starting vertical position when `fixed` position.
       * Offset from the top of the window when `sticky` position.
       * Has no affect on `static` positions.
       */
  top: PropTypes.any,

  /**
       * Ending horizontal position when `fixed` position.
       * Has no affect on `static` or `sticky` positions.
       */
  right: PropTypes.any,

  /**
       * Starting vertical position when `fixed` position.
       * Offset from the bottom of the window when `sticky` position.
       * Has no affect on `static` positions.
       */
  bottom: PropTypes.any,

  /**
       * Starting horizontal position when `fixed` position.
       * Has no affect on `static` or `sticky` positions.
       */
  left: PropTypes.any
};
OuiBottomBar.displayName = 'OuiBottomBar';

try {
  OuiBottomBar.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiBottomBar',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      usePortal: {
        defaultValue: {
          value: 'true'
        },
        description: 'Whether to wrap in an OuiPortal which appends the component to the body element.\n' + 'Only works if `position` is `fixed`.',
        name: 'usePortal',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      affordForDisplacement: {
        defaultValue: {
          value: 'true'
        },
        description: 'Whether the component should apply padding on the document body element to afford for its own displacement height.\n' + 'Only works if `usePortal` is true and `position` is `fixed`.',
        name: 'affordForDisplacement',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      position: {
        defaultValue: {
          value: 'fixed'
        },
        description: 'How to position the bottom bar against its parent.',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "static" | "sticky"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"static"'
          }, {
            value: '"sticky"'
          }]
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: "Padding applied to the bar. Default is 'm'.",
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"none"'
          }]
        }
      },
      bodyClassName: {
        defaultValue: null,
        description: 'Optional class applied to the body element on mount.',
        name: 'bodyClassName',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      landmarkHeading: {
        defaultValue: null,
        description: "Customize the screen reader heading that helps users find this control. Default is 'Page level controls'.",
        name: 'landmarkHeading',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      top: {
        defaultValue: null,
        description: 'Starting vertical position when `fixed` position.\n' + 'Offset from the top of the window when `sticky` position.\n' + 'Has no affect on `static` positions.',
        name: 'top',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      right: {
        defaultValue: null,
        description: 'Ending horizontal position when `fixed` position.\n' + 'Has no affect on `static` or `sticky` positions.',
        name: 'right',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      bottom: {
        defaultValue: null,
        description: 'Starting vertical position when `fixed` position.\n' + 'Offset from the bottom of the window when `sticky` position.\n' + 'Has no affect on `static` positions.',
        name: 'bottom',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      left: {
        defaultValue: null,
        description: 'Starting horizontal position when `fixed` position.\n' + 'Has no affect on `static` or `sticky` positions.',
        name: 'left',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }, {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}