/*
 * SPDX-License-Identifier: Apache-2.0
 *
 * The OpenSearch Contributors require contributions made to
 * this file be licensed under the Apache-2.0 license or a
 * compatible open source license.
 *
 * Modifications Copyright OpenSearch Contributors. See
 * GitHub history for details.
 */

/*
 * Licensed to Elasticsearch B.V. under one or more contributor
 * license agreements. See the NOTICE file distributed with
 * this work for additional information regarding copyright
 * ownership. Elasticsearch B.V. licenses this file to you under
 * the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
export var OuiAspectRatio = ({
  children,
  className,
  height,
  width,
  maxWidth,
  ...rest
}) => {
  const classes = classNames('ouiAspectRatio', className);
  const paddingBottom = `${height / width * 100}%`;
  const content = <div className={classes} {...rest} style={{
    paddingBottom: paddingBottom,
    maxWidth: maxWidth ? maxWidth : 'auto'
  }}>
      {children}
    </div>;
  let contentwithoptionalwrap = content;

  if (maxWidth) {
    contentwithoptionalwrap = <div style={{
      maxWidth: maxWidth
    }}>{content}</div>;
  }

  return contentwithoptionalwrap;
};
OuiAspectRatio.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
       * Aspect ratio height. For example 9 would be widescreen video.
       */
  height: PropTypes.number.isRequired,

  /**
       * Aspect ratio width. For example 16 would be widescreen video.
       */
  width: PropTypes.number.isRequired,

  /**
       * The maximum width you want the child to stretch to.
       */
  maxWidth: PropTypes.number
};

try {
  OuiAspectRatio.__docgenInfo = {
    tags: {},
    description: '',
    displayName: 'OuiAspectRatio',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'docs/node_modules/@types/react/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'docs/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      height: {
        defaultValue: null,
        description: 'Aspect ratio height. For example 9 would be widescreen video.',
        name: 'height',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/aspect_ratio/aspect_ratio.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      width: {
        defaultValue: null,
        description: 'Aspect ratio width. For example 16 would be widescreen video.',
        name: 'width',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/aspect_ratio/aspect_ratio.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      maxWidth: {
        defaultValue: null,
        description: 'The maximum width you want the child to stretch to.',
        name: 'maxWidth',
        parent: undefined,
        declarations: [{
          fileName: 'docs/src/components/aspect_ratio/aspect_ratio.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}